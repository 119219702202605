import { has, get } from '@/utils/utils'

/**
 * 受信データを展開する処理
 * @param {any} data
 * @returns {any}
 */
export const normalize = (data: any): any => {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map((item: any) => normalize(item))

    return data
  }

  // Flatten nested documents
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) =>
      Array.isArray(value)
        ? [key, value.map((v) => get(v, '@id', v))]
        : [key, get(value, '@id', value)]
    )
  )
}

/**
 * 受信したコレクションデータの展開処理
 * @param data
 */
export const extractCollection = (data: any, normalizeRelations: Function|null = null): any => {

  const totalItems = data['hydra:totalItems']
  const view = data['hydra:view']

  const items: any[] = []
  data['hydra:member'].forEach((item: any) => {
    const normalized = normalizeRelations ? normalizeRelations(item) : item
    items.push(normalized)
  })
  return { totalItems, view, items }
}
