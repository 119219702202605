// eslint-disable-next-line no-unused-vars
import fetch, { Options } from '@/utils/fetch'

const API_PATH = process.env.__API_PATH_

export default function makeService (endpoint: string) {
  return {
    // XXX ここで指定するidはidそのものではなく/api/v1/xxx/idの形式(['@id']と同じ)
    find (id: string) {
      return fetch(`${id}`)
    },
    findAll (params: Options) {
      return fetch(API_PATH + endpoint, params)
    },
    create (payload: any) {
      // @ts-ignore
      return fetch(API_PATH + endpoint, { method: 'POST', body: JSON.stringify(payload) })
    },
    del (item: any) {
      // @ts-ignore
      return fetch(item['@id'], { method: 'DELETE' })
    },
    update (payload: any) {
      // PUTはreplaceまたはcreateなのだけど
      // PUTではidが無いと新規作成できないので
      // id未指定時はcreateへリダイレクトする
      const id = payload['@id']
      if (id) {
        // @ts-ignore
        return fetch(payload['@id'], {
          method: 'PUT',
          body: JSON.stringify(payload),
        })
      } else {
        return this.create(payload)
      }
    },
    current (params: Options) {
      if (!params) {
        params = new Options()
      }
      params = { ...params, method: 'GET' }
      return fetch((API_PATH + endpoint + '/current'), params)
    },
  }
}
