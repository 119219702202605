import { getField, updateField } from 'vuex-map-fields'
import SubmissionError from '@/error/SubmissionError'

const initialState = () => ({
  allIds: [],
  byId: {},
  created: null,
  deleted: null,
  updated: null,
  selectItems: null,
  currentItem: null,
  totalCurrentItems: 0,
  view: null,
  error: '',
  isLoading: false,
  resetList: false,
  totalItems: 0,
  totalPages: 1,
  pageSize: 1,
  violations: null,
})

const handleError = (commit, e) => {
  commit(ACTIONS.TOGGLE_LOADING)

  if (e instanceof SubmissionError) {
    commit(ACTIONS.SET_VIOLATIONS, e.errors)
    // eslint-disable-next-line
    commit(ACTIONS.SET_ERROR, e.errors._error)

    return Promise.reject(e)
  }

  // eslint-disable-next-line
  commit(ACTIONS.SET_ERROR, e.message)

  return Promise.reject(e)
}

export const ACTIONS = {
  ADD: 'ADD',
  RESET_ALL: 'RESET_ALL',
  RESET_CREATE: 'RESET_CREATE',
  RESET_DELETE: 'RESET_DELETE',
  RESET_LIST: 'RESET_LIST',
  RESET_SHOW: 'RESET_SHOW',
  RESET_UPDATE: 'RESET_UPDATE',
  RESET_SELECTED: 'RESET_SELECTED',
  RESET_CURRENT: 'RESET_CURRENT',
  SET_CREATED: 'SET_CREATED',
  SET_DELETED: 'SET_DELETED',
  SET_CURRENT: 'SET_CURRENT',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SET_ERROR: 'SET_ERROR',
  SET_SELECT_ITEMS: 'SET_SELECT_ITEMS',
  SET_TOTAL_ITEMS: 'SET_TOTAL_ITEMS',
  SET_UPDATED: 'SET_UPDATED',
  SET_VIEW: 'SET_VIEW',
  SET_VIOLATIONS: 'SET_VIOLATIONS',
  TOGGLE_LOADING: 'TOGGLE_LOADING', // FIXME START_LOADING/END_LOADINGにした方がいいかも
}

export default function makeCrudModule ({
  normalizeRelations = (x) => x,
  resolveRelations = (x) => x,
  service,
} = {}) {
  return {
    actions: {
      // 新規作成
      create: ({ commit }, values) => {
        commit(ACTIONS.CLEAR_ERROR)
        commit(ACTIONS.TOGGLE_LOADING)

        return service
          .create(values)
          .then((response) => response.json())
          .then((data) => {
            commit(ACTIONS.TOGGLE_LOADING)
            commit(ACTIONS.ADD, data)
            commit(ACTIONS.SET_CREATED, data)
            return data
          })
          .catch((e) => handleError(commit, e))
      },
      // 削除
      del: ({ commit }, item) => {
        commit(ACTIONS.TOGGLE_LOADING)

        return service
          .del(item)
          .then(() => {
            commit(ACTIONS.TOGGLE_LOADING)
            commit(ACTIONS.SET_DELETED, item)
          })
          .catch((e) => handleError(commit, e))
      },
      // 一覧取得
      fetchAll: ({ commit, state }, params) => {
        if (!service) throw new Error('No service specified!')

        commit(ACTIONS.TOGGLE_LOADING)

        return service
          .findAll({ params })
          .then((response) => response.json())
          .then((retrieved) => {
            commit(ACTIONS.TOGGLE_LOADING)

            commit(ACTIONS.SET_TOTAL_ITEMS, retrieved['hydra:totalItems'])
            commit(ACTIONS.SET_VIEW, retrieved['hydra:view'])

            if (state.resetList === true) {
              commit(ACTIONS.RESET_LIST)
            }

            const items = []
            retrieved['hydra:member'].forEach((item) => {
              const normalized = normalizeRelations(item)
              items.push(normalized)
              commit(ACTIONS.ADD, normalized)
            })
            return items
          })
          .catch((e) => handleError(commit, e))
      },
      // 指定したアイテムを取得
      fetchSelectItems: (
        { commit },
        { params = { properties: ['@id', 'name'] } } = {}
      ) => {
        commit(ACTIONS.TOGGLE_LOADING)

        if (!service) throw new Error('No service specified!')

        return service
          .findAll({ params })
          .then((response) => response.json())
          .then((retrieved) => {
            const items = []
            retrieved['hydra:member'].forEach((item) => {
              const normalized = normalizeRelations(item)
              items.push(normalized)
            })
            commit(ACTIONS.SET_SELECT_ITEMS, items)
            return items
          })
          .catch((e) => handleError(commit, e))
      },
      // IDを指定して読み込み
      // XXX ここで指定するidはidそのものではなく/api/v1/xxx/idの形式(['@id']と同じ)
      load: ({ commit }, id) => {
        if (!service) throw new Error('No service specified!')

        commit(ACTIONS.TOGGLE_LOADING)
        return service
          .find(id)
          .then((response) => response.json())
          .then((item) => {
            const normalized = normalizeRelations(item)
            commit(ACTIONS.TOGGLE_LOADING)
            commit(ACTIONS.ADD, normalized)
            return normalized
          })
          .catch((e) => handleError(commit, e))
      },
      // 保持しているすべてのステートをクリア
      resetAll: ({ commit }) => {
        commit(ACTIONS.RESET_ALL)
      },
      // リストステートをクリア
      resetList: ({ commit }) => {
        commit(ACTIONS.RESET_LIST)
      },
      // 新規作成ステートをクリア
      resetCreate: ({ commit }) => {
        commit(ACTIONS.RESET_CREATE)
      },
      // 削除ステートをクリア
      resetDelete: ({ commit }) => {
        commit(ACTIONS.RESET_DELETE)
      },
      // 表示ステートをクリア？
      resetShow: ({ commit }) => {
        commit(ACTIONS.RESET_SHOW)
      },
      // 更新ステートをクリア
      resetUpdate: ({ commit }) => {
        commit(ACTIONS.RESET_UPDATE)
      },
      // 選択ステートをクリア
      resetSelected: ({ commit }) => {
        commit(ACTIONS.RESET_SELECTED)
      },
      // ログインユーザーに関連したデータをクリア
      resetCurrent: ({ commit }) => {
        commit(ACTIONS.RESET_CURRENT)
      },
      // 指定したアイテムを更新要求
      update: ({ commit }, item) => {
        commit(ACTIONS.CLEAR_ERROR)
        commit(ACTIONS.TOGGLE_LOADING)

        return service
          .update(item)
          .then((response) => response.json())
          .then((data) => {
            commit(ACTIONS.TOGGLE_LOADING)
            commit(ACTIONS.SET_UPDATED, data)
            return data
          })
          .catch((e) => handleError(commit, e))
      },
      // 現在認証中のユーザー/管理者に関連する情報を取得
      fetchCurrrent: ({ commit }, options) => {
        commit(ACTIONS.CLEAR_ERROR)
        commit(ACTIONS.TOGGLE_LOADING)

        return service
          .current(options)
          .then((response) => response.json())
          .then((item) => {
            if (item['hydra:member']) {
              // コレクションが返ってきた時
              const items = []
              item['hydra:member'].forEach((item) => {
                const normalized = normalizeRelations(item)
                items.push(normalized)
                commit(ACTIONS.ADD, normalized)
              })
              commit(ACTIONS.TOGGLE_LOADING)
              commit(ACTIONS.SET_CURRENT, { currentItem: items, totalCurrentItems: items.length })
              return items
            } else {
              // アイテムが1つ返ってきた時
              const normalized = normalizeRelations(item)
              commit(ACTIONS.TOGGLE_LOADING)
              commit(ACTIONS.ADD, normalized)
              commit(ACTIONS.SET_CURRENT, { currentItem: normalized, totalCurrentItems: 1 })
              return normalized
            }
          })
          .catch((e) => handleError(commit, e))
      },
    },
    getters: {
      getField,
      // IDを指定してアイテムを取得
      find: (state) => (id) => {
        return resolveRelations(state.byId[id])
      },
      // 一覧を取得
      list: (state, getters) => {
        return state.allIds.map((id) => getters.find(id))
      },
      // ログイン中のユーザー/管理者に関係する値を取得
      current: (state) => {
        return state.currentItem
      },
    },
    mutations: {
      updateField,
      [ACTIONS.ADD]: (state, item) => {
        const id = item['@id']
        if (state.allIds.includes(id)) return
        state.allIds.push(id)
        state.byId[id] = item
      },
      [ACTIONS.RESET_ALL]: (state) => {
        Object.assign(state, {
          allIds: [],
          byId: {},
          created: null,
          deleted: null,
          updated: null,
          selectItems: null,
          currentItem: null,
          totalCurrentItems: 0,
          view: null,
          error: '',
          isLoading: false,
          resetList: false,
          totalItems: 0,
          totalPages: 1,
          pageSize: 1,
          violations: null,
        })
      },
      [ACTIONS.RESET_CREATE]: (state) => {
        Object.assign(state, {
          isLoading: false,
          error: '',
          created: null,
          violations: null,
        })
      },
      [ACTIONS.RESET_DELETE]: (state) => {
        Object.assign(state, {
          isLoading: false,
          error: '',
          deleted: null,
          violations: null,
        })
      },
      [ACTIONS.RESET_LIST]: (state) => {
        Object.assign(state, {
          allIds: [],
          byId: {},
          error: '',
          isLoading: false,
          resetList: false,
          violations: null,
        })
      },
      [ACTIONS.RESET_SHOW]: (state) => {
        Object.assign(state, {
          error: '',
          isLoading: false,
          violations: null,
        })
      },
      [ACTIONS.RESET_UPDATE]: (state) => {
        Object.assign(state, {
          error: '',
          isLoading: false,
          updated: null,
          violations: null,
        })
      },
      [ACTIONS.RESET_SELECTED]: (state) => {
        Object.assign(state, {
          error: '',
          isLoading: false,
          selectItems: null,
          violations: null,
        })
      },
      [ACTIONS.RESET_CURRENT]: (state) => {
        Object.assign(state, {
          error: '',
          isLoading: false,
          currentItem: null,
          totalCurrentItems: 0,
          violations: null,
        })
      },
      [ACTIONS.SET_CREATED]: (state, created) => {
        Object.assign(state, { created })
      },
      [ACTIONS.SET_DELETED]: (state, deleted) => {
        const id = deleted['@id']
        if (!state.allIds.includes(id)) return
        const allIds = state.allIds.filter((item) => item['@id'] !== id)
        const byId = Object.fromEntries(
          Object.entries(state.byId)
            .map(([key, value]) => ({ key, value }))
            .filter((item) => item.key !== id)
            .map((item) => [item.key, item.value])
        )
        Object.assign(state, {
          allIds,
          byId,
          deleted,
        })
      },
      [ACTIONS.SET_CURRENT]: (state, items) => {
        Object.assign(state, items)
      },
      [ACTIONS.CLEAR_ERROR]: (state) => {
        Object.assign(state, { error: '', isLoading: false })
      },
      [ACTIONS.SET_ERROR]: (state, error) => {
        Object.assign(state, { error, isLoading: false })
      },
      [ACTIONS.SET_SELECT_ITEMS]: (state, selectItems) => {
        Object.assign(state, {
          error: '',
          isLoading: false,
          selectItems,
        })
      },
      [ACTIONS.SET_TOTAL_ITEMS]: (state, totalItems) => {
        Object.assign(state, { totalItems })
      },
      [ACTIONS.SET_UPDATED]: (state, updated) => {
        updated = normalizeRelations(updated)
        Object.assign(state, {
          updated,
        })
        // byIdの値を更新
        const id = updated['@id']
        if (id) {
          Object.assign(state.byId[id], updated)
        }
      },
      [ACTIONS.SET_VIEW]: (state, view) => {
        Object.assign(state, { view })
        if (view && view['hydra:last']) {
          const queryString = view['hydra:last'].split('?')[1]
          const queries = [...new URLSearchParams(queryString)
            .entries()].reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {})
          const pageSize = parseInt(queries.pageSize ?? state.pageSize, 10)
          const totalPages = parseInt(queries.totalPages ?? (queries.page ?? state.totalPages), 10)
          Object.assign(state, {
            pageSize,
            totalPages,
          })
        } else {
          // 1ページしか無いときはhydra:last等は存在しない
          Object.assign(state, {
            totalPages: 1,
          })
        }
      },
      [ACTIONS.SET_VIOLATIONS]: (state, violations) => {
        Object.assign(state, { violations })
      },
      [ACTIONS.TOGGLE_LOADING]: (state) => {
        Object.assign(state, { error: '', isLoading: !state.isLoading })
      },
    },
    namespaced: true,
    state: initialState,
  }
}
