import 'vuetify/styles'
import { createVuetify } from 'vuetify'
// webpack-plugin-vuetifyの3.0.1と3.0.1(最新)だとVuetifyPluginを組み込んで
// ツリーシェイキング/自動インポートを有効に使用とするとビルド時にエラーになる
// 全てのコンポーネントを読み込んでいた
// 2.0.1だと正常にビルドできる、動作も大丈夫そうなので

// vuetifyのコンポーネントを全てインポートする設定
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

import light from './light'
import dark from './dark'

import { ja, en } from 'vuetify/locale'

export default createVuetify({
  locale: {
    messages: {
      ja, en,
    },
    locale: 'ja',
    fallback: 'en',
  },
  display: {
    mobileBreakpoint: 'xs',  // This is equivalent to a value of 600
  },
  // icons: {
  //   iconfont: 'mdiSvg',
  // },
  theme: {
    // // cssでも色を使えるようにする
    // options: { customProperties: true },
    defaultTheme: 'light',
    themes: {
      // ここにはlightとdarkしか設定できない
      light,
      dark,
    },
    // primary-darken-9 primary-lighten-9 までできるようにする
    variations: {
      colors: ['primary', 'secondary', 'accent'],
      lighten: 9,
      darken: 9,
    },
  },
  // vuetifyのコンポーネントを全てインポートする設定
  // components,
  // directives,
})
