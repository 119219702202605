import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: 'signin',
  },
  { // ゲストホーム画面
    path: '/guest',
    name: 'Guest Home',
    component: () => import('@/views/guest/Home.vue'),
  },
  { // ゲストグループチャット通話中画面
    path: '/guest/groupchat/talk',
    name: 'Guest SFUTalk',
    component: () => import('@/views/guest/SFUTalk.vue'),
  },
  { // ユーザーホーム画面
    path: '/user',
    name: 'Home',
    component: () => import('@/views/user/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  { // ユーザーメールアドレス確認
    path: '/user/verify',
    name: 'Verify',
    component: () => import('@/views/user/VerifyEmail.vue'),
  },
  { // ペア招待承認画面
    path: '/user/approval',
    name: 'Approval',
    component: () => import('@/views/user/Approval.vue'),
  },
  { // ビデオチャットサービスホーム画面
    path: '/user/videochat',
    name: 'Videochat Home',
    component: () => import('@/views/user/HomeVideoChat.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  { // グループチャットサービスホーム画面
    path: '/user/groupchat',
    name: 'Groupchat Home',
    component: () => import('@/views/user/HomeGroupChat.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  { // 自分のグループチャット設定一覧画面
    path: '/user/owngroups',
    name: 'Groupchat Own',
    component: () => import('@/views/user/OwnGroupList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  { // ビデオチャット通話中画面
    path: '/user/videochat/talk',
    name: 'P2pTalk',
    component: () => import('@/views/user/P2pTalk.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  { // グループチャット通話中画面
    path: '/user/groupchat/talk',
    name: 'SFUTalk',
    component: () => import('@/views/user/SFUTalk.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  { // パスワードを忘れたときの再設定メール送信要求画面
    path: '/user/password/forgot',
    name: 'User password forgot',
    component: () => import('@/views/user/PasswordForgot.vue'),
  },
  { // パスワード再設定リンクから開いてパスワードを設定する画面
    path: '/user/password/reset',
    name: 'User password reset',
    component: () => import('@/views/user/PasswordReset.vue'),
  },
  // { // 新規ユーザー登録画面
  //   path: '/signup',
  //   name: 'Signup',
  //   component: () => import('@/views/user/SignUp.vue'),
  // },
  { // ユーザーログイン画面
    path: '/signin',
    name: 'Signin',
    component: () => import('@/views/user/SignIn.vue'),
  },
  { // ユーザー設定画面
    path: '/user/settings',
    name: 'Settings',
    component: () => import('@/views/user/Settings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  { // アバウト画面
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
  { // 企業マネジャーログイン画面
    path: '/manager/signin',
    name: 'Manager Signin',
    component: () => import('@/views/manager/SignIn.vue'),
  },
  { // 企業マネジャーのパスワードを忘れたときの再設定メール送信要求画面
    path: '/manager/password/forgot',
    name: 'Manager password forgot',
    component: () => import('@/views/manager/PasswordForgot.vue'),
  },
  { // 企業マネジャーのパスワード再設定リンクから開いてパスワードを設定する画面
    path: '/manager/password/reset',
    name: 'Manager password reset',
    component: () => import('@/views/manager/PasswordReset.vue'),
  },
  { // マネジャーホーム画面
    path: '/manager',
    name: 'Manager Home',
    component: () => import('@/views/manager/Home.vue'),
    meta: {
      requiresManagerAuth: true,
    },
  },
  { // マネジャー用機器一覧画面
    path: '/manager/devices',
    name: 'Manager device list',
    component: () => import('@/views/manager/DeviceList.vue'),
    meta: {
      requiresManagerAuth: true,
    },
  },
  { // マネジャー用ペア設定一覧画面
    path: '/manager/pairs',
    name: 'Manager pair list',
    component: () => import('@/views/manager/PairList.vue'),
    meta: {
      requiresManagerAuth: true,
    },
  },
  { // マネジャー用グループ設定一覧画面
    path: '/manager/groups',
    name: 'Manager group list',
    component: () => import('@/views/manager/GroupList.vue'),
    meta: {
      requiresManagerAuth: true,
    },
  },
  { // マネジャー用統計画面
    path: '/manager/statics',
    name: 'Manager statics',
    component: () => import('@/views/manager/Statics.vue'),
    meta: {
      requiresManagerAuth: true,
    },
  },
  { // マネジャー用顧客設定画面
    path: '/manager/customer',
    name: 'Customer Settings',
    component: () => import('@/views/manager/CustomerSettings.vue'),
    meta: {
      requiresManagerAuth: true,
    },
  },
  { // マネジャー設定画面
    path: '/manager/settings',
    name: 'Manager Settings',
    component: () => import('@/views/manager/Settings.vue'),
    meta: {
      requiresManagerAuth: true,
    },
  },
  // { // テスト画面
  //   path: '/test',
  //   name: 'Test',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "test" */ '@/views/test/Test5.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresManagerAuth = to.matched.some(record => record.meta.requiresManagerAuth)
  const user = store.getters['users/current']
  if (requiresAuth) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    // 一般ユーザーとしてログインが必要な場合
    if (user) {
      // console.log('user:%o', user)
      if (user.verified) {
        // email確認済み
        next()
      } else {
        // emailが確認されていない時
        next({
          path: '/signin',
          query: { redirect: to.fullPath },
        })
      }
    } else {
      next({
        path: '/signin',
        query: { redirect: to.fullPath },
      })
    }
  } else if (requiresManagerAuth) {
    // 企業マネジャーとしてログインが必要な場合
    if (user && user.isManager) {
      // console.log('user:%o', user)
      if (user.verified) {
        // email確認済み
        next()
      } else {
        // emailが確認されていない時
        next({
          path: '/manager/signin',
          query: { redirect: to.fullPath },
        })
      }
    } else {
      next({
        path: '/manager/signin',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
})

export default router
