import store from '@/store'
import {
  ConnectionSummaryApi,
  Configuration,
} from '@/api/client'

const API_SERVER_URL = process.env.__API_SERVER_URL_

const actions = {
  /**
   * 月毎の集計を取得
   * @param {{any}} { commit }
   * @param {Number} lastXMonth
   * @returns {Promise<any>}
   */
  countByMonth: async (
    { commit }: { commit: any },
    { lastXMonth = 12, customer = undefined }: { lastXMonth: Number, customer: string|null|undefined }) => {

    const token: any = await store.dispatch('updateToken')
    if (token) {
      const response = await (new ConnectionSummaryApi(new Configuration({ basePath: API_SERVER_URL, accessToken: token })))
        .countByMonthConnectionSummaryCollection(lastXMonth, customer, 1, 12)
      if (response.data) {
        return response.data
      } else {
        const err = { response, message: 'Failed to count by month' }
        throw err
      }
    } else {
      const err = { response: token }
      throw err
    }
  },

  /**
   * 曜日毎の集計を取得
   * @param {{any}} { commit }
   * @param {Number} lastXDays
   * @returns {Promise<any>}
   */
  countByWeek: async (
    { commit }: { commit: any },
    { lastXDays = 30, customer = undefined }: { lastXDays: Number, customer: string|null|undefined }) => {

    const token: any = await store.dispatch('updateToken')
    if (token) {
      const response = await (new ConnectionSummaryApi(new Configuration({ basePath: API_SERVER_URL, accessToken: token })))
        .countByWeekConnectionSummaryCollection(lastXDays, customer)
      if (response.data) {
        return response.data
      } else {
        const err = { response, message: 'Failed to count by week' }
        throw err
      }
    } else {
      const err = { response: token }
      throw err
    }
  },

  /**
   * 日付毎の集計を取得
   * @param {{any}} { commit }
   * @param {Number} lastXDays
   * @returns {Promise<any>}
   */
  countByDay: async (
    { commit }: { commit: any },
    { lastXDays = 30, customer = undefined }: { lastXDays: Number, customer: string|null|undefined }) => {

    const token: any = await store.dispatch('updateToken')
    if (token) {
      const response = await (new ConnectionSummaryApi(new Configuration({ basePath: API_SERVER_URL, accessToken: token })))
        .countByDayConnectionSummaryCollection(lastXDays, customer)
      if (response.data) {
        return response.data
      } else {
        const err = { response, message: 'Failed to count by week' }
        throw err
      }
    } else {
      const err = { response: token }
      throw err
    }
  },

  /**
   * 時間帯毎の集計を取得
   * @param {{any}} { commit }
   * @param {Number} lastXDays
   * @returns {Promise<any>}
   */
  countByHour: async (
    { commit }: { commit: any },
    { lastXDays = 30, customer = undefined }: { lastXDays: Number, customer: string|null|undefined }) => {

    const token: any = await store.dispatch('updateToken')
    if (token) {
      const response = await (new ConnectionSummaryApi(new Configuration({ basePath: API_SERVER_URL, accessToken: token })))
        .countByHourConnectionSummaryCollection(lastXDays, customer)
      if (response.data) {
        return response.data
      } else {
        const err = { response, message: 'Failed to count by week' }
        throw err
      }
    } else {
      const err = { response: token }
      throw err
    }
  },

  /**
   * 時間帯毎の集計を取得
   * @param {{any}} { commit }
   * @param {Number} lastXDays
   * @returns {Promise<any>}
   */
  countByPeerId: async (
    { commit }: { commit: any },
    { lastXDays = 30, customer = undefined }: { lastXDays: Number, customer: string|null|undefined }) => {

    const token: any = await store.dispatch('updateToken')
    if (token) {
      const response = await (new ConnectionSummaryApi(new Configuration({ basePath: API_SERVER_URL, accessToken: token })))
        .countByPeerIdConnectionSummaryCollection(lastXDays, customer)
      if (response.data) {
        return response.data
      } else {
        const err = { response, message: 'Failed to count by week' }
        throw err
      }
    } else {
      const err = { response: token }
      throw err
    }
  },

}

export default {
  namespaced: true,
  actions,
}
