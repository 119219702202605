import makeService from './api'
import { getName as getPairName } from '@/services/pair'

export default makeService('groups')

/**
 * グループメンバー表示用ラベル文字列生成用ヘルパー関数
 * @param group
 * @returns
 */
export const getMembersLabel = (group: any): string => {
  const members = group.memberPairs.map((member: any) => {
    return getPairName(member)
  })
  return members.concat(group.memberGuests).join(', ')
}
