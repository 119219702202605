import store from '@/store'
import SubmissionError from '../error/SubmissionError'
import { normalize } from './hydra'
import { isObject } from '@/utils/utils'

const MIME_TYPE = 'application/ld+json'
const API_SERVER_URL = process.env.__API_SERVER_URL_

/**
 * fetchのオプションを引き渡すためのホルダークラス
 */
export class Options /* implements RequestInit */ {
  headers?: any = undefined
  method?: string = undefined
  body?: any = undefined
  params?: any = undefined
  mode?: any = undefined
  credentials?: any = undefined
}

/**
 * createQueryStringの下請け
 * @param {string} key
 * @param {any} arr
 * @returns
 */
const makeParamArray = (key: string, arr: any) =>
  arr.map((val: any) => `${key}[]=${val}`).join('&')

/**
 * マップまたは連想配列からurlへ付加するためのquery文字列を生成する
 * @param {any} params
 * @returns {string} query文字列, 先頭の?はないので自前で追加すること
 */
export const createQueryString = (params: any): string => {
  params = normalize(params)
  const queryString = Object.keys(params)
    .map((key) =>
      Array.isArray(params[key])
        ? makeParamArray(key, params[key])
        : `${key}=${encodeURIComponent(params[key])}`
    )
    .join('&')
  return queryString
}

/**
 * URLからクエリーパラメータ文字列を取得する
 *
 * @param  {string} name パラメータのキー文字列
 * @return {string} url 対象のURL文字列
 */
export const getQueryParam = (name: string, url: string) => {
  if (!url) { url = window.location.href }
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) { return null }
  if (!results[2]) { return '' }
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * global.fetchを呼び出すためのヘルパー関数
 * @param id ここで指定するidはid値そのものではなく/api/v1/xxx/idの形式(['@id']と同じ)
 * @param options
 * @returns
 */
export default function (id: string, options: Options = new Options()) : Promise<Response> {
  // console.log('fetch:', id, options)
  if (typeof options.headers === 'undefined') {
    options.headers = {}
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!options.headers.hasOwnProperty('Accept')) {
    options.headers = { ...options.headers, Accept: MIME_TYPE }
  }

  if (options.body
      && !(options.body instanceof FormData)
      // eslint-disable-next-line no-prototype-builtins
      && !options.headers.hasOwnProperty('Content-Type')
  ) {
    options.headers = { ...options.headers, 'Content-Type': MIME_TYPE }
  }

  if (options.params) {
    const params = normalize(options.params)
    const queryString = Object.keys(params)
      .map((key) =>
        Array.isArray(params[key])
          ? makeParamArray(key, params[key])
          : `${key}=${params[key]}`
      )
      .join('&')
    id = `${id}?${queryString}`
  }

  const payload = options.body && JSON.parse(options.body)
  if (isObject(payload) && payload['@id']) {
    options.body = JSON.stringify(normalize(payload))
  }

  const url = new URL(id, API_SERVER_URL)
  const urlString = url.toString()
  return store.dispatch('updateToken')
    .then((token: string) => {
      if (token) {
        options.headers = { ...options.headers, authorization: 'Bearer ' + token }
      }
      options.mode = 'cors'
      // console.log('fetch:', id, options, url)
      return global.fetch(urlString, options)
    })
    .then((response: any) => {
      if (response.ok) {
        // レスポンスコードが200番台300番台のときはそのまま返す
        return response
      }

      // エラーレスポンスの場合
      return response.json().then(
        (json: any) => {
          const error: any
            = json['hydra:description']
            || json['hydra:title']
            || json.error
            || response.statusText
            || 'An error occurred.'

          if (!json.violations && !json.validation) {

            throw Error(error)
          }

          const errors = { _error: error }
          json.violations.forEach((violation: any) =>
            // @ts-ignore
            errors[violation.propertyPath]
              // @ts-ignore
              ? (errors[violation.propertyPath]
                // @ts-ignore
                  += '\n' + errors[violation.propertyPath])
              // @ts-ignore
              : (errors[violation.propertyPath] = violation.message)
          )

          throw new SubmissionError(errors)
        },
        () => {
          throw new Error(response.statusText || 'An error occurred.')
        }
      )
    })
}
