// @ts-ignore
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: () => ({
    alert: false,
    alertTitle: '',
    alertMsg: '',
    notification: false,
    notificationTitle: '',
    notificationMsg: '',
    notificationDuration: 2500,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
}
