import makeService from './api'

export default makeService('pairs')

/**
 * 表示用ラベル文字列を取得
 * @param {any} pair
 * @returns
 */
export const getLabel = (pair: any): string => {
  const label = pair.printedSerial ?? pair.email
  return pair.name ? `${label}\n(${pair.name})` : label
}

/**
 * ペアユーザーの名前文字列(シリアル番号またはメールアドレス)を取得
 * @param {any} pair
 * @returns
 */
export const getName = (pair: any): string => {
  return pair.printedSerial ?? pair.email
}
