import { createStore, createLogger } from 'vuex'
import { DateTime } from 'luxon'

import makeCrudModule from './modules/crud'
import token from './modules/token'
import common from './modules/common'
import user from './modules/user'
import stats from './modules/stats'
import message from './modules/message'

import userService from '@/services/user'
import deviceService from '@/services/device'
import pairService, { getLabel as getPairLabel } from '@/services/pair'
import groupService, { getMembersLabel } from '@/services/group'
import settingService from '@/services/setting'
import serviceService from '@/services/service'
import connectionService from '@/services/connection'
import notificationService from '@/services/notification'
import customerService from '@/services/customer'

import i18n from '@/plugins/i18n'
import { toPrintedSerial } from '@/utils/utils'

/**
 * vuexのロガーを有効にするかどうか
 * プロダクションビルド以外の時のみ有効にできる
 */
const ENABLE_LOGGER = false

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  plugins: ENABLE_LOGGER && (process.env.NODE_ENV !== 'production')
    ? [createLogger()]
    : [],
  modules: {
    common,
    token,
    user,
    stats,
    message,
    users: makeCrudModule({
      service: userService,
      normalizeRelations: (item) => {
        item.state = item.verified ? 'VERIFIED' : 'UNVERIFIED'
        item.state_str = i18n.global.t('userList.states.' + item.state)
        item.userType = item.isManager ? 'MANAGER' : 'USER'
        item.userType_str = i18n.global.t('userList.userTypes.' + item.userType)
        return item
      },
    }),
    devices: makeCrudModule({
      service: deviceService,
      normalizeRelations: (item) => {
        // console.log('device:%o', item)
        if (item.state) {
          item.state_str = i18n.global.t('deviceList.states.' + item.state)
        }
        if (!item.printedSerial && item.serialNumber) {
          // 表示用に印刷形式のシリアル番号を追加する
          item.printedSerial = toPrintedSerial(item.serialNumber)
        }
        if (item.registered) {
          const date = DateTime.fromISO(item.registered)
          item.registeredLocal = date.toLocaleString(DateTime.DATETIME_SHORT, { locale: i18n.locale })
        }
        return item
      },
    }),
    pairs: makeCrudModule({
      service: pairService,
      normalizeRelations: (item) => {
        // console.log('pairs:%o', item)
        if (!item.printedSerial && item.serialNumber) {
          // 表示用に印刷形式のシリアル番号を追加する
          item.printedSerial = toPrintedSerial(item.serialNumber)
        }
        if (item.printedSerial) {
          // タイトル用にシリアル番号とユーザー名を１つにまとめた文字列を追加する
          item.label = getPairLabel(item)
        }
        item.state = item.verified ? 'VERIFIED' : 'INVITED'
        if (item.verifyLink) {
          // 招待された側
          item.state_str = i18n.global.t('pairList.states.INVITED')
        } else {
          // 招待した側または承認/拒絶した
          item.state_str = i18n.global.t(item.verified ? 'pairList.states.VERIFIED' : 'pairList.states.INVITED')
        }
        return item
      },
    }),
    groups: makeCrudModule({
      service: groupService,
      normalizeRelations: (item) => {
        if (item.owner) {
          item.owner_name = item.owner.name ? item.owner.name : item.owner.email
        } else {
          item.owner_name = ''
        }
        item.memberPairs.forEach((item) => {
          if (!item.printedSerial && item.serialNumber) {
            item.printedSerial = toPrintedSerial(item.serialNumber)
          }
        })
        if (item.start) {
          const date = DateTime.fromISO(item.start)
          item.startLocal = date.toLocaleString(DateTime.DATETIME_SHORT, { locale: i18n.locale })
        }
        if (item.end) {
          const date = DateTime.fromISO(item.end)
          item.endLocal = date.toLocaleString(DateTime.DATETIME_SHORT, { locale: i18n.locale })
        }
        if (!item.members_str) {
          item.members_str = getMembersLabel(item)
        }
        return item
      },
    }),
    settings: makeCrudModule({
      service: settingService,
    }),
    services: makeCrudModule({
      service: serviceService,
    }),
    connections: makeCrudModule({
      service: connectionService,
    }),
    notifications: makeCrudModule({
      service: notificationService,
    }),
    customers: makeCrudModule({
      service: customerService,
      normalizeRelations: (item) => {
        item.state = item.verified ? 'VERIFIED' : 'UNVERIFIED'
        item.state_str = i18n.global.t('customerList.states.' + item.state)
        return item
      },
    }),
  },
})

export default store
