<script setup lang="ts">
// アプリのルート画面
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'

import AppNavigation from '@/components/AppNavigation'
import AppFooter from '@/components/AppFooter'

const { t } = useI18n()

const appTitle = computed(() => {
  return t('common.appName')
})

// デフォルトのhead設定
// 他のページでは基本的にtitleだけを指定すれば良い
// 以前のvue2版ではセパレーターが' - 'になっていたが区別できるように'|'に変更
useHead({
  // Titles
  title: appTitle,
  titleTemplate: '%s %separator %siteName',
  // Template params
  templateParams: { separator: '|', siteName: appTitle },
})

</script>

<template>
  <v-app>
    <app-navigation />

    <v-main color="background">
      <router-view />
    </v-main>

    <app-footer />
  </v-app>
</template>

<style scoped>
@import '@/assets/css/style.css';
/* デバッグ用に要素の境界線に色を付ける設定(デバッグ時以外はコメントにすること) */
/*
* {
  outline: 2px solid blue
}
*/
</style>

<style>
/*
 * vuetifyはdesktop環境だと常にスクロールバーを表示する💩仕様なので
 * (html.overflow:scroll)スクロバーを原則非表示にするようにcssを上書きする
 */
/* html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
} */
</style>
