  <!-- メニュー項目としてサブメニューを保持できるようにしたv-menu -->
<script setup>
import { ref } from 'vue'
// @ is an alias to /src
import { isObject } from '@/utils/utils'

const props = defineProps({
  title: { type: String, default: '' },
  icon: { type: String, required: false, default: '' },
  items: { type: Array, default: () => [] },
  color: { type: String, default: 'secondary' },
  isOffsetX: { type: Boolean, default: false },
  isOffsetY: { type: Boolean, default: true },
  isOpenOnHover: { type: Boolean, default: false },
  isSubMenu: { type: Boolean, default: false },
  transition: { type: String, default: 'scale-transition' },
  menuItemClick: { type: Function, required: false, default: undefined },
})

const openMenu = ref(false)

const itemClickEvent = (item) => {
  const menuItemClick = props.menuItemClick
  if (isObject(menuItemClick)) {
    menuItemClick(item)
  }
  openMenu.value = false
}

</script>

<template>
  <v-menu
    :close-on-content-click="true"
    :is-offset-x="isOffsetX"
    :is-offset-y="isOffsetY"
    :open-on-hover="isOpenOnHover"
    :transition="transition"
    :model-value="openMenu"
  >
    <template #activator="{ props: act }">
      <v-btn
        v-if="icon"
        :color="color"
        icon
        variant="flat"
        v-bind="act"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
      <v-list-item
        v-else-if="isSubMenu"
        class="d-flex justify-space-between"
        v-bind="act"
      >
        {{ title }}
        <div class="flex-grow-1" />
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item>
      <v-btn
        v-else
        :color="color"
        variant="text"
        v-bind="act"
        @click="openMenu=true"
      >
        {{ name }}
      </v-btn>
    </template>
    <v-list>
      <div
        v-for="(item) in items"
        :key="item.key"
      >
        <v-divider v-if="item.isDivider" />
        <nested-menu
          v-else-if="item.menu"
          :title="item.title"
          :items="item.menu"
          :menu-item-click="itemClickEvent"
          :is-open-on-hover="false"
          :is-offset-x="true"
          :is-offset-y="false"
          :is-sub-menu="true"
        />
        <v-list-item
          v-else
          @click="itemClickEvent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<style scoped>
</style>
