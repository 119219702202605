<!-- フッター -->
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
// @ is an alias to /src
import useBreakPoint from '@/composables/useBreakPoint'

const store = useStore()

const {
  isFooterVisible,
} = useBreakPoint()

const year = computed(() => {
  const thisYear = new Date().getFullYear()
  return thisYear <= 2021 ? thisYear : '2021-' + thisYear
})
const version = computed(() => store.getters['common/versionString'])

/**
 * ユーザーズページのリンクを踏んだときの処理
 */
const openUsersPage = () => {
  const url = 'https://vixion.jp/user/'
  window.open(url, '_blank', 'scrollbars=yes,resizable=yes')
}

/**
 * コピーライトリンクを踏んだときの処理
 */
const openCopyright = () => {
  const url = 'https://vixion.jp'
  window.open(url, '_blank', 'scrollbars=yes,resizable=yes')
}

</script>

<template>
  <footer>
    <v-footer
      v-if="isFooterVisible"
      color="navigation"
      app
    >
      <div class="footer-version">
        {{ version }}
      </div>
      <v-spacer />
      <div class="footer-link">
      <!-- 利用規約等のリンク表示(未実装) -->
      </div>
      <v-spacer />
      <div class="footer-userspage mx-2 mx-sm-4">
        <a
          class="link-text"
          hlef="#"
          @click.stop.prevent="openUsersPage"
        >{{ $t('common.usersPage') }}</a>
      </div>
      <div class="footer-copyright">
        &copy; {{ year }} <a
          class="link-text"
          hlef="#"
          @click.stop.prevent="openCopyright"
        >{{ $t('common.copyright') }}</a>
      </div>
    </v-footer>
  </footer>
</template>

<style scoped>
.footer-version {
  float: left;
}
.footer-userspage {
  float: right;
}
.footer-copyright {
  float: right;
}
.footer-link {
  width: 200;
  margin-left: auto;
  margin-right: auto;
}
a.no-color-change {
  color: link;
}
</style>
