import CRC32 from 'crc-32'

// --------------------------------------------------------------------------------
// シリアル番号関係
// --------------------------------------------------------------------------------
/**
 * 印刷形式のシリアル番号を取得
 * @param {string} serial
 * @return {string}
 */
export const toPrintedSerial = (serial: string): string|null => {
  if (serial.length > 16) {
    const model = serial.substr(0, serial.length - 16) // モデル
    const seq = serial.substr(serial.length - 16, 8)
    return model + ('00000' + seq).slice(-5)
  } else {
    return ''
  }
}

/**
 * 印刷形式のシリアル番号文字列を内部形式のシリアル番号文字列に変換する
 * @param {string|null|undefined} printed
 * @returns {string|null} 印刷形式のシリアル番号が正しくなければnull
 */
export const toSerial = (printed: string|null|undefined): string|null => {
  const len = printed ? printed.length : 0
  if (len > 5) {
    // "MWB19001"
    // @ts-ignore
    let model = printed.substr(0, len - 5)
    if (model.length < 8) {
      model = (model + '        ').substr(0, 8)
    }
    // @ts-ignore
    let seq = printed.substr(len - 5, 5)
    seq = ('00000' + seq).substr(-8)
    const modelArray = Uint8Array.from(Buffer.from(model))
    // @ts-ignore
    const seqArray = new Uint8Array(seq.match(/.{1,2}/g).map(v => parseInt(v, 16)))
    const target = concat(modelArray, seqArray)
    const calcCrc32 = CRC32.buf(target)
    return model.trim() + seq + ('00000000' + dechex(calcCrc32)).substr(-8)
  } else {
    return null
  }
}

/**
 * シリアル番号がフォーマット通りかどうか
 * チェックデジットがあっているかどうか
 * @param {string} serial
 * @returns {boolean} true: 正しい形式, false: シリアル番号の形式またはチェックデジットが間違っている
 */
export const isValidSerial = (serial: string): boolean => {
  // シリアル番号がフォーマット通りかどうか、チェックデジットがあっているかどうかを計算する
  if (serial.length > 16) {
    // シリアル番号文字列のチェックデジットを取り出す,
    // hex16文字から計算しただけだと元が負数でも正の数字(uint32におさまらない)になるので
    // 0xffffffffとandして32ビット整数に収まるようにする→32ビット符号付き整数になる
    const crc32 = parseInt(serial.substr(serial.length - 8), 16) & 0xffffffff
    let model = serial.substr(0, serial.length - 16) // モデル
    if (model.length < 8) {
      model = model + '        '.substr(0, 8 - model.length)
    }
    const seq = serial.substr(serial.length - 16, 8)
    const modelArray = Uint8Array.from(Buffer.from(model))
    // @ts-ignore
    const seqArray = new Uint8Array(seq.match(/.{1,2}/g).map(v => parseInt(v, 16)))
    const target = concat(modelArray, seqArray)
    const calcCrc32 = CRC32.buf(target)
    if (crc32 === calcCrc32) {
      // FIXME 機器データテーブルに登録されているかどうかの確認が必要
      return true
    }
  }
  return false
}

/**
 * 印刷形式シリアル番号文字列が正しい形式になっているかどうかを検証
 * @param {string} printed
 * @returns {boolean} true: 正しい形式、false: それ以外
 */
export const isValidPrintedSerial = (printed: string|null|undefined): boolean => {
  const len = printed ? printed.length : 0
  if ((len > 5) && (len < 13)) {
    // "MWB19001"
    // @ts-ignore
    let model = printed.substr(0, len - 5)
    if (model.length < 8) {
      model = (model + '        ').substr(0, 8)
    }
    // @ts-ignore
    let seq = printed.substr(len - 5, 5)  // 後ろから5文字がシーケンス番号のはず
    seq = ('00000000' + seq).substr(-8)  // ゼロパディング(シーケンス部はBCD8桁=4バイト分)
    return /^[0-9]{8}$/.test(seq) // シーケンス部が半角数字8桁かどうかをチェック
  }
  return false
}

// --------------------------------------------------------------------------------
// バリデーション関係
// --------------------------------------------------------------------------------
/**
* メールアドレスのバリデーション
* @param {string} mail
* @returns {boolean} true: 正しい形式、false: それ以外
*/
export const isValidMailAddress = (mail: string): boolean => {
  // eslint-disable-next-line no-control-regex, no-useless-escape
  const mailRegex1 = /(?:[-!#-'*+\/-9=?A-Z^-~]+.?(?:.[-!#-'*+\/-9=?A-Z^-~]+)*|"(?:[!#-[]-~]|\\[\t -~])*")@[-!#-'*+\/-9=?A-Z^-~]+(?:.[-!#-'*+\/-9=?A-Z^-~]+)*/
  const mailRegex2 = /^[^@]+@[^@]+$/
  if (mail.match(mailRegex1) && mail.match(mailRegex2)) {
    // 全角チェック
    // eslint-disable-next-line no-control-regex, no-useless-escape
    if (mail.match(/[^a-zA-Z0-9\!\"\#\$\%\&\'\(\)\=\~\|\-\^\\\@\[\;\:\]\,\.\/\\\<\>\?\_\`\{\+\*\} ]/)) { return false }
    // 末尾TLDチェック（〜.co,jpなどの末尾ミスチェック用）
    if (!mail.match(/\.[a-z]+$/)) { return false }
    return true
  } else {
    return false
  }
}

// --------------------------------------------------------------------------------
// 日付・時刻関係
// --------------------------------------------------------------------------------
/**
 * 日付時刻をフォーマットする
 * @param {Date} dtime
 * @returns {string|null}
 */
export const formatDateTime = (dtime: Date|Number): string|null => {
  if (dtime instanceof Date) {
    // do nothing here
  } else if (dtime instanceof Number) {
    dtime = new Date(dtime.valueOf())
  } else {
    return null
  }
  return `${('0000' + dtime.getFullYear()).slice(-4)}/`
    + `${('00' + (dtime.getMonth() + 1)).slice(-2)}/`
    + `${('00' + dtime.getDate()).slice(-2)} `
    + `${('00' + dtime.getHours()).slice(-2)}:`
    + `${('00' + dtime.getMinutes()).slice(-2)}:`
    + `${('00' + dtime.getSeconds()).slice(-2)}:`
    + `${('0000' + dtime.getMilliseconds()).slice(-4)}`
}

/**
 * 日付時刻をフォーマットする
 * @param {Date} dtime
 * @returns {string|null}
 */
export const formatDate = (date: Date|Number): string|null => {
  if (date instanceof Date) {
    // do nothing here
  } else if (date instanceof Number) {
    date = new Date(date.valueOf())
  } else {
    return null
  }
  return `${('0000' + date.getFullYear()).slice(-4)}/`
    + `${('00' + (date.getMonth() + 1)).slice(-2)}/`
    + `${('00' + date.getDate()).slice(-2)}`
}

/**
 * 現在日時を文字列として返す
 * @returns {string}
 */
export const currentDateTimeString = (): string => {
  const cur = new Date()
  return `${('0000' + cur.getFullYear()).slice(-4)}`
    + `${('00' + (cur.getMonth() + 1)).slice(-2)}`
    + `${('00' + cur.getDate()).slice(-2)}`
    + `${('00' + cur.getHours()).slice(-2)}`
    + `${('00' + cur.getMinutes()).slice(-2)}`
    + `${('00' + cur.getSeconds()).slice(-2)}`
    + `${('0000' + cur.getMilliseconds()).slice(-4)}`
}

// --------------------------------------------------------------------------------
// ファイル名生成
// --------------------------------------------------------------------------------
/**
 * 指定したmimeTypeに対応する拡張子を持った現在時刻のファイル名を生成する
 * @param {string} mimeType
 * @returns {string}
 */
export const createRecordingFileName = (mimeType: string): string => {
  let ext = '.webm'
  if (mimeType.includes('video/mp4')) {
    ext = '.mp4'
  } else if (mimeType.includes('video/webm')
    || (mimeType.includes('audio/webm'))) {
    ext = '.webm'
  } else if (mimeType.includes('video/mpeg')) {
    ext = '.mpeg'
  }
  return currentDateTimeString() + ext
}

// --------------------------------------------------------------------------------
// 配列関係
// --------------------------------------------------------------------------------
/**
 * 配列を連結
 * @param {Uint8Array} array1
 * @param {Uint8Array} array2
 * @return {Uint8Array}
 */
export const concat = (array1: Uint8Array, array2: Uint8Array): Uint8Array => {
  const result = new Uint8Array(array1.length + array2.length)
  result.set(array1, 0)
  result.set(array2, array1.length)
  return result
}

// --------------------------------------------------------------------------------
// 文字列関係
// --------------------------------------------------------------------------------
/**
 * 数値を符号なし整数とみなして16進数文字列に変換する
 * 先頭のゼロパディングはしないので引数によって返り値の文字数が変わるので注意
 * 桁数固定にする場合は('00000000' + dechex(number)).substr(-8)のようにすること
 * @param {number} number
 * @returns {string}
 */
export const dechex = (number: any) => {
  if (number < 0) {
    number = 0xFFFFFFFF + number + 1
  }
  return parseInt(number, 10).toString(16)
}

/**
 * 指定した文字列が空かどうか
 * @param {string|null|undefined} str
 * @return {boolean}
 */
export const isEmptyString = (str: string|null|undefined): boolean => {
  return str === null || str === undefined || str.length === 0
}

/**
 * argsで指定した連想配列のキーと値を使って
 * formatで指定した文字列に含まれる中括弧2個で囲まれたキー文字列を
 * 値で置換する
 * replaceAll('abcd{{key}}efg', [key: '値']) -> 'abcd値efg'
 * @param {string} format
 * @param {{[key: string]: string}|object} args 連想配列
 * @returns
 */
export const replaceAll = (format: string, args: {[key: string]: string}): string => {
  let result = format
  if (result.replaceAll) {
    Object.entries(args).forEach(([key, value]) => {
      result = result.replaceAll('{{' + key + '}}', value)
    })
  } else {
    const _replaceAll = (me: string, str: string | RegExp, newStr: any) => {
      // If a regex pattern
      if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
        return me.replace(str, newStr)
      } else {
        // If a string
        return me.replace(new RegExp(str, 'g'), newStr)
      }
    }
    Object.entries(args).forEach(([key, value]) => {
      result = _replaceAll(result, '{{' + key + '}}', value)
    })
  }
  return result
}

/**
 * 指定したキーが存在するかどうか
 * @param {any} data
 * @param {string|number} key
 * @returns
 */
export const has = (data: any, key: string|number): boolean => {
  return isObject(data) && (data[key] !== undefined)
}

/**
 * 指定したキーが存在する場合はその値、存在しなければデフォルト値を返す
 * @param {any} obj
 * @param {string|number} key
 * @param {any} def
 * @returns
 */
export const get = (obj: any, key: string|number, def: any): any => {
  return has(obj, key) ? obj[key] : def
}

/**
 * 引数がオブジェクトかどうか(nullやundefinedでないかどうか)
 * @param {any} obj
 */
export const isObject = (obj: any): boolean => {
  return obj !== null && obj !== undefined
}

export class Size {
  width: number = 0
  height: number = 0
}

// --------------------------------------------------------------------------------
// HTMLElement関係のヘルパー関数
// --------------------------------------------------------------------------------
/**
 * HTMLの指定した要素のマージン込みのサイズを取得する
 * @param el
 * @returns
 */
export const getAbsoluteSize = (el: HTMLElement|string): Size => {
  // Get the DOM Node if you pass in a string
  const element: HTMLElement|null
    = (typeof el === 'string') ? document.querySelector(el) : el

  const result = new Size()
  if (element) {
    const styles = window.getComputedStyle(element)
    const marginWidth = parseFloat(styles.marginLeft)
      + parseFloat(styles.marginRight)
    const marginHeight = parseFloat(styles.marginTop)
      + parseFloat(styles.marginBottom)
    result.width = Math.ceil(element.offsetWidth + marginWidth)
    result.height = Math.ceil(element.offsetHeight + marginHeight)
  }
  return result
}

/**
 * HTMLの指定した要素のマージン込みの幅を取得する
 * @param el
 * @returns
 */
export const getAbsoluteWidth = (el: HTMLElement|string): number => {
  // Get the DOM Node if you pass in a string
  const element: HTMLElement|null
    = (typeof el === 'string') ? document.querySelector(el) : el

  let result = 0
  if (element) {
    const styles = window.getComputedStyle(element)
    const marginWidth = parseFloat(styles.marginLeft)
      + parseFloat(styles.marginRight)
    result = Math.ceil(element.offsetWidth + marginWidth)
  }
  return result
}

/**
 * HTMLの指定した要素のマージン込みの高さを取得する
 * @param el
 * @returns
 */
export const getAbsoluteHeight = (el: HTMLElement|string): number => {
  // Get the DOM Node if you pass in a string
  const element: HTMLElement|null
    = (typeof el === 'string') ? document.querySelector(el) : el

  let result = 0
  if (element) {
    const styles = window.getComputedStyle(element)
    const marginHeight = parseFloat(styles.marginTop)
      + parseFloat(styles.marginBottom)
    result = Math.ceil(element.offsetHeight + marginHeight)
  }
  return result
}

/**
 * 指定したcanvas要素をを指定したfllStyleで塗りつぶす
 * @param {Canvas} canvas
 * @param {string} fillStyle デフォルトrgb(0,0,0)
 */
export const fill = (canvas: HTMLCanvasElement, fillStyle: any = 'rgb(0,0,0)') => {
  if (canvas && canvas.getContext) {
    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.fillStyle = fillStyle
      ctx.fillRect(0, 0, canvas.width, canvas.height)
    }
  }
}

/**
 * 単位指定可能で文字列|数値を文字列へ変換する
 * @param str
 * @param unit
 * @returns
 */
export const convertToUnit = (str: string | number | null | undefined, unit = 'px'): string | undefined => {
  if (str == null || str === '') {
    return undefined
  } else if (isNaN(+str!)) {
    return String(str)
  } else {
    return `${Number(str)}${unit}`
  }
}
