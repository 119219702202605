import /* i18n, */ { SUPPORTED_LANGS, FALLBACK_LANG } from '@/plugins/i18n'
// @ts-ignore
import { getField, updateField } from 'vuex-map-fields'
import {
  VersionApi,
  Configuration,
} from '@/api/client'

const API_SERVER_URL = process.env.__API_SERVER_URL_
const APP_VERSION: string = 'v2.0.0'

/**
 * アプリ全体の情報を保持するデータストア
 */
class CommonState {
  /**
   * APIのバージョン
   */
  apiVersion: string | null | undefined
  version: string | null | undefined
  fullscreen: boolean = false
  lang: string = 'ja'
  headerVisible = true
  footerVisible = true
}

const state = new CommonState()

const mutations = {
  /**
   * APIのバージョンをセット
   * @param state
   * @param param1
   */
  setVersion (state: CommonState,
    { apiVersion }: { apiVersion: string | null | undefined}) {
    state.apiVersion = apiVersion
    state.version = APP_VERSION + '/' + (apiVersion ?? '')
    // console.log('setVersion:', state.apiVersion, state.version)
  },
  setFullScreen (state: CommonState, isFullScreen: boolean) {
    state.fullscreen = isFullScreen
  },
  setLang (state: CommonState, lang: string) {
    state.lang = lang
  },
  setHeaderVisible (state: CommonState, visible: boolean) {
    state.headerVisible = visible
  },
  setFooterVisible (state: CommonState, visible: boolean) {
    state.footerVisible = visible
  },
  updateField,
}

const getters = {
  /**
   * アプリのバージョンを取得
   * @param state
   * @returns
   */
  getAppVersion: (state: CommonState) => {
    return APP_VERSION
  },
  /**
   * APIのバージョンを取得する
   * @param state
   * @returns
   */
  getApiVersion: (state: CommonState) => {
    return state.apiVersion
  },
  versionString: (state: CommonState) => {
    return state.version
  },
  isFullScreen: (state: CommonState) => {
    return state.fullscreen
  },
  isHeaderVisible: (state: CommonState) => {
    return state.headerVisible
  },
  isFooterVisible: (state: CommonState) => {
    return state.footerVisible
  },
  getField,
}

const actions = {
  refresh: async ({ commit }: { commit: any }): Promise<string> => {
    const response = await (new VersionApi(new Configuration({ basePath: API_SERVER_URL })))
      .getAPIVersionItem()
    if (response.data && response.data.version) {
      commit('setVersion', { apiVersion: response.data.version })
      return response.data.version
    } else {
      const err = { response, message: 'Failed to get API version' }
      throw err
    }
  },
  /**
   * 言語設定を変更する
   * @param {object} {commit}
   * @param {string} lang
   */
  changeLang: ({ commit }: { commit: any }, lang: string) => {
    if (!SUPPORTED_LANGS.includes(lang)) {
      // 未対応言語が指定されたときはフォールバック言語に変更する
      lang = FALLBACK_LANG
    }
    // i18n.locale = lang // FIXME ビルド時にエラーになる
    // htmlのlang属性の表示切替
    document.documentElement.setAttribute('lang', lang)
    commit('setLang', lang)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
