import {
  TokenApi,
  Configuration,
} from '@/api/client'

const API_SERVER_URL = process.env.__API_SERVER_URL_

export interface Credentials {
  username?: string;
  password?: string;
}

class TokenState {
  token: string | null = null         // API認証トークン(JWT)
  refreshToken: string | null = null  // API認証トークン更新トークン
  authenticated: boolean = false
}

const state = new TokenState()

const mutations = {
  setToken (state: TokenState,
    { token, refreshToken }: { token: string|null|undefined, refreshToken: string|null|undefined }) {
    state.token = token ?? null
    state.refreshToken = refreshToken ?? null
    state.authenticated = (state.token !== null) && (state.refreshToken !== null)
    // console.log('token/setToken:authenticated=%o', state.authenticated)
  },
  clearToken (state: TokenState) {
    state.token = null
    state.refreshToken = null
    state.authenticated = false
  },
}

const getters = {
  /**
   * 認証されているかどうかを取得
   * @param state
   * @returns
   */
  isAuthenticated (state: TokenState) {
    return state.authenticated
  },
  getToken (state: TokenState) {
    return state.token
  },
}

const actions = {
  /**
   * API認証用のJWTを更新する
   * @param {any, any} {state, commit}
   * @returns {string} token
   */
  updateToken: async (
    { state, commit }: { state: TokenState, commit: any }): Promise<string|null|undefined> => {
    const refreshToken = state.refreshToken
    if (state.token && refreshToken) {
      const token = await (new TokenApi(new Configuration({ basePath: API_SERVER_URL })))
        .postRefreshTokenItem({ refresh_token: refreshToken })
      if (token.data) {
        commit('setToken', { token: token.data.token, refreshToken: token.data.refresh_token })
        return token.data.token
      }
    } else if (state.token) {
      throw new Error('has no token')
    }
    return state.token
  },
  /**
   * API認証用JWTをクリアする
   * @param {any, any} {state, commit}
   */
  clearToken: (
    { state, commit }: { state: TokenState, commit: any }): void => {
    if (state.authenticated) {
      commit('clearToken')
    }
  },

  /**
   * 新しいクレデンシャルでログインし直す
   * @param {{any}} { commit }
   * @param {Credentials} credentail
   * @returns {Promise<any>}
   */
  authenticate: async (
    { commit }: { commit: any }, credentail: Credentials): Promise<any> => {
    const token = await (new TokenApi(new Configuration({ basePath: API_SERVER_URL })))
      .postCredentialsItem({ username: credentail.username, password: credentail.password })
    // console.log('postCredentialsItem:%o', token.data.token)
    if (token.data.token && token.data.token && token.data.refresh_token) {
      commit('setToken', { token: token.data.token, refreshToken: token.data.refresh_token })
      return token.data
    } else {
      throw new Error('wrong token')
    }
  },

  /**
   * 新しいクレデンシャルでログインし直す
   * FIXME 今はauthenticateと実質同じだけど現在ログインしているユーザー/管理者のメールアドレスと違うときはエラーにしたい
   * @param {{any}} { commit }
   * @param {Credentials} credentail
   * @returns {Promise<any>}
   */
  renewToken: async (
    { dispatch, commit }: { dispatch: any, commit: any }, credentail: Credentials): Promise<any> => {
    // FIXME 現在ログインしているユーザー/管理者のメールアドレスと違うときはエラーにしたい
    return dispatch('authenticate', credentail)
  },
}

export default {
  namespaced: false,
  state,
  mutations,
  getters,
  actions,
}
