import { ThemeDefinition } from 'vuetify'
import colors from 'vuetify/lib/util/colors.mjs'

const light: ThemeDefinition = {
  // デフォルトの設定
  // primary: '#1976D2',  // ダイアログのタイトル部分等
  // secondary: '#424242',
  // accent: '#82B1FF',
  // error: '#FF5252',
  // info: '#2196F3',
  // success: '#4CAF50',
  // warning: '#FFC107',

  // テーマオブジェクトの上書き可能なキー一覧
  // base: string
  // lighten5: string
  // lighten4: string
  // lighten3: string
  // lighten2: string
  // lighten1: string
  // darken1: string
  // darken2: string
  // darken3: string
  // darken4: string
  // [name: string]: string

  dark: false,
  colors: {
    primary: colors.grey.darken2,
    //  secondary: colors.indigo,

    // カスタムテーマカラー
    // ヘッダーとフッターの背景色
    navigation: colors.grey.lighten2,
    // 背景色
    background: '#f1f1f1',
    // アプリのコンテンツ部分の背景色
    contentsBackground: colors.shades.white,
    // editorの背景
    editorBackground: colors.shades.white,
    // link文字列
    link: colors.shades.black,
  },
}

export default light
