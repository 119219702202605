/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AdminAdminRead
 */
export interface AdminAdminRead {
    /**
     *
     * @type {string}
     * @memberof AdminAdminRead
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdminAdminRead
     */
    email?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof AdminAdminRead
     */
    phone?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof AdminAdminRead
     */
    address?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {number}
     * @memberof AdminAdminRead
     */
    level?: number;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof AdminAdminRead
     */
    verified?: boolean;
    /**
     * ニックネーム
     * @type {string}
     * @memberof AdminAdminRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface AdminAdminReadUpdate
 */
export interface AdminAdminReadUpdate {
    /**
     *
     * @type {string}
     * @memberof AdminAdminReadUpdate
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdminAdminReadUpdate
     */
    email?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof AdminAdminReadUpdate
     */
    phone?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof AdminAdminReadUpdate
     */
    address?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {number}
     * @memberof AdminAdminReadUpdate
     */
    level?: number;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof AdminAdminReadUpdate
     */
    verified?: boolean;
    /**
     * ニックネーム
     * @type {string}
     * @memberof AdminAdminReadUpdate
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface AdminAdminWrite
 */
export interface AdminAdminWrite {
    /**
     *
     * @type {string}
     * @memberof AdminAdminWrite
     */
    email?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof AdminAdminWrite
     */
    phone?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof AdminAdminWrite
     */
    address?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {number}
     * @memberof AdminAdminWrite
     */
    level?: number;
    /**
     * ハッシュしていない平文のパスワード これはデーターベースへ保存しない
     * @type {string}
     * @memberof AdminAdminWrite
     */
    plainPassword?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof AdminAdminWrite
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface AdminAdminWriteUpdate
 */
export interface AdminAdminWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof AdminAdminWriteUpdate
     */
    email?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof AdminAdminWriteUpdate
     */
    phone?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof AdminAdminWriteUpdate
     */
    address?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {number}
     * @memberof AdminAdminWriteUpdate
     */
    level?: number;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof AdminAdminWriteUpdate
     */
    verified?: boolean;
    /**
     * ニックネーム
     * @type {string}
     * @memberof AdminAdminWriteUpdate
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface AdminJsonldAdminRead
 */
export interface AdminJsonldAdminRead {
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminRead
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminRead
     */
    email?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof AdminJsonldAdminRead
     */
    phone?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof AdminJsonldAdminRead
     */
    address?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {number}
     * @memberof AdminJsonldAdminRead
     */
    level?: number;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof AdminJsonldAdminRead
     */
    verified?: boolean;
    /**
     * ニックネーム
     * @type {string}
     * @memberof AdminJsonldAdminRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface AdminJsonldAdminReadReset
 */
export interface AdminJsonldAdminReadReset {
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadReset
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadReset
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadReset
     */
    type?: string;
}
/**
 *
 * @export
 * @interface AdminJsonldAdminReadUpdate
 */
export interface AdminJsonldAdminReadUpdate {
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadUpdate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadUpdate
     */
    email?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof AdminJsonldAdminReadUpdate
     */
    phone?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof AdminJsonldAdminReadUpdate
     */
    address?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {number}
     * @memberof AdminJsonldAdminReadUpdate
     */
    level?: number;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof AdminJsonldAdminReadUpdate
     */
    verified?: boolean;
    /**
     * ニックネーム
     * @type {string}
     * @memberof AdminJsonldAdminReadUpdate
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface AdminJsonldAdminReadVerify
 */
export interface AdminJsonldAdminReadVerify {
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadVerify
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadVerify
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminReadVerify
     */
    type?: string;
}
/**
 *
 * @export
 * @interface AdminJsonldAdminWrite
 */
export interface AdminJsonldAdminWrite {
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminWrite
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminWrite
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminWrite
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminWrite
     */
    email?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof AdminJsonldAdminWrite
     */
    phone?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof AdminJsonldAdminWrite
     */
    address?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {number}
     * @memberof AdminJsonldAdminWrite
     */
    level?: number;
    /**
     * ハッシュしていない平文のパスワード これはデーターベースへ保存しない
     * @type {string}
     * @memberof AdminJsonldAdminWrite
     */
    plainPassword?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof AdminJsonldAdminWrite
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface AdminJsonldAdminWriteUpdate
 */
export interface AdminJsonldAdminWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    email?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    phone?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    address?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {number}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    level?: number;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    verified?: boolean;
    /**
     * ニックネーム
     * @type {string}
     * @memberof AdminJsonldAdminWriteUpdate
     */
    name?: string | null;
}
/**
 * ピア認証用エンティティ これはデーターベースには保存しない
 * @export
 * @interface AuthenticateJsonldPeerRead
 */
export interface AuthenticateJsonldPeerRead {
    /**
     *
     * @type {string}
     * @memberof AuthenticateJsonldPeerRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof AuthenticateJsonldPeerRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof AuthenticateJsonldPeerRead
     */
    type?: string;
    /**
     * ピアID(ユーザーIDまたはMW10のシリアル番号の内部表現文字列またはゲスト用に生成したUlid)
     * @type {string}
     * @memberof AuthenticateJsonldPeerRead
     */
    peerId?: string;
    /**
     * レスポンス用
     * @type {string}
     * @memberof AuthenticateJsonldPeerRead
     */
    authToken?: string;
    /**
     * レスポンス用
     * @type {number}
     * @memberof AuthenticateJsonldPeerRead
     */
    ttl?: number;
    /**
     * レスポンス用
     * @type {number}
     * @memberof AuthenticateJsonldPeerRead
     */
    timestamp?: number;
}
/**
 * ピア認証用エンティティ これはデーターベースには保存しない
 * @export
 * @interface AuthenticateJsonldPeerWrite
 */
export interface AuthenticateJsonldPeerWrite {
    /**
     *
     * @type {string}
     * @memberof AuthenticateJsonldPeerWrite
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof AuthenticateJsonldPeerWrite
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof AuthenticateJsonldPeerWrite
     */
    type?: string;
    /**
     * ピアID(ユーザーIDまたはMW10のシリアル番号の内部表現文字列またはゲスト用に生成したUlid)
     * @type {string}
     * @memberof AuthenticateJsonldPeerWrite
     */
    peerId?: string;
    /**
     * セッショントークン
     * @type {string}
     * @memberof AuthenticateJsonldPeerWrite
     */
    sessionToken?: string;
    /**
     * 前回のピア認証時の認証トークン
     * @type {string}
     * @memberof AuthenticateJsonldPeerWrite
     */
    prevAuthToken?: string | null;
    /**
     * p2p通話する相手のペアID (リモートチャット1対多とMW10からのアクセス時は未設定)
     * @type {string}
     * @memberof AuthenticateJsonldPeerWrite
     */
    pairId?: string | null;
    /**
     * 参加するグループのグループID (p2pのときは未設定)
     * @type {string}
     * @memberof AuthenticateJsonldPeerWrite
     */
    groupId?: string | null;
}
/**
 * ピア認証用エンティティ これはデーターベースには保存しない
 * @export
 * @interface AuthenticatePeerRead
 */
export interface AuthenticatePeerRead {
    /**
     * ピアID(ユーザーIDまたはMW10のシリアル番号の内部表現文字列またはゲスト用に生成したUlid)
     * @type {string}
     * @memberof AuthenticatePeerRead
     */
    peerId?: string;
    /**
     * レスポンス用
     * @type {string}
     * @memberof AuthenticatePeerRead
     */
    authToken?: string;
    /**
     * レスポンス用
     * @type {number}
     * @memberof AuthenticatePeerRead
     */
    ttl?: number;
    /**
     * レスポンス用
     * @type {number}
     * @memberof AuthenticatePeerRead
     */
    timestamp?: number;
}
/**
 * ピア認証用エンティティ これはデーターベースには保存しない
 * @export
 * @interface AuthenticatePeerWrite
 */
export interface AuthenticatePeerWrite {
    /**
     * ピアID(ユーザーIDまたはMW10のシリアル番号の内部表現文字列またはゲスト用に生成したUlid)
     * @type {string}
     * @memberof AuthenticatePeerWrite
     */
    peerId?: string;
    /**
     * セッショントークン
     * @type {string}
     * @memberof AuthenticatePeerWrite
     */
    sessionToken?: string;
    /**
     * 前回のピア認証時の認証トークン
     * @type {string}
     * @memberof AuthenticatePeerWrite
     */
    prevAuthToken?: string | null;
    /**
     * p2p通話する相手のペアID (リモートチャット1対多とMW10からのアクセス時は未設定)
     * @type {string}
     * @memberof AuthenticatePeerWrite
     */
    pairId?: string | null;
    /**
     * 参加するグループのグループID (p2pのときは未設定)
     * @type {string}
     * @memberof AuthenticatePeerWrite
     */
    groupId?: string | null;
}
/**
 *
 * @export
 * @interface ConnectionConnectionRead
 */
export interface ConnectionConnectionRead {
    /**
     *
     * @type {number}
     * @memberof ConnectionConnectionRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ConnectionConnectionRead
     */
    peerId?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionConnectionRead
     */
    start?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionConnectionRead
     */
    end?: string | null;
    /**
     * 接続時間[ミリ秒]
     * @type {number}
     * @memberof ConnectionConnectionRead
     */
    duration?: number | null;
    /**
     *
     * @type {string}
     * @memberof ConnectionConnectionRead
     */
    authToken?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionConnectionRead
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface ConnectionConnectionWrite
 */
export interface ConnectionConnectionWrite {
    /**
     *
     * @type {string}
     * @memberof ConnectionConnectionWrite
     */
    peerId?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionConnectionWrite
     */
    end?: string | null;
    /**
     * 接続時間[ミリ秒]
     * @type {number}
     * @memberof ConnectionConnectionWrite
     */
    duration?: number | null;
    /**
     *
     * @type {string}
     * @memberof ConnectionConnectionWrite
     */
    authToken?: string;
}
/**
 *
 * @export
 * @interface ConnectionJsonldConnectionRead
 */
export interface ConnectionJsonldConnectionRead {
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionRead
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionRead
     */
    peerId?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionRead
     */
    start?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionRead
     */
    end?: string | null;
    /**
     * 接続時間[ミリ秒]
     * @type {number}
     * @memberof ConnectionJsonldConnectionRead
     */
    duration?: number | null;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionRead
     */
    authToken?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionRead
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface ConnectionJsonldConnectionWrite
 */
export interface ConnectionJsonldConnectionWrite {
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionWrite
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionWrite
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionWrite
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionWrite
     */
    peerId?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionWrite
     */
    end?: string | null;
    /**
     * 接続時間[ミリ秒]
     * @type {number}
     * @memberof ConnectionJsonldConnectionWrite
     */
    duration?: number | null;
    /**
     *
     * @type {string}
     * @memberof ConnectionJsonldConnectionWrite
     */
    authToken?: string;
}
/**
 *
 * @export
 * @interface ConnectionSummaryJsonldSummaryRead
 */
export interface ConnectionSummaryJsonldSummaryRead {
    /**
     *
     * @type {string}
     * @memberof ConnectionSummaryJsonldSummaryRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionSummaryJsonldSummaryRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionSummaryJsonldSummaryRead
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectionSummaryJsonldSummaryRead
     */
    key?: string;
    /**
     *
     * @type {number}
     * @memberof ConnectionSummaryJsonldSummaryRead
     */
    counts?: number;
    /**
     *
     * @type {number}
     * @memberof ConnectionSummaryJsonldSummaryRead
     */
    durations?: number;
    /**
     *
     * @type {string}
     * @memberof ConnectionSummaryJsonldSummaryRead
     */
    customer?: string;
}
/**
 *
 * @export
 * @interface ConnectionSummarySummaryRead
 */
export interface ConnectionSummarySummaryRead {
    /**
     *
     * @type {string}
     * @memberof ConnectionSummarySummaryRead
     */
    key?: string;
    /**
     *
     * @type {number}
     * @memberof ConnectionSummarySummaryRead
     */
    counts?: number;
    /**
     *
     * @type {number}
     * @memberof ConnectionSummarySummaryRead
     */
    durations?: number;
    /**
     *
     * @type {string}
     * @memberof ConnectionSummarySummaryRead
     */
    customer?: string;
}
/**
 *
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     *
     * @type {string}
     * @memberof Credentials
     */
    username?: string;
    /**
     *
     * @type {string}
     * @memberof Credentials
     */
    password?: string;
}
/**
 *
 * @export
 * @interface CustomerCustomerRead
 */
export interface CustomerCustomerRead {
    /**
     * カスタマーID(ユーザー読み取り可)
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    id?: string;
    /**
     * カスタマータイプ(ユーザー読み取り可) 0: 一般ユーザー(unspecified) 1: B2B
     * @type {number}
     * @memberof CustomerCustomerRead
     */
    type?: number;
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerCustomerRead
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    name?: string;
}
/**
 *
 * @export
 * @interface CustomerCustomerReadCreate
 */
export interface CustomerCustomerReadCreate {
    /**
     * カスタマーID(ユーザー読み取り可)
     * @type {string}
     * @memberof CustomerCustomerReadCreate
     */
    id?: string;
    /**
     * カスタマータイプ(ユーザー読み取り可) 0: 一般ユーザー(unspecified) 1: B2B
     * @type {number}
     * @memberof CustomerCustomerReadCreate
     */
    type?: number;
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerCustomerReadCreate
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerCustomerReadCreate
     */
    name?: string;
    /**
     * メールアドレス
     * @type {Array<string>}
     * @memberof CustomerCustomerReadCreate
     */
    email?: Array<string> | null;
    /**
     * 電話番号 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerCustomerReadCreate
     */
    phone?: Array<string> | null;
    /**
     * 住所 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerCustomerReadCreate
     */
    address?: Array<string> | null;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof CustomerCustomerReadCreate
     */
    verified?: boolean;
    /**
     * メールアドレス確認メールを送信したかどうか !verifyed && !booleanなら メールアドレス確認メールを送信してこの値をtrueにする
     * @type {boolean}
     * @memberof CustomerCustomerReadCreate
     */
    verifyMailSent?: boolean;
}
/**
 *
 * @export
 * @interface CustomerCustomerReadUpdate
 */
export interface CustomerCustomerReadUpdate {
    /**
     * カスタマーID(ユーザー読み取り可)
     * @type {string}
     * @memberof CustomerCustomerReadUpdate
     */
    id?: string;
    /**
     * カスタマータイプ(ユーザー読み取り可) 0: 一般ユーザー(unspecified) 1: B2B
     * @type {number}
     * @memberof CustomerCustomerReadUpdate
     */
    type?: number;
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerCustomerReadUpdate
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerCustomerReadUpdate
     */
    name?: string;
    /**
     * メールアドレス
     * @type {Array<string>}
     * @memberof CustomerCustomerReadUpdate
     */
    email?: Array<string> | null;
    /**
     * 電話番号 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerCustomerReadUpdate
     */
    phone?: Array<string> | null;
    /**
     * 住所 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerCustomerReadUpdate
     */
    address?: Array<string> | null;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof CustomerCustomerReadUpdate
     */
    verified?: boolean;
    /**
     * メールアドレス確認メールを送信したかどうか !verifyed && !booleanなら メールアドレス確認メールを送信してこの値をtrueにする
     * @type {boolean}
     * @memberof CustomerCustomerReadUpdate
     */
    verifyMailSent?: boolean;
}
/**
 *
 * @export
 * @interface CustomerCustomerWriteCreate
 */
export interface CustomerCustomerWriteCreate {
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerCustomerWriteCreate
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerCustomerWriteCreate
     */
    name?: string;
    /**
     * メールアドレス
     * @type {Array<string>}
     * @memberof CustomerCustomerWriteCreate
     */
    email?: Array<string> | null;
    /**
     * 電話番号 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerCustomerWriteCreate
     */
    phone?: Array<string> | null;
    /**
     * 住所 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerCustomerWriteCreate
     */
    address?: Array<string> | null;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof CustomerCustomerWriteCreate
     */
    verified?: boolean;
    /**
     * メールアドレス確認メールを送信したかどうか !verifyed && !booleanなら メールアドレス確認メールを送信してこの値をtrueにする
     * @type {boolean}
     * @memberof CustomerCustomerWriteCreate
     */
    verifyMailSent?: boolean;
}
/**
 *
 * @export
 * @interface CustomerCustomerWriteUpdate
 */
export interface CustomerCustomerWriteUpdate {
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerCustomerWriteUpdate
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerCustomerWriteUpdate
     */
    name?: string;
    /**
     * メールアドレス
     * @type {Array<string>}
     * @memberof CustomerCustomerWriteUpdate
     */
    email?: Array<string> | null;
    /**
     * 電話番号 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerCustomerWriteUpdate
     */
    phone?: Array<string> | null;
    /**
     * 住所 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerCustomerWriteUpdate
     */
    address?: Array<string> | null;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof CustomerCustomerWriteUpdate
     */
    verified?: boolean;
    /**
     * メールアドレス確認メールを送信したかどうか !verifyed && !booleanなら メールアドレス確認メールを送信してこの値をtrueにする
     * @type {boolean}
     * @memberof CustomerCustomerWriteUpdate
     */
    verifyMailSent?: boolean;
}
/**
 *
 * @export
 * @interface CustomerJsonldCustomerRead
 */
export interface CustomerJsonldCustomerRead {
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerRead
     */
    context?: string;
    /**
     * カスタマーID(ユーザー読み取り可)
     * @type {string}
     * @memberof CustomerJsonldCustomerRead
     */
    id?: string;
    /**
     * カスタマータイプ(ユーザー読み取り可) 0: 一般ユーザー(unspecified) 1: B2B
     * @type {number}
     * @memberof CustomerJsonldCustomerRead
     */
    type?: number;
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerRead
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerJsonldCustomerRead
     */
    name?: string;
}
/**
 *
 * @export
 * @interface CustomerJsonldCustomerReadCreate
 */
export interface CustomerJsonldCustomerReadCreate {
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    context?: string;
    /**
     * カスタマーID(ユーザー読み取り可)
     * @type {string}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    id?: string;
    /**
     * カスタマータイプ(ユーザー読み取り可) 0: 一般ユーザー(unspecified) 1: B2B
     * @type {number}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    type?: number;
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    name?: string;
    /**
     * メールアドレス
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    email?: Array<string> | null;
    /**
     * 電話番号 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    phone?: Array<string> | null;
    /**
     * 住所 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    address?: Array<string> | null;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    verified?: boolean;
    /**
     * メールアドレス確認メールを送信したかどうか !verifyed && !booleanなら メールアドレス確認メールを送信してこの値をtrueにする
     * @type {boolean}
     * @memberof CustomerJsonldCustomerReadCreate
     */
    verifyMailSent?: boolean;
}
/**
 *
 * @export
 * @interface CustomerJsonldCustomerReadUpdate
 */
export interface CustomerJsonldCustomerReadUpdate {
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    context?: string;
    /**
     * カスタマーID(ユーザー読み取り可)
     * @type {string}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    id?: string;
    /**
     * カスタマータイプ(ユーザー読み取り可) 0: 一般ユーザー(unspecified) 1: B2B
     * @type {number}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    type?: number;
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    name?: string;
    /**
     * メールアドレス
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    email?: Array<string> | null;
    /**
     * 電話番号 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    phone?: Array<string> | null;
    /**
     * 住所 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    address?: Array<string> | null;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    verified?: boolean;
    /**
     * メールアドレス確認メールを送信したかどうか !verifyed && !booleanなら メールアドレス確認メールを送信してこの値をtrueにする
     * @type {boolean}
     * @memberof CustomerJsonldCustomerReadUpdate
     */
    verifyMailSent?: boolean;
}
/**
 *
 * @export
 * @interface CustomerJsonldCustomerWriteCreate
 */
export interface CustomerJsonldCustomerWriteCreate {
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    type?: string;
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    name?: string;
    /**
     * メールアドレス
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    email?: Array<string> | null;
    /**
     * 電話番号 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    phone?: Array<string> | null;
    /**
     * 住所 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    address?: Array<string> | null;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    verified?: boolean;
    /**
     * メールアドレス確認メールを送信したかどうか !verifyed && !booleanなら メールアドレス確認メールを送信してこの値をtrueにする
     * @type {boolean}
     * @memberof CustomerJsonldCustomerWriteCreate
     */
    verifyMailSent?: boolean;
}
/**
 *
 * @export
 * @interface CustomerJsonldCustomerWriteUpdate
 */
export interface CustomerJsonldCustomerWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    type?: string;
    /**
     * 対応機能(ユーザー読み取り専用) これはfeaturesetから取得するだけでデーターベースには保存しない、読み取り専用
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    features?: Array<string>;
    /**
     * 名称
     * @type {string}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    name?: string;
    /**
     * メールアドレス
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    email?: Array<string> | null;
    /**
     * 電話番号 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    phone?: Array<string> | null;
    /**
     * 住所 複数保持できるようにJSONにしておく(JSONArray)
     * @type {Array<string>}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    address?: Array<string> | null;
    /**
     * メールアドレスを確認済みかどうか
     * @type {boolean}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    verified?: boolean;
    /**
     * メールアドレス確認メールを送信したかどうか !verifyed && !booleanなら メールアドレス確認メールを送信してこの値をtrueにする
     * @type {boolean}
     * @memberof CustomerJsonldCustomerWriteUpdate
     */
    verifyMailSent?: boolean;
}
/**
 *
 * @export
 * @interface DeviceDeviceRead
 */
export interface DeviceDeviceRead {
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceRead
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceRead
     */
    serialNumber?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceRead
     */
    printedSerial?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {string}
     * @memberof DeviceDeviceRead
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceRead
     */
    registered?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceRead
     */
    note?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof DeviceDeviceRead
     */
    pairs?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceRead
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface DeviceDeviceReadCreate
 */
export interface DeviceDeviceReadCreate {
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceReadCreate
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceReadCreate
     */
    serialNumber?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceReadCreate
     */
    printedSerial?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {string}
     * @memberof DeviceDeviceReadCreate
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceReadCreate
     */
    registered?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceReadCreate
     */
    note?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof DeviceDeviceReadCreate
     */
    pairs?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceReadCreate
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface DeviceDeviceWrite
 */
export interface DeviceDeviceWrite {
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {string}
     * @memberof DeviceDeviceWrite
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceWrite
     */
    registered?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceWrite
     */
    note?: string | null;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceWrite
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface DeviceDeviceWriteCreate
 */
export interface DeviceDeviceWriteCreate {
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceWriteCreate
     */
    printedSerial?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {string}
     * @memberof DeviceDeviceWriteCreate
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceWriteCreate
     */
    registered?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceWriteCreate
     */
    note?: string | null;
    /**
     *
     * @type {string}
     * @memberof DeviceDeviceWriteCreate
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface DeviceJsonldDeviceRead
 */
export interface DeviceJsonldDeviceRead {
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    serialNumber?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    printedSerial?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    registered?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    note?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof DeviceJsonldDeviceRead
     */
    pairs?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceRead
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface DeviceJsonldDeviceReadCreate
 */
export interface DeviceJsonldDeviceReadCreate {
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    serialNumber?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    printedSerial?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    registered?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    note?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    pairs?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceReadCreate
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface DeviceJsonldDeviceWrite
 */
export interface DeviceJsonldDeviceWrite {
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWrite
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWrite
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWrite
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {string}
     * @memberof DeviceJsonldDeviceWrite
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWrite
     */
    registered?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWrite
     */
    note?: string | null;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWrite
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface DeviceJsonldDeviceWriteCreate
 */
export interface DeviceJsonldDeviceWriteCreate {
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWriteCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWriteCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWriteCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWriteCreate
     */
    printedSerial?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\')\"},
     * @type {string}
     * @memberof DeviceJsonldDeviceWriteCreate
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWriteCreate
     */
    registered?: string;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWriteCreate
     */
    note?: string | null;
    /**
     *
     * @type {string}
     * @memberof DeviceJsonldDeviceWriteCreate
     */
    customer?: string | null;
}
/**
 *
 * @export
 * @interface FeatureCustomerRead
 */
export interface FeatureCustomerRead {
    /**
     *
     * @type {string}
     * @memberof FeatureCustomerRead
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeatureCustomerRead
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureCustomerRead
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureCustomerReadCreate
 */
export interface FeatureCustomerReadCreate {
    /**
     *
     * @type {string}
     * @memberof FeatureCustomerReadCreate
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeatureCustomerReadCreate
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureCustomerReadCreate
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureCustomerReadUpdate
 */
export interface FeatureCustomerReadUpdate {
    /**
     *
     * @type {string}
     * @memberof FeatureCustomerReadUpdate
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof FeatureCustomerReadUpdate
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureCustomerReadUpdate
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureCustomerWriteCreate
 */
export interface FeatureCustomerWriteCreate {
    /**
     *
     * @type {string}
     * @memberof FeatureCustomerWriteCreate
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureCustomerWriteCreate
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureCustomerWriteUpdate
 */
export interface FeatureCustomerWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof FeatureCustomerWriteUpdate
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureCustomerWriteUpdate
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureJsonldCustomerRead
 */
export interface FeatureJsonldCustomerRead {
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerRead
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerRead
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureJsonldCustomerRead
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureJsonldCustomerReadCreate
 */
export interface FeatureJsonldCustomerReadCreate {
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerReadCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerReadCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerReadCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerReadCreate
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureJsonldCustomerReadCreate
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureJsonldCustomerReadUpdate
 */
export interface FeatureJsonldCustomerReadUpdate {
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerReadUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerReadUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerReadUpdate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerReadUpdate
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureJsonldCustomerReadUpdate
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureJsonldCustomerWriteCreate
 */
export interface FeatureJsonldCustomerWriteCreate {
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerWriteCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerWriteCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerWriteCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerWriteCreate
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureJsonldCustomerWriteCreate
     */
    options?: Array<string>;
}
/**
 *
 * @export
 * @interface FeatureJsonldCustomerWriteUpdate
 */
export interface FeatureJsonldCustomerWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerWriteUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerWriteUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerWriteUpdate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof FeatureJsonldCustomerWriteUpdate
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FeatureJsonldCustomerWriteUpdate
     */
    options?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupGroupRead
 */
export interface GroupGroupRead {
    /**
     *
     * @type {string}
     * @memberof GroupGroupRead
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof GroupGroupRead
     */
    name?: string;
    /**
     *
     * @type {UserGroupRead}
     * @memberof GroupGroupRead
     */
    owner?: UserGroupRead;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupGroupRead
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupGroupRead
     */
    end?: string | null;
    /**
     *
     * @type {Array<PairGroupRead>}
     * @memberof GroupGroupRead
     */
    memberPairs?: Array<PairGroupRead>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupGroupRead
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupGroupReadAvailables
 */
export interface GroupGroupReadAvailables {
    /**
     *
     * @type {string}
     * @memberof GroupGroupReadAvailables
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof GroupGroupReadAvailables
     */
    name?: string;
    /**
     *
     * @type {UserGroupReadAvailables}
     * @memberof GroupGroupReadAvailables
     */
    owner?: UserGroupReadAvailables;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupGroupReadAvailables
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupGroupReadAvailables
     */
    end?: string | null;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupGroupReadCreate
 */
export interface GroupGroupReadCreate {
    /**
     *
     * @type {string}
     * @memberof GroupGroupReadCreate
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof GroupGroupReadCreate
     */
    name?: string;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof GroupGroupReadCreate
     */
    owner?: string;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupGroupReadCreate
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupGroupReadCreate
     */
    end?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof GroupGroupReadCreate
     */
    memberPairs?: Array<string>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupGroupReadCreate
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupGroupReadUpdate
 */
export interface GroupGroupReadUpdate {
    /**
     *
     * @type {string}
     * @memberof GroupGroupReadUpdate
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof GroupGroupReadUpdate
     */
    name?: string;
    /**
     *
     * @type {UserGroupReadUpdate}
     * @memberof GroupGroupReadUpdate
     */
    owner?: UserGroupReadUpdate;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupGroupReadUpdate
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupGroupReadUpdate
     */
    end?: string | null;
    /**
     *
     * @type {Array<PairGroupReadUpdate>}
     * @memberof GroupGroupReadUpdate
     */
    memberPairs?: Array<PairGroupReadUpdate>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupGroupReadUpdate
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupGroupWriteCreate
 */
export interface GroupGroupWriteCreate {
    /**
     *
     * @type {string}
     * @memberof GroupGroupWriteCreate
     */
    name?: string;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof GroupGroupWriteCreate
     */
    owner?: string;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupGroupWriteCreate
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupGroupWriteCreate
     */
    end?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof GroupGroupWriteCreate
     */
    memberPairs?: Array<string>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupGroupWriteCreate
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupGroupWriteUpdate
 */
export interface GroupGroupWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof GroupGroupWriteUpdate
     */
    name?: string;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupGroupWriteUpdate
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupGroupWriteUpdate
     */
    end?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof GroupGroupWriteUpdate
     */
    memberPairs?: Array<string>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupGroupWriteUpdate
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupJsonldGroupRead
 */
export interface GroupJsonldGroupRead {
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupRead
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupRead
     */
    name?: string;
    /**
     *
     * @type {UserJsonldGroupRead}
     * @memberof GroupJsonldGroupRead
     */
    owner?: UserJsonldGroupRead;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupJsonldGroupRead
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupJsonldGroupRead
     */
    end?: string | null;
    /**
     *
     * @type {Array<PairJsonldGroupRead>}
     * @memberof GroupJsonldGroupRead
     */
    memberPairs?: Array<PairJsonldGroupRead>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupJsonldGroupRead
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupJsonldGroupReadAvailables
 */
export interface GroupJsonldGroupReadAvailables {
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadAvailables
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadAvailables
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadAvailables
     */
    name?: string;
    /**
     *
     * @type {UserJsonldGroupReadAvailables}
     * @memberof GroupJsonldGroupReadAvailables
     */
    owner?: UserJsonldGroupReadAvailables;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupJsonldGroupReadAvailables
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupJsonldGroupReadAvailables
     */
    end?: string | null;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupJsonldGroupReadCreate
 */
export interface GroupJsonldGroupReadCreate {
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadCreate
     */
    name?: string;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof GroupJsonldGroupReadCreate
     */
    owner?: string;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupJsonldGroupReadCreate
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupJsonldGroupReadCreate
     */
    end?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof GroupJsonldGroupReadCreate
     */
    memberPairs?: Array<string>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupJsonldGroupReadCreate
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupJsonldGroupReadUpdate
 */
export interface GroupJsonldGroupReadUpdate {
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadUpdate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupReadUpdate
     */
    name?: string;
    /**
     *
     * @type {UserJsonldGroupReadUpdate}
     * @memberof GroupJsonldGroupReadUpdate
     */
    owner?: UserJsonldGroupReadUpdate;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupJsonldGroupReadUpdate
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupJsonldGroupReadUpdate
     */
    end?: string | null;
    /**
     *
     * @type {Array<PairJsonldGroupReadUpdate>}
     * @memberof GroupJsonldGroupReadUpdate
     */
    memberPairs?: Array<PairJsonldGroupReadUpdate>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupJsonldGroupReadUpdate
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupJsonldGroupWriteCreate
 */
export interface GroupJsonldGroupWriteCreate {
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupWriteCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupWriteCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupWriteCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupWriteCreate
     */
    name?: string;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof GroupJsonldGroupWriteCreate
     */
    owner?: string;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupJsonldGroupWriteCreate
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupJsonldGroupWriteCreate
     */
    end?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof GroupJsonldGroupWriteCreate
     */
    memberPairs?: Array<string>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupJsonldGroupWriteCreate
     */
    memberGuests?: Array<string>;
}
/**
 * /groupsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /groups、/groups/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface GroupJsonldGroupWriteUpdate
 */
export interface GroupJsonldGroupWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupWriteUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupWriteUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupWriteUpdate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof GroupJsonldGroupWriteUpdate
     */
    name?: string;
    /**
     * 開始予定日時
     * @type {string}
     * @memberof GroupJsonldGroupWriteUpdate
     */
    start?: string | null;
    /**
     * 終了予定日時
     * @type {string}
     * @memberof GroupJsonldGroupWriteUpdate
     */
    end?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof GroupJsonldGroupWriteUpdate
     */
    memberPairs?: Array<string>;
    /**
     * ゲストメンバーのメールアドレス配列
     * @type {Array<string>}
     * @memberof GroupJsonldGroupWriteUpdate
     */
    memberGuests?: Array<string>;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     *
     * @type {string}
     * @memberof InlineObject
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject
     */
    plainPassword?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject
     */
    token?: string;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     *
     * @type {string}
     * @memberof InlineObject1
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject1
     */
    target?: string;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     *
     * @type {string}
     * @memberof InlineObject2
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject2
     */
    target?: string;
}
/**
 *
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     *
     * @type {string}
     * @memberof InlineObject3
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject3
     */
    plainPassword?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject3
     */
    token?: string;
}
/**
 *
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     *
     * @type {string}
     * @memberof InlineObject4
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject4
     */
    plainPassword?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject4
     */
    token?: string;
}
/**
 *
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     *
     * @type {string}
     * @memberof InlineObject5
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof InlineObject5
     */
    target?: string;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     *
     * @type {Array<AdminJsonldAdminRead>}
     * @memberof InlineResponse200
     */
    'hydra:member': Array<AdminJsonldAdminRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse200
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse200
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse200
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     *
     * @type {Array<ConnectionSummaryJsonldSummaryRead>}
     * @memberof InlineResponse2001
     */
    'hydra:member': Array<ConnectionSummaryJsonldSummaryRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2001
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2001
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2001
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     *
     * @type {Array<LogJsonldLogRead>}
     * @memberof InlineResponse20010
     */
    'hydra:member': Array<LogJsonldLogRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20010
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20010
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20010
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     *
     * @type {Array<NotificationJsonldNotifyRead>}
     * @memberof InlineResponse20011
     */
    'hydra:member': Array<NotificationJsonldNotifyRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20011
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20011
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20011
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     *
     * @type {Array<PairJsonldPairReadAvailables>}
     * @memberof InlineResponse20012
     */
    'hydra:member': Array<PairJsonldPairReadAvailables>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20012
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20012
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20012
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     *
     * @type {Array<ServiceJsonldServiceRead>}
     * @memberof InlineResponse20013
     */
    'hydra:member': Array<ServiceJsonldServiceRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20013
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20013
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20013
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     *
     * @type {Array<SettingJsonldSettingRead>}
     * @memberof InlineResponse20014
     */
    'hydra:member': Array<SettingJsonldSettingRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20014
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20014
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20014
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     *
     * @type {Array<UserJsonldUserRead>}
     * @memberof InlineResponse20015
     */
    'hydra:member': Array<UserJsonldUserRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20015
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20015
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20015
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     *
     * @type {Array<UserJsonldUserReadExtra>}
     * @memberof InlineResponse20016
     */
    'hydra:member': Array<UserJsonldUserReadExtra>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20016
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20016
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20016
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     *
     * @type {Array<ConnectionJsonldConnectionRead>}
     * @memberof InlineResponse2002
     */
    'hydra:member': Array<ConnectionJsonldConnectionRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2002
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2002
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2002
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     *
     * @type {Array<CustomerJsonldCustomerReadUpdate>}
     * @memberof InlineResponse2003
     */
    'hydra:member': Array<CustomerJsonldCustomerReadUpdate>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2003
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2003
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2003
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     *
     * @type {Array<CustomerJsonldCustomerRead>}
     * @memberof InlineResponse2004
     */
    'hydra:member': Array<CustomerJsonldCustomerRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2004
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2004
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2004
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     *
     * @type {Array<DeviceJsonldDeviceRead>}
     * @memberof InlineResponse2005
     */
    'hydra:member': Array<DeviceJsonldDeviceRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2005
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2005
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2005
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     *
     * @type {Array<FeatureJsonldCustomerReadUpdate>}
     * @memberof InlineResponse2006
     */
    'hydra:member': Array<FeatureJsonldCustomerReadUpdate>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2006
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2006
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2006
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     *
     * @type {Array<GroupJsonldGroupRead>}
     * @memberof InlineResponse2007
     */
    'hydra:member': Array<GroupJsonldGroupRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2007
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2007
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2007
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     *
     * @type {Array<PairJsonldPairRead>}
     * @memberof InlineResponse2008
     */
    'hydra:member': Array<PairJsonldPairRead>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2008
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2008
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2008
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     *
     * @type {Array<GroupJsonldGroupReadAvailables>}
     * @memberof InlineResponse2009
     */
    'hydra:member': Array<GroupJsonldGroupReadAvailables>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2009
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2009
     */
    'hydra:view'?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2009
     */
    'hydra:search'?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraSearch
 */
export interface InlineResponse200HydraSearch {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    'hydra:template'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     *
     * @type {Array<InlineResponse200HydraSearchHydraMapping>}
     * @memberof InlineResponse200HydraSearch
     */
    'hydra:mapping'?: Array<InlineResponse200HydraSearchHydraMapping>;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraSearchHydraMapping
 */
export interface InlineResponse200HydraSearchHydraMapping {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    variable?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    property?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    required?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraView
 */
export interface InlineResponse200HydraView {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    'hydra:first'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    'hydra:last'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    'hydra:next'?: string;
}
/**
 *
 * @export
 * @interface LogJsonldLogRead
 */
export interface LogJsonldLogRead {
    /**
     *
     * @type {string}
     * @memberof LogJsonldLogRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof LogJsonldLogRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LogJsonldLogRead
     */
    type?: string;
}
/**
 *
 * @export
 * @interface NotificationJsonldNotifyRead
 */
export interface NotificationJsonldNotifyRead {
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyRead
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyRead
     */
    dateTime?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationJsonldNotifyRead
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface NotificationJsonldNotifyReadCreate
 */
export interface NotificationJsonldNotifyReadCreate {
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyReadCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyReadCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyReadCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyReadCreate
     */
    dateTime?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationJsonldNotifyReadCreate
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface NotificationJsonldNotifyWrite
 */
export interface NotificationJsonldNotifyWrite {
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyWrite
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyWrite
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyWrite
     */
    type?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationJsonldNotifyWrite
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface NotificationJsonldNotifyWriteCreate
 */
export interface NotificationJsonldNotifyWriteCreate {
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyWriteCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyWriteCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationJsonldNotifyWriteCreate
     */
    type?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationJsonldNotifyWriteCreate
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface NotificationNotifyRead
 */
export interface NotificationNotifyRead {
    /**
     *
     * @type {string}
     * @memberof NotificationNotifyRead
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof NotificationNotifyRead
     */
    dateTime?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationNotifyRead
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface NotificationNotifyReadCreate
 */
export interface NotificationNotifyReadCreate {
    /**
     *
     * @type {string}
     * @memberof NotificationNotifyReadCreate
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof NotificationNotifyReadCreate
     */
    dateTime?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationNotifyReadCreate
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface NotificationNotifyWrite
 */
export interface NotificationNotifyWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationNotifyWrite
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface NotificationNotifyWriteCreate
 */
export interface NotificationNotifyWriteCreate {
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationNotifyWriteCreate
     */
    items?: Array<string>;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairGroupRead
 */
export interface PairGroupRead {
    /**
     *
     * @type {string}
     * @memberof PairGroupRead
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof PairGroupRead
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairGroupRead
     */
    email?: string | null;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairGroupRead
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairGroupRead
     */
    peerId?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairGroupReadUpdate
 */
export interface PairGroupReadUpdate {
    /**
     *
     * @type {string}
     * @memberof PairGroupReadUpdate
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof PairGroupReadUpdate
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairGroupReadUpdate
     */
    email?: string | null;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairGroupReadUpdate
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairGroupReadUpdate
     */
    peerId?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldGroupRead
 */
export interface PairJsonldGroupRead {
    /**
     *
     * @type {string}
     * @memberof PairJsonldGroupRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldGroupRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldGroupRead
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldGroupRead
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairJsonldGroupRead
     */
    email?: string | null;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldGroupRead
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldGroupRead
     */
    peerId?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldGroupReadUpdate
 */
export interface PairJsonldGroupReadUpdate {
    /**
     *
     * @type {string}
     * @memberof PairJsonldGroupReadUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldGroupReadUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldGroupReadUpdate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldGroupReadUpdate
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairJsonldGroupReadUpdate
     */
    email?: string | null;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldGroupReadUpdate
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldGroupReadUpdate
     */
    peerId?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldPairRead
 */
export interface PairJsonldPairRead {
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    type?: string;
    /**
     *
     * @type {UserJsonldPairRead}
     * @memberof PairJsonldPairRead
     */
    owner?: UserJsonldPairRead;
    /**
     * ペア設定相手がDeviceの場合
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    device?: string | null;
    /**
     * ペア設定相手がUserの場合
     * @type {UserJsonldPairRead}
     * @memberof PairJsonldPairRead
     */
    user?: UserJsonldPairRead | null;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    email?: string | null;
    /**
     * 検証済みかどうか 通常ユーザーは読み取り専用、管理者は読み書き可能
     * @type {boolean}
     * @memberof PairJsonldPairRead
     */
    verified?: boolean;
    /**
     * ユーザー同士のペア設定時に招待された側の承認リンク メールリンクを踏む代りにwebアプリ内から承認する時のために保持
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    verifyLink?: string | null;
    /**
     * このペア設定で保持しているMW10が参加できるグループ設定一覧
     * @type {Array<string>}
     * @memberof PairJsonldPairRead
     */
    groups?: Array<string>;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldPairRead
     */
    peerId?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldPairReadApproval
 */
export interface PairJsonldPairReadApproval {
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadApproval
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadApproval
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadApproval
     */
    type?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldPairReadAvailables
 */
export interface PairJsonldPairReadAvailables {
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadAvailables
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadAvailables
     */
    type?: string;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof PairJsonldPairReadAvailables
     */
    owner?: string;
    /**
     * ペア設定相手がDeviceの場合
     * @type {string}
     * @memberof PairJsonldPairReadAvailables
     */
    device?: string | null;
    /**
     * ペア設定相手がUserの場合
     * @type {string}
     * @memberof PairJsonldPairReadAvailables
     */
    user?: string | null;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldPairReadCreate
 */
export interface PairJsonldPairReadCreate {
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    type?: string;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    owner?: string;
    /**
     * ペア設定相手がDeviceの場合
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    device?: string | null;
    /**
     * ペア設定相手がUserの場合
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    user?: string | null;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    email?: string | null;
    /**
     * 検証済みかどうか 通常ユーザーは読み取り専用、管理者は読み書き可能
     * @type {boolean}
     * @memberof PairJsonldPairReadCreate
     */
    verified?: boolean;
    /**
     * ユーザー同士のペア設定時に招待された側の承認リンク メールリンクを踏む代りにwebアプリ内から承認する時のために保持
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    verifyLink?: string | null;
    /**
     * このペア設定で保持しているMW10が参加できるグループ設定一覧
     * @type {Array<string>}
     * @memberof PairJsonldPairReadCreate
     */
    groups?: Array<string>;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldPairReadCreate
     */
    peerId?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldPairReadRegister
 */
export interface PairJsonldPairReadRegister {
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadRegister
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadRegister
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairReadRegister
     */
    type?: string;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldPairReadRegister
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldPairReadRegister
     */
    peerId?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairJsonldPairReadRegister
     */
    partner?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairJsonldPairReadRegister
     */
    authCode?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairJsonldPairReadRegister
     */
    token?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldPairWrite
 */
export interface PairJsonldPairWrite {
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWrite
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWrite
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWrite
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWrite
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairJsonldPairWrite
     */
    email?: string | null;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldPairWriteCreate
 */
export interface PairJsonldPairWriteCreate {
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWriteCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWriteCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWriteCreate
     */
    type?: string;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof PairJsonldPairWriteCreate
     */
    owner?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWriteCreate
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairJsonldPairWriteCreate
     */
    email?: string | null;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldPairWriteCreate
     */
    serialNumber?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairJsonldPairWriteRegister
 */
export interface PairJsonldPairWriteRegister {
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWriteRegister
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWriteRegister
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PairJsonldPairWriteRegister
     */
    type?: string;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairJsonldPairWriteRegister
     */
    serialNumber?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairJsonldPairWriteRegister
     */
    partner?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairJsonldPairWriteRegister
     */
    authCode?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairJsonldPairWriteRegister
     */
    token?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairPairRead
 */
export interface PairPairRead {
    /**
     *
     * @type {string}
     * @memberof PairPairRead
     */
    id?: string | null;
    /**
     *
     * @type {UserPairRead}
     * @memberof PairPairRead
     */
    owner?: UserPairRead;
    /**
     * ペア設定相手がDeviceの場合
     * @type {string}
     * @memberof PairPairRead
     */
    device?: string | null;
    /**
     * ペア設定相手がUserの場合
     * @type {UserPairRead}
     * @memberof PairPairRead
     */
    user?: UserPairRead | null;
    /**
     *
     * @type {string}
     * @memberof PairPairRead
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairPairRead
     */
    email?: string | null;
    /**
     * 検証済みかどうか 通常ユーザーは読み取り専用、管理者は読み書き可能
     * @type {boolean}
     * @memberof PairPairRead
     */
    verified?: boolean;
    /**
     * ユーザー同士のペア設定時に招待された側の承認リンク メールリンクを踏む代りにwebアプリ内から承認する時のために保持
     * @type {string}
     * @memberof PairPairRead
     */
    verifyLink?: string | null;
    /**
     * このペア設定で保持しているMW10が参加できるグループ設定一覧
     * @type {Array<string>}
     * @memberof PairPairRead
     */
    groups?: Array<string>;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairPairRead
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairPairRead
     */
    peerId?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairPairReadApproval
 */
export interface PairPairReadApproval {
    /**
     *
     * @type {string}
     * @memberof PairPairReadApproval
     */
    id?: string | null;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairPairReadAvailables
 */
export interface PairPairReadAvailables {
    /**
     *
     * @type {string}
     * @memberof PairPairReadAvailables
     */
    id?: string | null;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof PairPairReadAvailables
     */
    owner?: string;
    /**
     * ペア設定相手がDeviceの場合
     * @type {string}
     * @memberof PairPairReadAvailables
     */
    device?: string | null;
    /**
     * ペア設定相手がUserの場合
     * @type {string}
     * @memberof PairPairReadAvailables
     */
    user?: string | null;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairPairReadCreate
 */
export interface PairPairReadCreate {
    /**
     *
     * @type {string}
     * @memberof PairPairReadCreate
     */
    id?: string | null;
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof PairPairReadCreate
     */
    owner?: string;
    /**
     * ペア設定相手がDeviceの場合
     * @type {string}
     * @memberof PairPairReadCreate
     */
    device?: string | null;
    /**
     * ペア設定相手がUserの場合
     * @type {string}
     * @memberof PairPairReadCreate
     */
    user?: string | null;
    /**
     *
     * @type {string}
     * @memberof PairPairReadCreate
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairPairReadCreate
     */
    email?: string | null;
    /**
     * 検証済みかどうか 通常ユーザーは読み取り専用、管理者は読み書き可能
     * @type {boolean}
     * @memberof PairPairReadCreate
     */
    verified?: boolean;
    /**
     * ユーザー同士のペア設定時に招待された側の承認リンク メールリンクを踏む代りにwebアプリ内から承認する時のために保持
     * @type {string}
     * @memberof PairPairReadCreate
     */
    verifyLink?: string | null;
    /**
     * このペア設定で保持しているMW10が参加できるグループ設定一覧
     * @type {Array<string>}
     * @memberof PairPairReadCreate
     */
    groups?: Array<string>;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairPairReadCreate
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairPairReadCreate
     */
    peerId?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairPairReadRegister
 */
export interface PairPairReadRegister {
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairPairReadRegister
     */
    serialNumber?: string;
    /**
     * ペア設定相手のピアID(機器の場合はシリアル番号、ユーザーの場合はユーザーID) 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairPairReadRegister
     */
    peerId?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairPairReadRegister
     */
    partner?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairPairReadRegister
     */
    authCode?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairPairReadRegister
     */
    token?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairPairWrite
 */
export interface PairPairWrite {
    /**
     *
     * @type {string}
     * @memberof PairPairWrite
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairPairWrite
     */
    email?: string | null;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairPairWriteCreate
 */
export interface PairPairWriteCreate {
    /**
     * これにセットする値はユーザーIDではなくユーザーオブジェクトを参照するためのUri 例：/api/v1/users/ユーザーID
     * @type {string}
     * @memberof PairPairWriteCreate
     */
    owner?: string;
    /**
     *
     * @type {string}
     * @memberof PairPairWriteCreate
     */
    name?: string | null;
    /**
     * ペア設定相手がUserの場合はそのUserのメールアドレス(変更できない) ペア設定相手がDeviceの場合はこのペア設定で保持しているメールアドレス(変更可)
     * @type {string}
     * @memberof PairPairWriteCreate
     */
    email?: string | null;
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairPairWriteCreate
     */
    serialNumber?: string;
}
/**
 * /pairsへのPOSTはログイン中のユーザーと同じユーザーなら可能 /pairs、/pairs/{id}はログイン中のユーザーと同じか特権管理者なら可能
 * @export
 * @interface PairPairWriteRegister
 */
export interface PairPairWriteRegister {
    /**
     * ペア設定相手がDeviceの場合にこのペア設定が保持している機器のシリアル番号 読み取り専用 これはデーターベースでは保持しない
     * @type {string}
     * @memberof PairPairWriteRegister
     */
    serialNumber?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairPairWriteRegister
     */
    partner?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairPairWriteRegister
     */
    authCode?: string;
    /**
     * MW10からのペア登録時のみに使う、データベースへは保持しない
     * @type {string}
     * @memberof PairPairWriteRegister
     */
    token?: string;
}
/**
 *
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
    /**
     *
     * @type {string}
     * @memberof RefreshToken
     */
    refresh_token?: string;
}
/**
 *
 * @export
 * @interface ServiceJsonldServiceRead
 */
export interface ServiceJsonldServiceRead {
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceRead
     */
    type?: string;
    /**
     * このクラスが保持しているサービス情報に関連付けられたユーザーID
     * @type {string}
     * @memberof ServiceJsonldServiceRead
     */
    owner?: string;
    /**
     * サービスの識別子
     * @type {string}
     * @memberof ServiceJsonldServiceRead
     */
    sku?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceJsonldServiceRead
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceRead
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceRead
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceJsonldServiceReadCreate
 */
export interface ServiceJsonldServiceReadCreate {
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceReadCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceReadCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceReadCreate
     */
    type?: string;
    /**
     * このクラスが保持しているサービス情報に関連付けられたユーザーID
     * @type {string}
     * @memberof ServiceJsonldServiceReadCreate
     */
    owner?: string;
    /**
     * サービスの識別子
     * @type {string}
     * @memberof ServiceJsonldServiceReadCreate
     */
    sku?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceJsonldServiceReadCreate
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceReadCreate
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceReadCreate
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceJsonldServiceReadUpdate
 */
export interface ServiceJsonldServiceReadUpdate {
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceReadUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceReadUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceReadUpdate
     */
    type?: string;
    /**
     * このクラスが保持しているサービス情報に関連付けられたユーザーID
     * @type {string}
     * @memberof ServiceJsonldServiceReadUpdate
     */
    owner?: string;
    /**
     * サービスの識別子
     * @type {string}
     * @memberof ServiceJsonldServiceReadUpdate
     */
    sku?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceJsonldServiceReadUpdate
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceReadUpdate
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceReadUpdate
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceJsonldServiceWriteCreate
 */
export interface ServiceJsonldServiceWriteCreate {
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceWriteCreate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceWriteCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceWriteCreate
     */
    type?: string;
    /**
     * このクラスが保持しているサービス情報に関連付けられたユーザーID
     * @type {string}
     * @memberof ServiceJsonldServiceWriteCreate
     */
    owner?: string;
    /**
     * サービスの識別子
     * @type {string}
     * @memberof ServiceJsonldServiceWriteCreate
     */
    sku?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceJsonldServiceWriteCreate
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceWriteCreate
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceWriteCreate
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceJsonldServiceWriteUpdate
 */
export interface ServiceJsonldServiceWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceWriteUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceWriteUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ServiceJsonldServiceWriteUpdate
     */
    type?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceJsonldServiceWriteUpdate
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceWriteUpdate
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceJsonldServiceWriteUpdate
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceServiceRead
 */
export interface ServiceServiceRead {
    /**
     *
     * @type {string}
     * @memberof ServiceServiceRead
     */
    id?: string | null;
    /**
     * このクラスが保持しているサービス情報に関連付けられたユーザーID
     * @type {string}
     * @memberof ServiceServiceRead
     */
    owner?: string;
    /**
     * サービスの識別子
     * @type {string}
     * @memberof ServiceServiceRead
     */
    sku?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceServiceRead
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceServiceRead
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceServiceRead
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceServiceReadCreate
 */
export interface ServiceServiceReadCreate {
    /**
     *
     * @type {string}
     * @memberof ServiceServiceReadCreate
     */
    id?: string | null;
    /**
     * このクラスが保持しているサービス情報に関連付けられたユーザーID
     * @type {string}
     * @memberof ServiceServiceReadCreate
     */
    owner?: string;
    /**
     * サービスの識別子
     * @type {string}
     * @memberof ServiceServiceReadCreate
     */
    sku?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceServiceReadCreate
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceServiceReadCreate
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceServiceReadCreate
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceServiceReadUpdate
 */
export interface ServiceServiceReadUpdate {
    /**
     *
     * @type {string}
     * @memberof ServiceServiceReadUpdate
     */
    id?: string | null;
    /**
     * このクラスが保持しているサービス情報に関連付けられたユーザーID
     * @type {string}
     * @memberof ServiceServiceReadUpdate
     */
    owner?: string;
    /**
     * サービスの識別子
     * @type {string}
     * @memberof ServiceServiceReadUpdate
     */
    sku?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceServiceReadUpdate
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceServiceReadUpdate
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceServiceReadUpdate
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceServiceWriteCreate
 */
export interface ServiceServiceWriteCreate {
    /**
     * このクラスが保持しているサービス情報に関連付けられたユーザーID
     * @type {string}
     * @memberof ServiceServiceWriteCreate
     */
    owner?: string;
    /**
     * サービスの識別子
     * @type {string}
     * @memberof ServiceServiceWriteCreate
     */
    sku?: string;
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceServiceWriteCreate
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceServiceWriteCreate
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceServiceWriteCreate
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface ServiceServiceWriteUpdate
 */
export interface ServiceServiceWriteUpdate {
    /**
     * サービスの有効無効状態 ENABLED/DISABLED
     * @type {string}
     * @memberof ServiceServiceWriteUpdate
     */
    state?: string;
    /**
     * 課金情報
     * @type {Array<string>}
     * @memberof ServiceServiceWriteUpdate
     */
    charge?: Array<string>;
    /**
     * サービス利用制約 例えば有効期限とか開始日時とか終了日時とか 日付時刻はutcとすること
     * @type {Array<string>}
     * @memberof ServiceServiceWriteUpdate
     */
    constraints?: Array<string>;
}
/**
 *
 * @export
 * @interface SettingJsonldSettingRead
 */
export interface SettingJsonldSettingRead {
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingRead
     */
    type?: string;
    /**
     * この設定の持ち主のユーザーID 管理者は個別には保持せずに全員で１つの グローバル設定(ownerId==NilUlid)を使う
     * @type {string}
     * @memberof SettingJsonldSettingRead
     */
    ownerId?: string;
    /**
     * 設定項目 変更した項目のみを保持する
     * @type {Array<string>}
     * @memberof SettingJsonldSettingRead
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface SettingJsonldSettingReadUpdate
 */
export interface SettingJsonldSettingReadUpdate {
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingReadUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingReadUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingReadUpdate
     */
    type?: string;
    /**
     * この設定の持ち主のユーザーID 管理者は個別には保持せずに全員で１つの グローバル設定(ownerId==NilUlid)を使う
     * @type {string}
     * @memberof SettingJsonldSettingReadUpdate
     */
    ownerId?: string;
    /**
     * 設定項目 変更した項目のみを保持する
     * @type {Array<string>}
     * @memberof SettingJsonldSettingReadUpdate
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface SettingJsonldSettingWriteUpdate
 */
export interface SettingJsonldSettingWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingWriteUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingWriteUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof SettingJsonldSettingWriteUpdate
     */
    type?: string;
    /**
     * 設定項目 変更した項目のみを保持する
     * @type {Array<string>}
     * @memberof SettingJsonldSettingWriteUpdate
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface SettingSettingRead
 */
export interface SettingSettingRead {
    /**
     *
     * @type {string}
     * @memberof SettingSettingRead
     */
    id?: string | null;
    /**
     * この設定の持ち主のユーザーID 管理者は個別には保持せずに全員で１つの グローバル設定(ownerId==NilUlid)を使う
     * @type {string}
     * @memberof SettingSettingRead
     */
    ownerId?: string;
    /**
     * 設定項目 変更した項目のみを保持する
     * @type {Array<string>}
     * @memberof SettingSettingRead
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface SettingSettingReadUpdate
 */
export interface SettingSettingReadUpdate {
    /**
     *
     * @type {string}
     * @memberof SettingSettingReadUpdate
     */
    id?: string | null;
    /**
     * この設定の持ち主のユーザーID 管理者は個別には保持せずに全員で１つの グローバル設定(ownerId==NilUlid)を使う
     * @type {string}
     * @memberof SettingSettingReadUpdate
     */
    ownerId?: string;
    /**
     * 設定項目 変更した項目のみを保持する
     * @type {Array<string>}
     * @memberof SettingSettingReadUpdate
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface SettingSettingWriteUpdate
 */
export interface SettingSettingWriteUpdate {
    /**
     * 設定項目 変更した項目のみを保持する
     * @type {Array<string>}
     * @memberof SettingSettingWriteUpdate
     */
    items?: Array<string>;
}
/**
 *
 * @export
 * @interface Token
 */
export interface Token {
    /**
     *
     * @type {string}
     * @memberof Token
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof Token
     */
    refresh_token?: string;
}
/**
 *
 * @export
 * @interface UserGroupRead
 */
export interface UserGroupRead {
    /**
     *
     * @type {string}
     * @memberof UserGroupRead
     */
    id?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserGroupRead
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserGroupRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface UserGroupReadAvailables
 */
export interface UserGroupReadAvailables {
    /**
     *
     * @type {string}
     * @memberof UserGroupReadAvailables
     */
    id?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserGroupReadAvailables
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserGroupReadAvailables
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface UserGroupReadUpdate
 */
export interface UserGroupReadUpdate {
    /**
     *
     * @type {string}
     * @memberof UserGroupReadUpdate
     */
    id?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserGroupReadUpdate
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserGroupReadUpdate
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface UserJsonldGroupRead
 */
export interface UserJsonldGroupRead {
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupRead
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldGroupRead
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldGroupRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface UserJsonldGroupReadAvailables
 */
export interface UserJsonldGroupReadAvailables {
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupReadAvailables
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupReadAvailables
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupReadAvailables
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldGroupReadAvailables
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldGroupReadAvailables
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface UserJsonldGroupReadUpdate
 */
export interface UserJsonldGroupReadUpdate {
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupReadUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupReadUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldGroupReadUpdate
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldGroupReadUpdate
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldGroupReadUpdate
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface UserJsonldPairRead
 */
export interface UserJsonldPairRead {
    /**
     *
     * @type {string}
     * @memberof UserJsonldPairRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldPairRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldPairRead
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldPairRead
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldPairRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface UserJsonldUserRead
 */
export interface UserJsonldUserRead {
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserRead
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserRead
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserRead
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldUserRead
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldUserRead
     */
    name?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserJsonldUserRead
     */
    verified?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserRead
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserJsonldUserRead
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserJsonldUserReadExtra
 */
export interface UserJsonldUserReadExtra {
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadExtra
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadExtra
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldUserReadExtra
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldUserReadExtra
     */
    name?: string | null;
    /**
     * 携帯電話番号 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserJsonldUserReadExtra
     */
    phone?: string | null;
    /**
     * 住所 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserJsonldUserReadExtra
     */
    address?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserJsonldUserReadExtra
     */
    verified?: boolean;
    /**
     * ペア設定済み機器一覧(Uri配列) これに入るのはPairオブジェクトを参照するためのUri 例：/api/v1/pairs/ペアID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserJsonldUserReadExtra
     */
    pairs?: Array<string>;
    /**
     * ユーザーが自分で作ったグループ設定一覧(Uri配列) これに入るのはGroupオブジェクトを参照するためのUri 例：/api/v1/pairs/グループID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserJsonldUserReadExtra
     */
    groups?: Array<string>;
    /**
     * ユーザーが利用可能なサービス
     * @type {Array<string>}
     * @memberof UserJsonldUserReadExtra
     */
    services?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadExtra
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserJsonldUserReadExtra
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserJsonldUserReadReset
 */
export interface UserJsonldUserReadReset {
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadReset
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadReset
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadReset
     */
    type?: string;
}
/**
 *
 * @export
 * @interface UserJsonldUserReadUpdate
 */
export interface UserJsonldUserReadUpdate {
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadUpdate
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldUserReadUpdate
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldUserReadUpdate
     */
    name?: string | null;
    /**
     * 携帯電話番号 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserJsonldUserReadUpdate
     */
    phone?: string | null;
    /**
     * 住所 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserJsonldUserReadUpdate
     */
    address?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserJsonldUserReadUpdate
     */
    verified?: boolean;
    /**
     * ペア設定済み機器一覧(Uri配列) これに入るのはPairオブジェクトを参照するためのUri 例：/api/v1/pairs/ペアID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserJsonldUserReadUpdate
     */
    pairs?: Array<string>;
    /**
     * ユーザーが自分で作ったグループ設定一覧(Uri配列) これに入るのはGroupオブジェクトを参照するためのUri 例：/api/v1/pairs/グループID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserJsonldUserReadUpdate
     */
    groups?: Array<string>;
    /**
     * ユーザーが利用可能なサービス
     * @type {Array<string>}
     * @memberof UserJsonldUserReadUpdate
     */
    services?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadUpdate
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserJsonldUserReadUpdate
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserJsonldUserReadVerify
 */
export interface UserJsonldUserReadVerify {
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadVerify
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadVerify
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserReadVerify
     */
    type?: string;
}
/**
 *
 * @export
 * @interface UserJsonldUserWrite
 */
export interface UserJsonldUserWrite {
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserWrite
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserWrite
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserWrite
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldUserWrite
     */
    email?: string;
    /**
     * ハッシュしていない平文のパスワード これはデーターベースへ保存しない
     * @type {string}
     * @memberof UserJsonldUserWrite
     */
    plainPassword?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldUserWrite
     */
    name?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserJsonldUserWrite
     */
    verified?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserWrite
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserJsonldUserWrite
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserJsonldUserWriteUpdate
 */
export interface UserJsonldUserWriteUpdate {
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    context?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    type?: string;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    email?: string;
    /**
     * ハッシュしていない平文のパスワード これはデーターベースへ保存しない
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    plainPassword?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    name?: string | null;
    /**
     * 携帯電話番号 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    phone?: string | null;
    /**
     * 住所 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    address?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserJsonldUserWriteUpdate
     */
    verified?: boolean;
    /**
     * ペア設定済み機器一覧(Uri配列) これに入るのはPairオブジェクトを参照するためのUri 例：/api/v1/pairs/ペアID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserJsonldUserWriteUpdate
     */
    pairs?: Array<string>;
    /**
     * ユーザーが自分で作ったグループ設定一覧(Uri配列) これに入るのはGroupオブジェクトを参照するためのUri 例：/api/v1/pairs/グループID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserJsonldUserWriteUpdate
     */
    groups?: Array<string>;
    /**
     * ユーザーが利用可能なサービス
     * @type {Array<string>}
     * @memberof UserJsonldUserWriteUpdate
     */
    services?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserWriteUpdate
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserJsonldUserWriteUpdate
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserPairRead
 */
export interface UserPairRead {
    /**
     *
     * @type {string}
     * @memberof UserPairRead
     */
    id?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserPairRead
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserPairRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface UserUserRead
 */
export interface UserUserRead {
    /**
     *
     * @type {string}
     * @memberof UserUserRead
     */
    id?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserUserRead
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserUserRead
     */
    name?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserUserRead
     */
    verified?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserUserRead
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserUserRead
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserUserReadExtra
 */
export interface UserUserReadExtra {
    /**
     *
     * @type {string}
     * @memberof UserUserReadExtra
     */
    id?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserUserReadExtra
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserUserReadExtra
     */
    name?: string | null;
    /**
     * 携帯電話番号 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserUserReadExtra
     */
    phone?: string | null;
    /**
     * 住所 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserUserReadExtra
     */
    address?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserUserReadExtra
     */
    verified?: boolean;
    /**
     * ペア設定済み機器一覧(Uri配列) これに入るのはPairオブジェクトを参照するためのUri 例：/api/v1/pairs/ペアID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserUserReadExtra
     */
    pairs?: Array<string>;
    /**
     * ユーザーが自分で作ったグループ設定一覧(Uri配列) これに入るのはGroupオブジェクトを参照するためのUri 例：/api/v1/pairs/グループID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserUserReadExtra
     */
    groups?: Array<string>;
    /**
     * ユーザーが利用可能なサービス
     * @type {Array<string>}
     * @memberof UserUserReadExtra
     */
    services?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UserUserReadExtra
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserUserReadExtra
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserUserReadUpdate
 */
export interface UserUserReadUpdate {
    /**
     *
     * @type {string}
     * @memberof UserUserReadUpdate
     */
    id?: string | null;
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserUserReadUpdate
     */
    email?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserUserReadUpdate
     */
    name?: string | null;
    /**
     * 携帯電話番号 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserUserReadUpdate
     */
    phone?: string | null;
    /**
     * 住所 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserUserReadUpdate
     */
    address?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserUserReadUpdate
     */
    verified?: boolean;
    /**
     * ペア設定済み機器一覧(Uri配列) これに入るのはPairオブジェクトを参照するためのUri 例：/api/v1/pairs/ペアID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserUserReadUpdate
     */
    pairs?: Array<string>;
    /**
     * ユーザーが自分で作ったグループ設定一覧(Uri配列) これに入るのはGroupオブジェクトを参照するためのUri 例：/api/v1/pairs/グループID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserUserReadUpdate
     */
    groups?: Array<string>;
    /**
     * ユーザーが利用可能なサービス
     * @type {Array<string>}
     * @memberof UserUserReadUpdate
     */
    services?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UserUserReadUpdate
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserUserReadUpdate
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserUserWrite
 */
export interface UserUserWrite {
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserUserWrite
     */
    email?: string;
    /**
     * ハッシュしていない平文のパスワード これはデーターベースへ保存しない
     * @type {string}
     * @memberof UserUserWrite
     */
    plainPassword?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserUserWrite
     */
    name?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserUserWrite
     */
    verified?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserUserWrite
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserUserWrite
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface UserUserWriteUpdate
 */
export interface UserUserWriteUpdate {
    /**
     * attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {string}
     * @memberof UserUserWriteUpdate
     */
    email?: string;
    /**
     * ハッシュしていない平文のパスワード これはデーターベースへ保存しない
     * @type {string}
     * @memberof UserUserWriteUpdate
     */
    plainPassword?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserUserWriteUpdate
     */
    name?: string | null;
    /**
     * 携帯電話番号 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserUserWriteUpdate
     */
    phone?: string | null;
    /**
     * 住所 attributes={\"security\"=\"is_granted(\'ROLE_ADMIN_PRIVILEGED\') or object.getId() == user.getId()\"},
     * @type {string}
     * @memberof UserUserWriteUpdate
     */
    address?: string | null;
    /**
     * メールアドレスを確認済みかどうか attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {boolean}
     * @memberof UserUserWriteUpdate
     */
    verified?: boolean;
    /**
     * ペア設定済み機器一覧(Uri配列) これに入るのはPairオブジェクトを参照するためのUri 例：/api/v1/pairs/ペアID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserUserWriteUpdate
     */
    pairs?: Array<string>;
    /**
     * ユーザーが自分で作ったグループ設定一覧(Uri配列) これに入るのはGroupオブジェクトを参照するためのUri 例：/api/v1/pairs/グループID attributes={\"security\"=\"is_granted(\'ROLE_USER\')\"},
     * @type {Array<string>}
     * @memberof UserUserWriteUpdate
     */
    groups?: Array<string>;
    /**
     * ユーザーが利用可能なサービス
     * @type {Array<string>}
     * @memberof UserUserWriteUpdate
     */
    services?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UserUserWriteUpdate
     */
    customer?: string | null;
    /**
     * マネジャーユーザーかどうか(rolesにROLE_MANAGERを含んでいるかどうか)
     * @type {boolean}
     * @memberof UserUserWriteUpdate
     */
    isManager?: boolean;
}
/**
 *
 * @export
 * @interface Version
 */
export interface Version {
    /**
     *
     * @type {string}
     * @memberof Version
     */
    version?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Admin resource.
         * @summary Removes the Admin resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminItem', 'id', id)
            const localVarPath = `/api/v1/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Admin resources.
         * @summary Retrieves the collection of Admin resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCollection: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Admin resource.
         * @summary Retrieves a Admin resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminItem', 'id', id)
            const localVarPath = `/api/v1/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Admin resources.
         * @summary Retrieves the collection of Admin resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAdminAdminCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admins/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Admin resource.
         * @summary Updates the Admin resource.
         * @param {string} id Resource identifier
         * @param {AdminAdminWriteUpdate} adminAdminWriteUpdate The updated Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdminItem: async (id: string, adminAdminWriteUpdate: AdminAdminWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchAdminItem', 'id', id)
            // verify required parameter 'adminAdminWriteUpdate' is not null or undefined
            assertParamExists('patchAdminItem', 'adminAdminWriteUpdate', adminAdminWriteUpdate)
            const localVarPath = `/api/v1/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdminWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {AdminJsonldAdminWrite} adminJsonldAdminWrite The new Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminCollection: async (adminJsonldAdminWrite: AdminJsonldAdminWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminJsonldAdminWrite' is not null or undefined
            assertParamExists('postAdminCollection', 'adminJsonldAdminWrite', adminJsonldAdminWrite)
            const localVarPath = `/api/v1/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminJsonldAdminWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Admin resource.
         * @summary Replaces the Admin resource.
         * @param {string} id Resource identifier
         * @param {AdminJsonldAdminWriteUpdate} adminJsonldAdminWriteUpdate The updated Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminItem: async (id: string, adminJsonldAdminWriteUpdate: AdminJsonldAdminWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminItem', 'id', id)
            // verify required parameter 'adminJsonldAdminWriteUpdate' is not null or undefined
            assertParamExists('putAdminItem', 'adminJsonldAdminWriteUpdate', adminJsonldAdminWriteUpdate)
            const localVarPath = `/api/v1/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminJsonldAdminWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAdminAdminCollection: async (inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admins/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAdminAdminCollection: async (inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admins/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Admin resource.
         * @summary Removes the Admin resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Admin resources.
         * @summary Retrieves the collection of Admin resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminCollection(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminCollection(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Admin resource.
         * @summary Retrieves a Admin resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminJsonldAdminRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Admin resources.
         * @summary Retrieves the collection of Admin resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentAdminAdminCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentAdminAdminCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Admin resource.
         * @summary Updates the Admin resource.
         * @param {string} id Resource identifier
         * @param {AdminAdminWriteUpdate} adminAdminWriteUpdate The updated Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAdminItem(id: string, adminAdminWriteUpdate: AdminAdminWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminJsonldAdminReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAdminItem(id, adminAdminWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {AdminJsonldAdminWrite} adminJsonldAdminWrite The new Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminCollection(adminJsonldAdminWrite: AdminJsonldAdminWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminJsonldAdminRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminCollection(adminJsonldAdminWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Admin resource.
         * @summary Replaces the Admin resource.
         * @param {string} id Resource identifier
         * @param {AdminJsonldAdminWriteUpdate} adminJsonldAdminWriteUpdate The updated Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminItem(id: string, adminJsonldAdminWriteUpdate: AdminJsonldAdminWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminJsonldAdminReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminItem(id, adminJsonldAdminWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAdminAdminCollection(inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminJsonldAdminReadReset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetAdminAdminCollection(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAdminAdminCollection(inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminJsonldAdminReadVerify>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyAdminAdminCollection(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Removes the Admin resource.
         * @summary Removes the Admin resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Admin resources.
         * @summary Retrieves the collection of Admin resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCollection(page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.getAdminCollection(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Admin resource.
         * @summary Retrieves a Admin resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminItem(id: string, options?: any): AxiosPromise<AdminJsonldAdminRead> {
            return localVarFp.getAdminItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Admin resources.
         * @summary Retrieves the collection of Admin resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAdminAdminCollection(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.getCurrentAdminAdminCollection(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Admin resource.
         * @summary Updates the Admin resource.
         * @param {string} id Resource identifier
         * @param {AdminAdminWriteUpdate} adminAdminWriteUpdate The updated Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdminItem(id: string, adminAdminWriteUpdate: AdminAdminWriteUpdate, options?: any): AxiosPromise<AdminJsonldAdminReadUpdate> {
            return localVarFp.patchAdminItem(id, adminAdminWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {AdminJsonldAdminWrite} adminJsonldAdminWrite The new Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminCollection(adminJsonldAdminWrite: AdminJsonldAdminWrite, options?: any): AxiosPromise<AdminJsonldAdminRead> {
            return localVarFp.postAdminCollection(adminJsonldAdminWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Admin resource.
         * @summary Replaces the Admin resource.
         * @param {string} id Resource identifier
         * @param {AdminJsonldAdminWriteUpdate} adminJsonldAdminWriteUpdate The updated Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminItem(id: string, adminJsonldAdminWriteUpdate: AdminJsonldAdminWriteUpdate, options?: any): AxiosPromise<AdminJsonldAdminReadUpdate> {
            return localVarFp.putAdminItem(id, adminJsonldAdminWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAdminAdminCollection(inlineObject?: InlineObject, options?: any): AxiosPromise<AdminJsonldAdminReadReset> {
            return localVarFp.resetAdminAdminCollection(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Admin resource.
         * @summary Creates a Admin resource.
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAdminAdminCollection(inlineObject1?: InlineObject1, options?: any): AxiosPromise<AdminJsonldAdminReadVerify> {
            return localVarFp.verifyAdminAdminCollection(inlineObject1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Removes the Admin resource.
     * @summary Removes the Admin resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminItem(id: string, options?: any) {
        return AdminApiFp(this.configuration).deleteAdminItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Admin resources.
     * @summary Retrieves the collection of Admin resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminCollection(page?: number, pageSize?: number, options?: any) {
        return AdminApiFp(this.configuration).getAdminCollection(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Admin resource.
     * @summary Retrieves a Admin resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminItem(id: string, options?: any) {
        return AdminApiFp(this.configuration).getAdminItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Admin resources.
     * @summary Retrieves the collection of Admin resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCurrentAdminAdminCollection(options?: any) {
        return AdminApiFp(this.configuration).getCurrentAdminAdminCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Admin resource.
     * @summary Updates the Admin resource.
     * @param {string} id Resource identifier
     * @param {AdminAdminWriteUpdate} adminAdminWriteUpdate The updated Admin resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public patchAdminItem(id: string, adminAdminWriteUpdate: AdminAdminWriteUpdate, options?: any) {
        return AdminApiFp(this.configuration).patchAdminItem(id, adminAdminWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Admin resource.
     * @summary Creates a Admin resource.
     * @param {AdminJsonldAdminWrite} adminJsonldAdminWrite The new Admin resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminCollection(adminJsonldAdminWrite: AdminJsonldAdminWrite, options?: any) {
        return AdminApiFp(this.configuration).postAdminCollection(adminJsonldAdminWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Admin resource.
     * @summary Replaces the Admin resource.
     * @param {string} id Resource identifier
     * @param {AdminJsonldAdminWriteUpdate} adminJsonldAdminWriteUpdate The updated Admin resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminItem(id: string, adminJsonldAdminWriteUpdate: AdminJsonldAdminWriteUpdate, options?: any) {
        return AdminApiFp(this.configuration).putAdminItem(id, adminJsonldAdminWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Admin resource.
     * @summary Creates a Admin resource.
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public resetAdminAdminCollection(inlineObject?: InlineObject, options?: any) {
        return AdminApiFp(this.configuration).resetAdminAdminCollection(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Admin resource.
     * @summary Creates a Admin resource.
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public verifyAdminAdminCollection(inlineObject1?: InlineObject1, options?: any) {
        return AdminApiFp(this.configuration).verifyAdminAdminCollection(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticateApi - axios parameter creator
 * @export
 */
export const AuthenticateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a Authenticate resource.
         * @summary Creates a Authenticate resource.
         * @param {AuthenticateJsonldPeerWrite} authenticateJsonldPeerWrite The new Authenticate resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthenticateAuthenticateCollection: async (authenticateJsonldPeerWrite: AuthenticateJsonldPeerWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticateJsonldPeerWrite' is not null or undefined
            assertParamExists('postAuthenticateAuthenticateCollection', 'authenticateJsonldPeerWrite', authenticateJsonldPeerWrite)
            const localVarPath = `/api/v1/authenticates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticateJsonldPeerWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticateApi - functional programming interface
 * @export
 */
export const AuthenticateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticateApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a Authenticate resource.
         * @summary Creates a Authenticate resource.
         * @param {AuthenticateJsonldPeerWrite} authenticateJsonldPeerWrite The new Authenticate resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthenticateAuthenticateCollection(authenticateJsonldPeerWrite: AuthenticateJsonldPeerWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticateJsonldPeerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthenticateAuthenticateCollection(authenticateJsonldPeerWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticateApi - factory interface
 * @export
 */
export const AuthenticateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticateApiFp(configuration)
    return {
        /**
         * Creates a Authenticate resource.
         * @summary Creates a Authenticate resource.
         * @param {AuthenticateJsonldPeerWrite} authenticateJsonldPeerWrite The new Authenticate resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthenticateAuthenticateCollection(authenticateJsonldPeerWrite: AuthenticateJsonldPeerWrite, options?: any): AxiosPromise<AuthenticateJsonldPeerRead> {
            return localVarFp.postAuthenticateAuthenticateCollection(authenticateJsonldPeerWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticateApi - object-oriented interface
 * @export
 * @class AuthenticateApi
 * @extends {BaseAPI}
 */
export class AuthenticateApi extends BaseAPI {
    /**
     * Creates a Authenticate resource.
     * @summary Creates a Authenticate resource.
     * @param {AuthenticateJsonldPeerWrite} authenticateJsonldPeerWrite The new Authenticate resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticateApi
     */
    public postAuthenticateAuthenticateCollection(authenticateJsonldPeerWrite: AuthenticateJsonldPeerWrite, options?: any) {
        return AuthenticateApiFp(this.configuration).postAuthenticateAuthenticateCollection(authenticateJsonldPeerWrite, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectionApi - axios parameter creator
 * @export
 */
export const ConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Connection resources.
         * @summary Retrieves the collection of Connection resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionCollection: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Connection resource.
         * @summary Retrieves a Connection resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConnectionItem', 'id', id)
            const localVarPath = `/api/v1/connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Connection resource.
         * @summary Creates a Connection resource.
         * @param {ConnectionJsonldConnectionWrite} connectionJsonldConnectionWrite The new Connection resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDurationConnectionCollection: async (connectionJsonldConnectionWrite: ConnectionJsonldConnectionWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionJsonldConnectionWrite' is not null or undefined
            assertParamExists('postDurationConnectionCollection', 'connectionJsonldConnectionWrite', connectionJsonldConnectionWrite)
            const localVarPath = `/api/v1/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionJsonldConnectionWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionApi - functional programming interface
 * @export
 */
export const ConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of Connection resources.
         * @summary Retrieves the collection of Connection resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionCollection(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionCollection(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Connection resource.
         * @summary Retrieves a Connection resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionJsonldConnectionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Connection resource.
         * @summary Creates a Connection resource.
         * @param {ConnectionJsonldConnectionWrite} connectionJsonldConnectionWrite The new Connection resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDurationConnectionCollection(connectionJsonldConnectionWrite: ConnectionJsonldConnectionWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionJsonldConnectionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDurationConnectionCollection(connectionJsonldConnectionWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectionApi - factory interface
 * @export
 */
export const ConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionApiFp(configuration)
    return {
        /**
         * Retrieves the collection of Connection resources.
         * @summary Retrieves the collection of Connection resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionCollection(page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.getConnectionCollection(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Connection resource.
         * @summary Retrieves a Connection resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionItem(id: string, options?: any): AxiosPromise<ConnectionJsonldConnectionRead> {
            return localVarFp.getConnectionItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Connection resource.
         * @summary Creates a Connection resource.
         * @param {ConnectionJsonldConnectionWrite} connectionJsonldConnectionWrite The new Connection resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDurationConnectionCollection(connectionJsonldConnectionWrite: ConnectionJsonldConnectionWrite, options?: any): AxiosPromise<ConnectionJsonldConnectionRead> {
            return localVarFp.postDurationConnectionCollection(connectionJsonldConnectionWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectionApi - object-oriented interface
 * @export
 * @class ConnectionApi
 * @extends {BaseAPI}
 */
export class ConnectionApi extends BaseAPI {
    /**
     * Retrieves the collection of Connection resources.
     * @summary Retrieves the collection of Connection resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public getConnectionCollection(page?: number, pageSize?: number, options?: any) {
        return ConnectionApiFp(this.configuration).getConnectionCollection(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Connection resource.
     * @summary Retrieves a Connection resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public getConnectionItem(id: string, options?: any) {
        return ConnectionApiFp(this.configuration).getConnectionItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Connection resource.
     * @summary Creates a Connection resource.
     * @param {ConnectionJsonldConnectionWrite} connectionJsonldConnectionWrite The new Connection resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public postDurationConnectionCollection(connectionJsonldConnectionWrite: ConnectionJsonldConnectionWrite, options?: any) {
        return ConnectionApiFp(this.configuration).postDurationConnectionCollection(connectionJsonldConnectionWrite, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectionSummaryApi - axios parameter creator
 * @export
 */
export const ConnectionSummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByDayConnectionSummaryCollection: async (lastXDays?: any, customer?: any, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connection_summaries/byday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lastXDays !== undefined) {
                localVarQueryParameter['lastXDays'] = lastXDays;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByHourConnectionSummaryCollection: async (lastXDays?: any, customer?: any, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connection_summaries/byhour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lastXDays !== undefined) {
                localVarQueryParameter['lastXDays'] = lastXDays;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXMonths]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByMonthConnectionSummaryCollection: async (lastXMonths?: any, customer?: any, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connection_summaries/bymonth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lastXMonths !== undefined) {
                localVarQueryParameter['lastXMonths'] = lastXMonths;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByPeerIdConnectionSummaryCollection: async (lastXDays?: any, customer?: any, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connection_summaries/bypeerid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lastXDays !== undefined) {
                localVarQueryParameter['lastXDays'] = lastXDays;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByWeekConnectionSummaryCollection: async (lastXDays?: any, customer?: any, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connection_summaries/byweek`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lastXDays !== undefined) {
                localVarQueryParameter['lastXDays'] = lastXDays;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ConnectionSummary resource.
         * @summary Retrieves a ConnectionSummary resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionSummaryItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConnectionSummaryItem', 'id', id)
            const localVarPath = `/api/v1/connection_summaries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionSummaryApi - functional programming interface
 * @export
 */
export const ConnectionSummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionSummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countByDayConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countByDayConnectionSummaryCollection(lastXDays, customer, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countByHourConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countByHourConnectionSummaryCollection(lastXDays, customer, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXMonth]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countByMonthConnectionSummaryCollection(lastXMonth?: any, customer?: any, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countByMonthConnectionSummaryCollection(lastXMonth, customer, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countByPeerIdConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countByPeerIdConnectionSummaryCollection(lastXDays, customer, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countByWeekConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countByWeekConnectionSummaryCollection(lastXDays, customer, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a ConnectionSummary resource.
         * @summary Retrieves a ConnectionSummary resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionSummaryItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionSummaryJsonldSummaryRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionSummaryItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectionSummaryApi - factory interface
 * @export
 */
export const ConnectionSummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionSummaryApiFp(configuration)
    return {
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByDayConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.countByDayConnectionSummaryCollection(lastXDays, customer, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByHourConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.countByHourConnectionSummaryCollection(lastXDays, customer, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXMonth]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByMonthConnectionSummaryCollection(lastXMonth?: any, customer?: any, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.countByMonthConnectionSummaryCollection(lastXMonth, customer, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByPeerIdConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.countByPeerIdConnectionSummaryCollection(lastXDays, customer, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of ConnectionSummary resources.
         * @summary Retrieves the collection of ConnectionSummary resources.
         * @param {} [lastXDays]
         * @param {} [customer]
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countByWeekConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.countByWeekConnectionSummaryCollection(lastXDays, customer, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ConnectionSummary resource.
         * @summary Retrieves a ConnectionSummary resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionSummaryItem(id: string, options?: any): AxiosPromise<ConnectionSummaryJsonldSummaryRead> {
            return localVarFp.getConnectionSummaryItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectionSummaryApi - object-oriented interface
 * @export
 * @class ConnectionSummaryApi
 * @extends {BaseAPI}
 */
export class ConnectionSummaryApi extends BaseAPI {
    /**
     * Retrieves the collection of ConnectionSummary resources.
     * @summary Retrieves the collection of ConnectionSummary resources.
     * @param {} [lastXDays]
     * @param {} [customer]
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionSummaryApi
     */
    public countByDayConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any) {
        return ConnectionSummaryApiFp(this.configuration).countByDayConnectionSummaryCollection(lastXDays, customer, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of ConnectionSummary resources.
     * @summary Retrieves the collection of ConnectionSummary resources.
     * @param {} [lastXDays]
     * @param {} [customer]
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionSummaryApi
     */
    public countByHourConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any) {
        return ConnectionSummaryApiFp(this.configuration).countByHourConnectionSummaryCollection(lastXDays, customer, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of ConnectionSummary resources.
     * @summary Retrieves the collection of ConnectionSummary resources.
     * @param {} [lastXMonth]
     * @param {} [customer]
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionSummaryApi
     */
    public countByMonthConnectionSummaryCollection(lastXMonth?: any, customer?: any, page?: number, pageSize?: number, options?: any) {
        return ConnectionSummaryApiFp(this.configuration).countByMonthConnectionSummaryCollection(lastXMonth, customer, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of ConnectionSummary resources.
     * @summary Retrieves the collection of ConnectionSummary resources.
     * @param {} [lastXDays]
     * @param {} [customer]
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionSummaryApi
     */
    public countByPeerIdConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any) {
        return ConnectionSummaryApiFp(this.configuration).countByPeerIdConnectionSummaryCollection(lastXDays, customer, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of ConnectionSummary resources.
     * @summary Retrieves the collection of ConnectionSummary resources.
     * @param {} [lastXDays]
     * @param {} [customer]
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionSummaryApi
     */
    public countByWeekConnectionSummaryCollection(lastXDays?: any, customer?: any, page?: number, pageSize?: number, options?: any) {
        return ConnectionSummaryApiFp(this.configuration).countByWeekConnectionSummaryCollection(lastXDays, customer, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ConnectionSummary resource.
     * @summary Retrieves a ConnectionSummary resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionSummaryApi
     */
    public getConnectionSummaryItem(id: string, options?: any) {
        return ConnectionSummaryApiFp(this.configuration).getConnectionSummaryItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Customer resource.
         * @summary Removes the Customer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomerItem', 'id', id)
            const localVarPath = `/api/v1/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Drops manager roles from the user.
         * @param {string} customerId the customer identifier
         * @param {string} userId the user identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerManager: async (customerId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCustomerManager', 'customerId', customerId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteCustomerManager', 'userId', userId)
            const localVarPath = `/api/v1/customers/{customer_id}/manager/{user_id}`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Customer resources.
         * @summary Retrieves the collection of Customer resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCustomerCustomerCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/customers/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Customer resources.
         * @summary Retrieves the collection of Customer resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerCollection: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Customer resource.
         * @summary Retrieves a Customer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerItem', 'id', id)
            const localVarPath = `/api/v1/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Customer resource.
         * @summary Updates the Customer resource.
         * @param {string} id Resource identifier
         * @param {CustomerCustomerWriteUpdate} customerCustomerWriteUpdate The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCustomerItem: async (id: string, customerCustomerWriteUpdate: CustomerCustomerWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCustomerItem', 'id', id)
            // verify required parameter 'customerCustomerWriteUpdate' is not null or undefined
            assertParamExists('patchCustomerItem', 'customerCustomerWriteUpdate', customerCustomerWriteUpdate)
            const localVarPath = `/api/v1/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerCustomerWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Customer resource.
         * @summary Creates a Customer resource.
         * @param {CustomerJsonldCustomerWriteCreate} customerJsonldCustomerWriteCreate The new Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerCollection: async (customerJsonldCustomerWriteCreate: CustomerJsonldCustomerWriteCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerJsonldCustomerWriteCreate' is not null or undefined
            assertParamExists('postCustomerCollection', 'customerJsonldCustomerWriteCreate', customerJsonldCustomerWriteCreate)
            const localVarPath = `/api/v1/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerJsonldCustomerWriteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create manager user for the customer
         * @param {string} customerId the customer identifier
         * @param {UserJsonldUserWrite} [userJsonldUserWrite] Create manager user for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerManager: async (customerId: string, userJsonldUserWrite?: UserJsonldUserWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('postCustomerManager', 'customerId', customerId)
            const localVarPath = `/api/v1/customers/{customer_id}/manager`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userJsonldUserWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Customer resource.
         * @summary Replaces the Customer resource.
         * @param {string} id Resource identifier
         * @param {CustomerJsonldCustomerWriteUpdate} customerJsonldCustomerWriteUpdate The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerItem: async (id: string, customerJsonldCustomerWriteUpdate: CustomerJsonldCustomerWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCustomerItem', 'id', id)
            // verify required parameter 'customerJsonldCustomerWriteUpdate' is not null or undefined
            assertParamExists('putCustomerItem', 'customerJsonldCustomerWriteUpdate', customerJsonldCustomerWriteUpdate)
            const localVarPath = `/api/v1/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerJsonldCustomerWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Customer resource.
         * @summary Removes the Customer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Drops manager roles from the user.
         * @param {string} customerId the customer identifier
         * @param {string} userId the user identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerManager(customerId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerManager(customerId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Customer resources.
         * @summary Retrieves the collection of Customer resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentCustomerCustomerCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentCustomerCustomerCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Customer resources.
         * @summary Retrieves the collection of Customer resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerCollection(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerCollection(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Customer resource.
         * @summary Retrieves a Customer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerJsonldCustomerReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Customer resource.
         * @summary Updates the Customer resource.
         * @param {string} id Resource identifier
         * @param {CustomerCustomerWriteUpdate} customerCustomerWriteUpdate The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCustomerItem(id: string, customerCustomerWriteUpdate: CustomerCustomerWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerJsonldCustomerReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCustomerItem(id, customerCustomerWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Customer resource.
         * @summary Creates a Customer resource.
         * @param {CustomerJsonldCustomerWriteCreate} customerJsonldCustomerWriteCreate The new Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomerCollection(customerJsonldCustomerWriteCreate: CustomerJsonldCustomerWriteCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerJsonldCustomerReadCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomerCollection(customerJsonldCustomerWriteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Create manager user for the customer
         * @param {string} customerId the customer identifier
         * @param {UserJsonldUserWrite} [userJsonldUserWrite] Create manager user for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomerManager(customerId: string, userJsonldUserWrite?: UserJsonldUserWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomerManager(customerId, userJsonldUserWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Customer resource.
         * @summary Replaces the Customer resource.
         * @param {string} id Resource identifier
         * @param {CustomerJsonldCustomerWriteUpdate} customerJsonldCustomerWriteUpdate The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerItem(id: string, customerJsonldCustomerWriteUpdate: CustomerJsonldCustomerWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerJsonldCustomerReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerItem(id, customerJsonldCustomerWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * Removes the Customer resource.
         * @summary Removes the Customer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Drops manager roles from the user.
         * @param {string} customerId the customer identifier
         * @param {string} userId the user identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerManager(customerId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomerManager(customerId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Customer resources.
         * @summary Retrieves the collection of Customer resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCustomerCustomerCollection(options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.getCurrentCustomerCustomerCollection(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Customer resources.
         * @summary Retrieves the collection of Customer resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerCollection(page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.getCustomerCollection(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Customer resource.
         * @summary Retrieves a Customer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerItem(id: string, options?: any): AxiosPromise<CustomerJsonldCustomerReadUpdate> {
            return localVarFp.getCustomerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Customer resource.
         * @summary Updates the Customer resource.
         * @param {string} id Resource identifier
         * @param {CustomerCustomerWriteUpdate} customerCustomerWriteUpdate The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCustomerItem(id: string, customerCustomerWriteUpdate: CustomerCustomerWriteUpdate, options?: any): AxiosPromise<CustomerJsonldCustomerReadUpdate> {
            return localVarFp.patchCustomerItem(id, customerCustomerWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Customer resource.
         * @summary Creates a Customer resource.
         * @param {CustomerJsonldCustomerWriteCreate} customerJsonldCustomerWriteCreate The new Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerCollection(customerJsonldCustomerWriteCreate: CustomerJsonldCustomerWriteCreate, options?: any): AxiosPromise<CustomerJsonldCustomerReadCreate> {
            return localVarFp.postCustomerCollection(customerJsonldCustomerWriteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create manager user for the customer
         * @param {string} customerId the customer identifier
         * @param {UserJsonldUserWrite} [userJsonldUserWrite] Create manager user for the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerManager(customerId: string, userJsonldUserWrite?: UserJsonldUserWrite, options?: any): AxiosPromise<UserJsonldUserRead> {
            return localVarFp.postCustomerManager(customerId, userJsonldUserWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Customer resource.
         * @summary Replaces the Customer resource.
         * @param {string} id Resource identifier
         * @param {CustomerJsonldCustomerWriteUpdate} customerJsonldCustomerWriteUpdate The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerItem(id: string, customerJsonldCustomerWriteUpdate: CustomerJsonldCustomerWriteUpdate, options?: any): AxiosPromise<CustomerJsonldCustomerReadUpdate> {
            return localVarFp.putCustomerItem(id, customerJsonldCustomerWriteUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * Removes the Customer resource.
     * @summary Removes the Customer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCustomerItem(id: string, options?: any) {
        return CustomerApiFp(this.configuration).deleteCustomerItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Drops manager roles from the user.
     * @param {string} customerId the customer identifier
     * @param {string} userId the user identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCustomerManager(customerId: string, userId: string, options?: any) {
        return CustomerApiFp(this.configuration).deleteCustomerManager(customerId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Customer resources.
     * @summary Retrieves the collection of Customer resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCurrentCustomerCustomerCollection(options?: any) {
        return CustomerApiFp(this.configuration).getCurrentCustomerCustomerCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Customer resources.
     * @summary Retrieves the collection of Customer resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerCollection(page?: number, pageSize?: number, options?: any) {
        return CustomerApiFp(this.configuration).getCustomerCollection(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Customer resource.
     * @summary Retrieves a Customer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerItem(id: string, options?: any) {
        return CustomerApiFp(this.configuration).getCustomerItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Customer resource.
     * @summary Updates the Customer resource.
     * @param {string} id Resource identifier
     * @param {CustomerCustomerWriteUpdate} customerCustomerWriteUpdate The updated Customer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public patchCustomerItem(id: string, customerCustomerWriteUpdate: CustomerCustomerWriteUpdate, options?: any) {
        return CustomerApiFp(this.configuration).patchCustomerItem(id, customerCustomerWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Customer resource.
     * @summary Creates a Customer resource.
     * @param {CustomerJsonldCustomerWriteCreate} customerJsonldCustomerWriteCreate The new Customer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCustomerCollection(customerJsonldCustomerWriteCreate: CustomerJsonldCustomerWriteCreate, options?: any) {
        return CustomerApiFp(this.configuration).postCustomerCollection(customerJsonldCustomerWriteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Create manager user for the customer
     * @param {string} customerId the customer identifier
     * @param {UserJsonldUserWrite} [userJsonldUserWrite] Create manager user for the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCustomerManager(customerId: string, userJsonldUserWrite?: UserJsonldUserWrite, options?: any) {
        return CustomerApiFp(this.configuration).postCustomerManager(customerId, userJsonldUserWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Customer resource.
     * @summary Replaces the Customer resource.
     * @param {string} id Resource identifier
     * @param {CustomerJsonldCustomerWriteUpdate} customerJsonldCustomerWriteUpdate The updated Customer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putCustomerItem(id: string, customerJsonldCustomerWriteUpdate: CustomerJsonldCustomerWriteUpdate, options?: any) {
        return CustomerApiFp(this.configuration).putCustomerItem(id, customerJsonldCustomerWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Device resource.
         * @summary Removes the Device resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeviceItem', 'id', id)
            const localVarPath = `/api/v1/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Device resources.
         * @summary Retrieves the collection of Device resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [customer]
         * @param {string} [registeredBefore]
         * @param {string} [registeredStrictlyBefore]
         * @param {string} [registeredAfter]
         * @param {string} [registeredStrictlyAfter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceCollection: async (page?: number, pageSize?: number, customer?: string, registeredBefore?: string, registeredStrictlyBefore?: string, registeredAfter?: string, registeredStrictlyAfter?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (registeredBefore !== undefined) {
                localVarQueryParameter['registered[before]'] = registeredBefore;
            }

            if (registeredStrictlyBefore !== undefined) {
                localVarQueryParameter['registered[strictly_before]'] = registeredStrictlyBefore;
            }

            if (registeredAfter !== undefined) {
                localVarQueryParameter['registered[after]'] = registeredAfter;
            }

            if (registeredStrictlyAfter !== undefined) {
                localVarQueryParameter['registered[strictly_after]'] = registeredStrictlyAfter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceItem', 'id', id)
            const localVarPath = `/api/v1/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Device resource.
         * @summary Updates the Device resource.
         * @param {string} id Resource identifier
         * @param {DeviceDeviceWrite} deviceDeviceWrite The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeviceItem: async (id: string, deviceDeviceWrite: DeviceDeviceWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchDeviceItem', 'id', id)
            // verify required parameter 'deviceDeviceWrite' is not null or undefined
            assertParamExists('patchDeviceItem', 'deviceDeviceWrite', deviceDeviceWrite)
            const localVarPath = `/api/v1/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceDeviceWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Device resource.
         * @summary Creates a Device resource.
         * @param {DeviceJsonldDeviceWriteCreate} deviceJsonldDeviceWriteCreate The new Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceCollection: async (deviceJsonldDeviceWriteCreate: DeviceJsonldDeviceWriteCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceJsonldDeviceWriteCreate' is not null or undefined
            assertParamExists('postDeviceCollection', 'deviceJsonldDeviceWriteCreate', deviceJsonldDeviceWriteCreate)
            const localVarPath = `/api/v1/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceJsonldDeviceWriteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Device resource.
         * @summary Replaces the Device resource.
         * @param {string} id Resource identifier
         * @param {DeviceJsonldDeviceWrite} deviceJsonldDeviceWrite The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeviceItem: async (id: string, deviceJsonldDeviceWrite: DeviceJsonldDeviceWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDeviceItem', 'id', id)
            // verify required parameter 'deviceJsonldDeviceWrite' is not null or undefined
            assertParamExists('putDeviceItem', 'deviceJsonldDeviceWrite', deviceJsonldDeviceWrite)
            const localVarPath = `/api/v1/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceJsonldDeviceWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Device resource.
         * @summary Removes the Device resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Device resources.
         * @summary Retrieves the collection of Device resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [customer]
         * @param {string} [registeredBefore]
         * @param {string} [registeredStrictlyBefore]
         * @param {string} [registeredAfter]
         * @param {string} [registeredStrictlyAfter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceCollection(page?: number, pageSize?: number, customer?: string, registeredBefore?: string, registeredStrictlyBefore?: string, registeredAfter?: string, registeredStrictlyAfter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceCollection(page, pageSize, customer, registeredBefore, registeredStrictlyBefore, registeredAfter, registeredStrictlyAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceJsonldDeviceRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Device resource.
         * @summary Updates the Device resource.
         * @param {string} id Resource identifier
         * @param {DeviceDeviceWrite} deviceDeviceWrite The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDeviceItem(id: string, deviceDeviceWrite: DeviceDeviceWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceJsonldDeviceRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDeviceItem(id, deviceDeviceWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Device resource.
         * @summary Creates a Device resource.
         * @param {DeviceJsonldDeviceWriteCreate} deviceJsonldDeviceWriteCreate The new Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeviceCollection(deviceJsonldDeviceWriteCreate: DeviceJsonldDeviceWriteCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceJsonldDeviceReadCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeviceCollection(deviceJsonldDeviceWriteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Device resource.
         * @summary Replaces the Device resource.
         * @param {string} id Resource identifier
         * @param {DeviceJsonldDeviceWrite} deviceJsonldDeviceWrite The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDeviceItem(id: string, deviceJsonldDeviceWrite: DeviceJsonldDeviceWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceJsonldDeviceRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDeviceItem(id, deviceJsonldDeviceWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * Removes the Device resource.
         * @summary Removes the Device resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeviceItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Device resources.
         * @summary Retrieves the collection of Device resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [customer]
         * @param {string} [registeredBefore]
         * @param {string} [registeredStrictlyBefore]
         * @param {string} [registeredAfter]
         * @param {string} [registeredStrictlyAfter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceCollection(page?: number, pageSize?: number, customer?: string, registeredBefore?: string, registeredStrictlyBefore?: string, registeredAfter?: string, registeredStrictlyAfter?: string, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.getDeviceCollection(page, pageSize, customer, registeredBefore, registeredStrictlyBefore, registeredAfter, registeredStrictlyAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceItem(id: string, options?: any): AxiosPromise<DeviceJsonldDeviceRead> {
            return localVarFp.getDeviceItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Device resource.
         * @summary Updates the Device resource.
         * @param {string} id Resource identifier
         * @param {DeviceDeviceWrite} deviceDeviceWrite The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeviceItem(id: string, deviceDeviceWrite: DeviceDeviceWrite, options?: any): AxiosPromise<DeviceJsonldDeviceRead> {
            return localVarFp.patchDeviceItem(id, deviceDeviceWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Device resource.
         * @summary Creates a Device resource.
         * @param {DeviceJsonldDeviceWriteCreate} deviceJsonldDeviceWriteCreate The new Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceCollection(deviceJsonldDeviceWriteCreate: DeviceJsonldDeviceWriteCreate, options?: any): AxiosPromise<DeviceJsonldDeviceReadCreate> {
            return localVarFp.postDeviceCollection(deviceJsonldDeviceWriteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Device resource.
         * @summary Replaces the Device resource.
         * @param {string} id Resource identifier
         * @param {DeviceJsonldDeviceWrite} deviceJsonldDeviceWrite The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeviceItem(id: string, deviceJsonldDeviceWrite: DeviceJsonldDeviceWrite, options?: any): AxiosPromise<DeviceJsonldDeviceRead> {
            return localVarFp.putDeviceItem(id, deviceJsonldDeviceWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * Removes the Device resource.
     * @summary Removes the Device resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deleteDeviceItem(id: string, options?: any) {
        return DeviceApiFp(this.configuration).deleteDeviceItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Device resources.
     * @summary Retrieves the collection of Device resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [customer]
     * @param {string} [registeredBefore]
     * @param {string} [registeredStrictlyBefore]
     * @param {string} [registeredAfter]
     * @param {string} [registeredStrictlyAfter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceCollection(page?: number, pageSize?: number, customer?: string, registeredBefore?: string, registeredStrictlyBefore?: string, registeredAfter?: string, registeredStrictlyAfter?: string, options?: any) {
        return DeviceApiFp(this.configuration).getDeviceCollection(page, pageSize, customer, registeredBefore, registeredStrictlyBefore, registeredAfter, registeredStrictlyAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Device resource.
     * @summary Retrieves a Device resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceItem(id: string, options?: any) {
        return DeviceApiFp(this.configuration).getDeviceItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Device resource.
     * @summary Updates the Device resource.
     * @param {string} id Resource identifier
     * @param {DeviceDeviceWrite} deviceDeviceWrite The updated Device resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public patchDeviceItem(id: string, deviceDeviceWrite: DeviceDeviceWrite, options?: any) {
        return DeviceApiFp(this.configuration).patchDeviceItem(id, deviceDeviceWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Device resource.
     * @summary Creates a Device resource.
     * @param {DeviceJsonldDeviceWriteCreate} deviceJsonldDeviceWriteCreate The new Device resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public postDeviceCollection(deviceJsonldDeviceWriteCreate: DeviceJsonldDeviceWriteCreate, options?: any) {
        return DeviceApiFp(this.configuration).postDeviceCollection(deviceJsonldDeviceWriteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Device resource.
     * @summary Replaces the Device resource.
     * @param {string} id Resource identifier
     * @param {DeviceJsonldDeviceWrite} deviceJsonldDeviceWrite The updated Device resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public putDeviceItem(id: string, deviceJsonldDeviceWrite: DeviceJsonldDeviceWrite, options?: any) {
        return DeviceApiFp(this.configuration).putDeviceItem(id, deviceJsonldDeviceWrite, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeatureApi - axios parameter creator
 * @export
 */
export const FeatureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Feature resource.
         * @summary Removes the Feature resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeatureItem', 'id', id)
            const localVarPath = `/api/v1/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Feature resources.
         * @summary Retrieves the collection of Feature resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureCollection: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Feature resource.
         * @summary Retrieves a Feature resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureItem', 'id', id)
            const localVarPath = `/api/v1/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Feature resource.
         * @summary Updates the Feature resource.
         * @param {string} id Resource identifier
         * @param {FeatureCustomerWriteUpdate} featureCustomerWriteUpdate The updated Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureItem: async (id: string, featureCustomerWriteUpdate: FeatureCustomerWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchFeatureItem', 'id', id)
            // verify required parameter 'featureCustomerWriteUpdate' is not null or undefined
            assertParamExists('patchFeatureItem', 'featureCustomerWriteUpdate', featureCustomerWriteUpdate)
            const localVarPath = `/api/v1/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureCustomerWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Feature resource.
         * @summary Creates a Feature resource.
         * @param {FeatureJsonldCustomerWriteCreate} featureJsonldCustomerWriteCreate The new Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeatureCollection: async (featureJsonldCustomerWriteCreate: FeatureJsonldCustomerWriteCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureJsonldCustomerWriteCreate' is not null or undefined
            assertParamExists('postFeatureCollection', 'featureJsonldCustomerWriteCreate', featureJsonldCustomerWriteCreate)
            const localVarPath = `/api/v1/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureJsonldCustomerWriteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Feature resource.
         * @summary Replaces the Feature resource.
         * @param {string} id Resource identifier
         * @param {FeatureJsonldCustomerWriteUpdate} featureJsonldCustomerWriteUpdate The updated Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFeatureItem: async (id: string, featureJsonldCustomerWriteUpdate: FeatureJsonldCustomerWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putFeatureItem', 'id', id)
            // verify required parameter 'featureJsonldCustomerWriteUpdate' is not null or undefined
            assertParamExists('putFeatureItem', 'featureJsonldCustomerWriteUpdate', featureJsonldCustomerWriteUpdate)
            const localVarPath = `/api/v1/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureJsonldCustomerWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureApi - functional programming interface
 * @export
 */
export const FeatureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Feature resource.
         * @summary Removes the Feature resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Feature resources.
         * @summary Retrieves the collection of Feature resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureCollection(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureCollection(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Feature resource.
         * @summary Retrieves a Feature resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureJsonldCustomerReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Feature resource.
         * @summary Updates the Feature resource.
         * @param {string} id Resource identifier
         * @param {FeatureCustomerWriteUpdate} featureCustomerWriteUpdate The updated Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFeatureItem(id: string, featureCustomerWriteUpdate: FeatureCustomerWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureJsonldCustomerReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFeatureItem(id, featureCustomerWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Feature resource.
         * @summary Creates a Feature resource.
         * @param {FeatureJsonldCustomerWriteCreate} featureJsonldCustomerWriteCreate The new Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFeatureCollection(featureJsonldCustomerWriteCreate: FeatureJsonldCustomerWriteCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureJsonldCustomerReadCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFeatureCollection(featureJsonldCustomerWriteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Feature resource.
         * @summary Replaces the Feature resource.
         * @param {string} id Resource identifier
         * @param {FeatureJsonldCustomerWriteUpdate} featureJsonldCustomerWriteUpdate The updated Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFeatureItem(id: string, featureJsonldCustomerWriteUpdate: FeatureJsonldCustomerWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureJsonldCustomerReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFeatureItem(id, featureJsonldCustomerWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureApi - factory interface
 * @export
 */
export const FeatureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureApiFp(configuration)
    return {
        /**
         * Removes the Feature resource.
         * @summary Removes the Feature resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFeatureItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Feature resources.
         * @summary Retrieves the collection of Feature resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureCollection(page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.getFeatureCollection(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Feature resource.
         * @summary Retrieves a Feature resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureItem(id: string, options?: any): AxiosPromise<FeatureJsonldCustomerReadUpdate> {
            return localVarFp.getFeatureItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Feature resource.
         * @summary Updates the Feature resource.
         * @param {string} id Resource identifier
         * @param {FeatureCustomerWriteUpdate} featureCustomerWriteUpdate The updated Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureItem(id: string, featureCustomerWriteUpdate: FeatureCustomerWriteUpdate, options?: any): AxiosPromise<FeatureJsonldCustomerReadUpdate> {
            return localVarFp.patchFeatureItem(id, featureCustomerWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Feature resource.
         * @summary Creates a Feature resource.
         * @param {FeatureJsonldCustomerWriteCreate} featureJsonldCustomerWriteCreate The new Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeatureCollection(featureJsonldCustomerWriteCreate: FeatureJsonldCustomerWriteCreate, options?: any): AxiosPromise<FeatureJsonldCustomerReadCreate> {
            return localVarFp.postFeatureCollection(featureJsonldCustomerWriteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Feature resource.
         * @summary Replaces the Feature resource.
         * @param {string} id Resource identifier
         * @param {FeatureJsonldCustomerWriteUpdate} featureJsonldCustomerWriteUpdate The updated Feature resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFeatureItem(id: string, featureJsonldCustomerWriteUpdate: FeatureJsonldCustomerWriteUpdate, options?: any): AxiosPromise<FeatureJsonldCustomerReadUpdate> {
            return localVarFp.putFeatureItem(id, featureJsonldCustomerWriteUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureApi - object-oriented interface
 * @export
 * @class FeatureApi
 * @extends {BaseAPI}
 */
export class FeatureApi extends BaseAPI {
    /**
     * Removes the Feature resource.
     * @summary Removes the Feature resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public deleteFeatureItem(id: string, options?: any) {
        return FeatureApiFp(this.configuration).deleteFeatureItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Feature resources.
     * @summary Retrieves the collection of Feature resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureCollection(page?: number, pageSize?: number, options?: any) {
        return FeatureApiFp(this.configuration).getFeatureCollection(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Feature resource.
     * @summary Retrieves a Feature resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureItem(id: string, options?: any) {
        return FeatureApiFp(this.configuration).getFeatureItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Feature resource.
     * @summary Updates the Feature resource.
     * @param {string} id Resource identifier
     * @param {FeatureCustomerWriteUpdate} featureCustomerWriteUpdate The updated Feature resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public patchFeatureItem(id: string, featureCustomerWriteUpdate: FeatureCustomerWriteUpdate, options?: any) {
        return FeatureApiFp(this.configuration).patchFeatureItem(id, featureCustomerWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Feature resource.
     * @summary Creates a Feature resource.
     * @param {FeatureJsonldCustomerWriteCreate} featureJsonldCustomerWriteCreate The new Feature resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public postFeatureCollection(featureJsonldCustomerWriteCreate: FeatureJsonldCustomerWriteCreate, options?: any) {
        return FeatureApiFp(this.configuration).postFeatureCollection(featureJsonldCustomerWriteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Feature resource.
     * @summary Replaces the Feature resource.
     * @param {string} id Resource identifier
     * @param {FeatureJsonldCustomerWriteUpdate} featureJsonldCustomerWriteUpdate The updated Feature resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public putFeatureItem(id: string, featureJsonldCustomerWriteUpdate: FeatureJsonldCustomerWriteUpdate, options?: any) {
        return FeatureApiFp(this.configuration).putFeatureItem(id, featureJsonldCustomerWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Group identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupsMemberPairsGetSubresourceGroupSubresource: async (id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGroupsMemberPairsGetSubresourceGroupSubresource', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/member_pairs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGroupsGetSubresourceUserSubresource: async (id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersGroupsGetSubresourceUserSubresource', 'id', id)
            const localVarPath = `/api/v1/users/{id}/groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Group resource.
         * @summary Removes the Group resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGroupItem', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Group resources.
         * @summary Retrieves the collection of Group resources.
         * @param {} UNKNOWN_PARAMETER_NAME
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableGroupsGroupCollection: async (UNKNOWN_PARAMETER_NAME?: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'UNKNOWN_PARAMETER_NAME' is not null or undefined
            assertParamExists('getAvailableGroupsGroupCollection', 'UNKNOWN_PARAMETER_NAME', UNKNOWN_PARAMETER_NAME)
            const localVarPath = `/api/v1/groups/{serial_or_id}/availables`
                .replace(`{${"serial_or_id"}}`, encodeURIComponent(String(UNKNOWN_PARAMETER_NAME)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Group resources.
         * @summary Retrieves the collection of Group resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupCollection: async (page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroupItem', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Group resource.
         * @summary Creates a Group resource.
         * @param {string} id Resource identifier
         * @param {GroupJsonldGroupWriteUpdate} groupJsonldGroupWriteUpdate The new Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteGroupItem: async (id: string, groupJsonldGroupWriteUpdate: GroupJsonldGroupWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inviteGroupItem', 'id', id)
            // verify required parameter 'groupJsonldGroupWriteUpdate' is not null or undefined
            assertParamExists('inviteGroupItem', 'groupJsonldGroupWriteUpdate', groupJsonldGroupWriteUpdate)
            const localVarPath = `/api/v1/groups/{id}/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupJsonldGroupWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Group resource.
         * @summary Updates the Group resource.
         * @param {string} id Resource identifier
         * @param {GroupGroupWriteUpdate} groupGroupWriteUpdate The updated Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGroupItem: async (id: string, groupGroupWriteUpdate: GroupGroupWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchGroupItem', 'id', id)
            // verify required parameter 'groupGroupWriteUpdate' is not null or undefined
            assertParamExists('patchGroupItem', 'groupGroupWriteUpdate', groupGroupWriteUpdate)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupGroupWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Group resource.
         * @summary Creates a Group resource.
         * @param {GroupJsonldGroupWriteCreate} groupJsonldGroupWriteCreate The new Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGroupCollection: async (groupJsonldGroupWriteCreate: GroupJsonldGroupWriteCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupJsonldGroupWriteCreate' is not null or undefined
            assertParamExists('postGroupCollection', 'groupJsonldGroupWriteCreate', groupJsonldGroupWriteCreate)
            const localVarPath = `/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupJsonldGroupWriteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Group resource.
         * @summary Replaces the Group resource.
         * @param {string} id Resource identifier
         * @param {GroupJsonldGroupWriteUpdate} groupJsonldGroupWriteUpdate The updated Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGroupItem: async (id: string, groupJsonldGroupWriteUpdate: GroupJsonldGroupWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putGroupItem', 'id', id)
            // verify required parameter 'groupJsonldGroupWriteUpdate' is not null or undefined
            assertParamExists('putGroupItem', 'groupJsonldGroupWriteUpdate', groupJsonldGroupWriteUpdate)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupJsonldGroupWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Group identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupsMemberPairsGetSubresourceGroupSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupsMemberPairsGetSubresourceGroupSubresource(id, page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGroupsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGroupsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Group resource.
         * @summary Removes the Group resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroupItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroupItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Group resources.
         * @summary Retrieves the collection of Group resources.
         * @param {} UNKNOWN_PARAMETER_NAME
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableGroupsGroupCollection(UNKNOWN_PARAMETER_NAME: any, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableGroupsGroupCollection(UNKNOWN_PARAMETER_NAME, page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Group resources.
         * @summary Retrieves the collection of Group resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupCollection(page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupCollection(page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupJsonldGroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Group resource.
         * @summary Creates a Group resource.
         * @param {string} id Resource identifier
         * @param {GroupJsonldGroupWriteUpdate} groupJsonldGroupWriteUpdate The new Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteGroupItem(id: string, groupJsonldGroupWriteUpdate: GroupJsonldGroupWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupJsonldGroupReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteGroupItem(id, groupJsonldGroupWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Group resource.
         * @summary Updates the Group resource.
         * @param {string} id Resource identifier
         * @param {GroupGroupWriteUpdate} groupGroupWriteUpdate The updated Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGroupItem(id: string, groupGroupWriteUpdate: GroupGroupWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupJsonldGroupReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGroupItem(id, groupGroupWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Group resource.
         * @summary Creates a Group resource.
         * @param {GroupJsonldGroupWriteCreate} groupJsonldGroupWriteCreate The new Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postGroupCollection(groupJsonldGroupWriteCreate: GroupJsonldGroupWriteCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupJsonldGroupReadCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postGroupCollection(groupJsonldGroupWriteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Group resource.
         * @summary Replaces the Group resource.
         * @param {string} id Resource identifier
         * @param {GroupJsonldGroupWriteUpdate} groupJsonldGroupWriteUpdate The updated Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGroupItem(id: string, groupJsonldGroupWriteUpdate: GroupJsonldGroupWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupJsonldGroupReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGroupItem(id, groupJsonldGroupWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Group identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupsMemberPairsGetSubresourceGroupSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.apiGroupsMemberPairsGetSubresourceGroupSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGroupsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.apiUsersGroupsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the Group resource.
         * @summary Removes the Group resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroupItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Group resources.
         * @summary Retrieves the collection of Group resources.
         * @param {} UNKNOWN_PARAMETER_NAME
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableGroupsGroupCollection(UNKNOWN_PARAMETER_NAME: any, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.getAvailableGroupsGroupCollection(UNKNOWN_PARAMETER_NAME, page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Group resources.
         * @summary Retrieves the collection of Group resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupCollection(page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.getGroupCollection(page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupItem(id: string, options?: any): AxiosPromise<GroupJsonldGroupRead> {
            return localVarFp.getGroupItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Group resource.
         * @summary Creates a Group resource.
         * @param {string} id Resource identifier
         * @param {GroupJsonldGroupWriteUpdate} groupJsonldGroupWriteUpdate The new Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteGroupItem(id: string, groupJsonldGroupWriteUpdate: GroupJsonldGroupWriteUpdate, options?: any): AxiosPromise<GroupJsonldGroupReadUpdate> {
            return localVarFp.inviteGroupItem(id, groupJsonldGroupWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Group resource.
         * @summary Updates the Group resource.
         * @param {string} id Resource identifier
         * @param {GroupGroupWriteUpdate} groupGroupWriteUpdate The updated Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGroupItem(id: string, groupGroupWriteUpdate: GroupGroupWriteUpdate, options?: any): AxiosPromise<GroupJsonldGroupReadUpdate> {
            return localVarFp.patchGroupItem(id, groupGroupWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Group resource.
         * @summary Creates a Group resource.
         * @param {GroupJsonldGroupWriteCreate} groupJsonldGroupWriteCreate The new Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGroupCollection(groupJsonldGroupWriteCreate: GroupJsonldGroupWriteCreate, options?: any): AxiosPromise<GroupJsonldGroupReadCreate> {
            return localVarFp.postGroupCollection(groupJsonldGroupWriteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Group resource.
         * @summary Replaces the Group resource.
         * @param {string} id Resource identifier
         * @param {GroupJsonldGroupWriteUpdate} groupJsonldGroupWriteUpdate The updated Group resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGroupItem(id: string, groupJsonldGroupWriteUpdate: GroupJsonldGroupWriteUpdate, options?: any): AxiosPromise<GroupJsonldGroupReadUpdate> {
            return localVarFp.putGroupItem(id, groupJsonldGroupWriteUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * Retrieves a Group resource.
     * @summary Retrieves a Group resource.
     * @param {string} id Group identifier
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupsMemberPairsGetSubresourceGroupSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return GroupApiFp(this.configuration).apiGroupsMemberPairsGetSubresourceGroupSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id User identifier
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiUsersGroupsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return GroupApiFp(this.configuration).apiUsersGroupsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Group resource.
     * @summary Removes the Group resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public deleteGroupItem(id: string, options?: any) {
        return GroupApiFp(this.configuration).deleteGroupItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Group resources.
     * @summary Retrieves the collection of Group resources.
     * @param {} UNKNOWN_PARAMETER_NAME
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getAvailableGroupsGroupCollection(UNKNOWN_PARAMETER_NAME: any, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return GroupApiFp(this.configuration).getAvailableGroupsGroupCollection(UNKNOWN_PARAMETER_NAME, page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Group resources.
     * @summary Retrieves the collection of Group resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroupCollection(page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return GroupApiFp(this.configuration).getGroupCollection(page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Group resource.
     * @summary Retrieves a Group resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroupItem(id: string, options?: any) {
        return GroupApiFp(this.configuration).getGroupItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Group resource.
     * @summary Creates a Group resource.
     * @param {string} id Resource identifier
     * @param {GroupJsonldGroupWriteUpdate} groupJsonldGroupWriteUpdate The new Group resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public inviteGroupItem(id: string, groupJsonldGroupWriteUpdate: GroupJsonldGroupWriteUpdate, options?: any) {
        return GroupApiFp(this.configuration).inviteGroupItem(id, groupJsonldGroupWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Group resource.
     * @summary Updates the Group resource.
     * @param {string} id Resource identifier
     * @param {GroupGroupWriteUpdate} groupGroupWriteUpdate The updated Group resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public patchGroupItem(id: string, groupGroupWriteUpdate: GroupGroupWriteUpdate, options?: any) {
        return GroupApiFp(this.configuration).patchGroupItem(id, groupGroupWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Group resource.
     * @summary Creates a Group resource.
     * @param {GroupJsonldGroupWriteCreate} groupJsonldGroupWriteCreate The new Group resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public postGroupCollection(groupJsonldGroupWriteCreate: GroupJsonldGroupWriteCreate, options?: any) {
        return GroupApiFp(this.configuration).postGroupCollection(groupJsonldGroupWriteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Group resource.
     * @summary Replaces the Group resource.
     * @param {string} id Resource identifier
     * @param {GroupJsonldGroupWriteUpdate} groupJsonldGroupWriteUpdate The updated Group resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public putGroupItem(id: string, groupJsonldGroupWriteUpdate: GroupJsonldGroupWriteUpdate, options?: any) {
        return GroupApiFp(this.configuration).putGroupItem(id, groupJsonldGroupWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogApi - axios parameter creator
 * @export
 */
export const LogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Log resources.
         * @summary Retrieves the collection of Log resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogCollection: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Log resource.
         * @summary Retrieves a Log resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLogItem', 'id', id)
            const localVarPath = `/api/v1/logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogApi - functional programming interface
 * @export
 */
export const LogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of Log resources.
         * @summary Retrieves the collection of Log resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogCollection(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogCollection(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Log resource.
         * @summary Retrieves a Log resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogJsonldLogRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogApi - factory interface
 * @export
 */
export const LogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogApiFp(configuration)
    return {
        /**
         * Retrieves the collection of Log resources.
         * @summary Retrieves the collection of Log resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogCollection(page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.getLogCollection(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Log resource.
         * @summary Retrieves a Log resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogItem(id: string, options?: any): AxiosPromise<LogJsonldLogRead> {
            return localVarFp.getLogItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogApi - object-oriented interface
 * @export
 * @class LogApi
 * @extends {BaseAPI}
 */
export class LogApi extends BaseAPI {
    /**
     * Retrieves the collection of Log resources.
     * @summary Retrieves the collection of Log resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public getLogCollection(page?: number, pageSize?: number, options?: any) {
        return LogApiFp(this.configuration).getLogCollection(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Log resource.
     * @summary Retrieves a Log resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public getLogItem(id: string, options?: any) {
        return LogApiFp(this.configuration).getLogItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Notification resource.
         * @summary Removes the Notification resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNotificationItem', 'id', id)
            const localVarPath = `/api/v1/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Notification resources.
         * @summary Retrieves the collection of Notification resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationCollection: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Notification resource.
         * @summary Retrieves a Notification resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNotificationItem', 'id', id)
            const localVarPath = `/api/v1/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Notification resource.
         * @summary Updates the Notification resource.
         * @param {string} id Resource identifier
         * @param {NotificationNotifyWrite} notificationNotifyWrite The updated Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotificationItem: async (id: string, notificationNotifyWrite: NotificationNotifyWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchNotificationItem', 'id', id)
            // verify required parameter 'notificationNotifyWrite' is not null or undefined
            assertParamExists('patchNotificationItem', 'notificationNotifyWrite', notificationNotifyWrite)
            const localVarPath = `/api/v1/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationNotifyWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Notification resource.
         * @summary Creates a Notification resource.
         * @param {NotificationJsonldNotifyWriteCreate} notificationJsonldNotifyWriteCreate The new Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotificationCollection: async (notificationJsonldNotifyWriteCreate: NotificationJsonldNotifyWriteCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationJsonldNotifyWriteCreate' is not null or undefined
            assertParamExists('postNotificationCollection', 'notificationJsonldNotifyWriteCreate', notificationJsonldNotifyWriteCreate)
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationJsonldNotifyWriteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Notification resource.
         * @summary Replaces the Notification resource.
         * @param {string} id Resource identifier
         * @param {NotificationJsonldNotifyWrite} notificationJsonldNotifyWrite The updated Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationItem: async (id: string, notificationJsonldNotifyWrite: NotificationJsonldNotifyWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putNotificationItem', 'id', id)
            // verify required parameter 'notificationJsonldNotifyWrite' is not null or undefined
            assertParamExists('putNotificationItem', 'notificationJsonldNotifyWrite', notificationJsonldNotifyWrite)
            const localVarPath = `/api/v1/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationJsonldNotifyWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Notification resource.
         * @summary Removes the Notification resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotificationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotificationItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Notification resources.
         * @summary Retrieves the collection of Notification resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationCollection(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationCollection(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Notification resource.
         * @summary Retrieves a Notification resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationJsonldNotifyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Notification resource.
         * @summary Updates the Notification resource.
         * @param {string} id Resource identifier
         * @param {NotificationNotifyWrite} notificationNotifyWrite The updated Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNotificationItem(id: string, notificationNotifyWrite: NotificationNotifyWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationJsonldNotifyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNotificationItem(id, notificationNotifyWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Notification resource.
         * @summary Creates a Notification resource.
         * @param {NotificationJsonldNotifyWriteCreate} notificationJsonldNotifyWriteCreate The new Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNotificationCollection(notificationJsonldNotifyWriteCreate: NotificationJsonldNotifyWriteCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationJsonldNotifyReadCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNotificationCollection(notificationJsonldNotifyWriteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Notification resource.
         * @summary Replaces the Notification resource.
         * @param {string} id Resource identifier
         * @param {NotificationJsonldNotifyWrite} notificationJsonldNotifyWrite The updated Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotificationItem(id: string, notificationJsonldNotifyWrite: NotificationJsonldNotifyWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationJsonldNotifyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotificationItem(id, notificationJsonldNotifyWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * Removes the Notification resource.
         * @summary Removes the Notification resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNotificationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Notification resources.
         * @summary Retrieves the collection of Notification resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationCollection(page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.getNotificationCollection(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Notification resource.
         * @summary Retrieves a Notification resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationItem(id: string, options?: any): AxiosPromise<NotificationJsonldNotifyRead> {
            return localVarFp.getNotificationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Notification resource.
         * @summary Updates the Notification resource.
         * @param {string} id Resource identifier
         * @param {NotificationNotifyWrite} notificationNotifyWrite The updated Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotificationItem(id: string, notificationNotifyWrite: NotificationNotifyWrite, options?: any): AxiosPromise<NotificationJsonldNotifyRead> {
            return localVarFp.patchNotificationItem(id, notificationNotifyWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Notification resource.
         * @summary Creates a Notification resource.
         * @param {NotificationJsonldNotifyWriteCreate} notificationJsonldNotifyWriteCreate The new Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotificationCollection(notificationJsonldNotifyWriteCreate: NotificationJsonldNotifyWriteCreate, options?: any): AxiosPromise<NotificationJsonldNotifyReadCreate> {
            return localVarFp.postNotificationCollection(notificationJsonldNotifyWriteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Notification resource.
         * @summary Replaces the Notification resource.
         * @param {string} id Resource identifier
         * @param {NotificationJsonldNotifyWrite} notificationJsonldNotifyWrite The updated Notification resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationItem(id: string, notificationJsonldNotifyWrite: NotificationJsonldNotifyWrite, options?: any): AxiosPromise<NotificationJsonldNotifyRead> {
            return localVarFp.putNotificationItem(id, notificationJsonldNotifyWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * Removes the Notification resource.
     * @summary Removes the Notification resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public deleteNotificationItem(id: string, options?: any) {
        return NotificationApiFp(this.configuration).deleteNotificationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Notification resources.
     * @summary Retrieves the collection of Notification resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotificationCollection(page?: number, pageSize?: number, options?: any) {
        return NotificationApiFp(this.configuration).getNotificationCollection(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Notification resource.
     * @summary Retrieves a Notification resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotificationItem(id: string, options?: any) {
        return NotificationApiFp(this.configuration).getNotificationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Notification resource.
     * @summary Updates the Notification resource.
     * @param {string} id Resource identifier
     * @param {NotificationNotifyWrite} notificationNotifyWrite The updated Notification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public patchNotificationItem(id: string, notificationNotifyWrite: NotificationNotifyWrite, options?: any) {
        return NotificationApiFp(this.configuration).patchNotificationItem(id, notificationNotifyWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Notification resource.
     * @summary Creates a Notification resource.
     * @param {NotificationJsonldNotifyWriteCreate} notificationJsonldNotifyWriteCreate The new Notification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public postNotificationCollection(notificationJsonldNotifyWriteCreate: NotificationJsonldNotifyWriteCreate, options?: any) {
        return NotificationApiFp(this.configuration).postNotificationCollection(notificationJsonldNotifyWriteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Notification resource.
     * @summary Replaces the Notification resource.
     * @param {string} id Resource identifier
     * @param {NotificationJsonldNotifyWrite} notificationJsonldNotifyWrite The updated Notification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public putNotificationItem(id: string, notificationJsonldNotifyWrite: NotificationJsonldNotifyWrite, options?: any) {
        return NotificationApiFp(this.configuration).putNotificationItem(id, notificationJsonldNotifyWrite, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PairApi - axios parameter creator
 * @export
 */
export const PairApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Group identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupsMemberPairsGetSubresourceGroupSubresource: async (id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGroupsMemberPairsGetSubresourceGroupSubresource', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/member_pairs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPairsGetSubresourceUserSubresource: async (id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersPairsGetSubresourceUserSubresource', 'id', id)
            const localVarPath = `/api/v1/users/{id}/pairs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvalPairPairCollection: async (inlineObject2?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pairs/approval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Pair resource.
         * @summary Removes the Pair resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePairItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePairItem', 'id', id)
            const localVarPath = `/api/v1/pairs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Pair resources.
         * @summary Retrieves the collection of Pair resources.
         * @param {} UNKNOWN_PARAMETER_NAME
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePairsPairCollection: async (UNKNOWN_PARAMETER_NAME: any, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'UNKNOWN_PARAMETER_NAME' is not null or undefined
            assertParamExists('getAvailablePairsPairCollection', 'UNKNOWN_PARAMETER_NAME', UNKNOWN_PARAMETER_NAME)
            const localVarPath = `/api/v1/pairs/{serial_or_id}/availables`
                .replace(`{${"serial_or_id"}}`, encodeURIComponent(String(UNKNOWN_PARAMETER_NAME)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Pair resources.
         * @summary Retrieves the collection of Pair resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPairCollection: async (page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Pair resource.
         * @summary Retrieves a Pair resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPairItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPairItem', 'id', id)
            const localVarPath = `/api/v1/pairs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Pair resource.
         * @summary Updates the Pair resource.
         * @param {string} id Resource identifier
         * @param {PairPairWrite} pairPairWrite The updated Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPairItem: async (id: string, pairPairWrite: PairPairWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchPairItem', 'id', id)
            // verify required parameter 'pairPairWrite' is not null or undefined
            assertParamExists('patchPairItem', 'pairPairWrite', pairPairWrite)
            const localVarPath = `/api/v1/pairs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairPairWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {PairJsonldPairWriteCreate} pairJsonldPairWriteCreate The new Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPairCollection: async (pairJsonldPairWriteCreate: PairJsonldPairWriteCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairJsonldPairWriteCreate' is not null or undefined
            assertParamExists('postPairCollection', 'pairJsonldPairWriteCreate', pairJsonldPairWriteCreate)
            const localVarPath = `/api/v1/pairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairJsonldPairWriteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Pair resource.
         * @summary Replaces the Pair resource.
         * @param {string} id Resource identifier
         * @param {PairJsonldPairWrite} pairJsonldPairWrite The updated Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPairItem: async (id: string, pairJsonldPairWrite: PairJsonldPairWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putPairItem', 'id', id)
            // verify required parameter 'pairJsonldPairWrite' is not null or undefined
            assertParamExists('putPairItem', 'pairJsonldPairWrite', pairJsonldPairWrite)
            const localVarPath = `/api/v1/pairs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairJsonldPairWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {PairJsonldPairWriteRegister} pairJsonldPairWriteRegister The new Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPairPairCollection: async (pairJsonldPairWriteRegister: PairJsonldPairWriteRegister, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairJsonldPairWriteRegister' is not null or undefined
            assertParamExists('registerPairPairCollection', 'pairJsonldPairWriteRegister', pairJsonldPairWriteRegister)
            const localVarPath = `/api/v1/pairs/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairJsonldPairWriteRegister, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PairApi - functional programming interface
 * @export
 */
export const PairApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PairApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Group identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupsMemberPairsGetSubresourceGroupSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupsMemberPairsGetSubresourceGroupSubresource(id, page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPairsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPairsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvalPairPairCollection(inlineObject2?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairJsonldPairReadApproval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvalPairPairCollection(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Pair resource.
         * @summary Removes the Pair resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePairItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePairItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Pair resources.
         * @summary Retrieves the collection of Pair resources.
         * @param {} UNKNOWN_PARAMETER_NAME
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailablePairsPairCollection(UNKNOWN_PARAMETER_NAME: any, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailablePairsPairCollection(UNKNOWN_PARAMETER_NAME, page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Pair resources.
         * @summary Retrieves the collection of Pair resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPairCollection(page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPairCollection(page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Pair resource.
         * @summary Retrieves a Pair resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPairItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairJsonldPairRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPairItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Pair resource.
         * @summary Updates the Pair resource.
         * @param {string} id Resource identifier
         * @param {PairPairWrite} pairPairWrite The updated Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPairItem(id: string, pairPairWrite: PairPairWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairJsonldPairRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPairItem(id, pairPairWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {PairJsonldPairWriteCreate} pairJsonldPairWriteCreate The new Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPairCollection(pairJsonldPairWriteCreate: PairJsonldPairWriteCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairJsonldPairReadCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPairCollection(pairJsonldPairWriteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Pair resource.
         * @summary Replaces the Pair resource.
         * @param {string} id Resource identifier
         * @param {PairJsonldPairWrite} pairJsonldPairWrite The updated Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPairItem(id: string, pairJsonldPairWrite: PairJsonldPairWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairJsonldPairRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPairItem(id, pairJsonldPairWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {PairJsonldPairWriteRegister} pairJsonldPairWriteRegister The new Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPairPairCollection(pairJsonldPairWriteRegister: PairJsonldPairWriteRegister, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairJsonldPairReadRegister>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPairPairCollection(pairJsonldPairWriteRegister, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PairApi - factory interface
 * @export
 */
export const PairApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PairApiFp(configuration)
    return {
        /**
         * Retrieves a Group resource.
         * @summary Retrieves a Group resource.
         * @param {string} id Group identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupsMemberPairsGetSubresourceGroupSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.apiGroupsMemberPairsGetSubresourceGroupSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPairsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.apiUsersPairsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvalPairPairCollection(inlineObject2?: InlineObject2, options?: any): AxiosPromise<PairJsonldPairReadApproval> {
            return localVarFp.approvalPairPairCollection(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the Pair resource.
         * @summary Removes the Pair resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePairItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePairItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Pair resources.
         * @summary Retrieves the collection of Pair resources.
         * @param {} UNKNOWN_PARAMETER_NAME
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePairsPairCollection(UNKNOWN_PARAMETER_NAME: any, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.getAvailablePairsPairCollection(UNKNOWN_PARAMETER_NAME, page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Pair resources.
         * @summary Retrieves the collection of Pair resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPairCollection(page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.getPairCollection(page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Pair resource.
         * @summary Retrieves a Pair resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPairItem(id: string, options?: any): AxiosPromise<PairJsonldPairRead> {
            return localVarFp.getPairItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Pair resource.
         * @summary Updates the Pair resource.
         * @param {string} id Resource identifier
         * @param {PairPairWrite} pairPairWrite The updated Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPairItem(id: string, pairPairWrite: PairPairWrite, options?: any): AxiosPromise<PairJsonldPairRead> {
            return localVarFp.patchPairItem(id, pairPairWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {PairJsonldPairWriteCreate} pairJsonldPairWriteCreate The new Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPairCollection(pairJsonldPairWriteCreate: PairJsonldPairWriteCreate, options?: any): AxiosPromise<PairJsonldPairReadCreate> {
            return localVarFp.postPairCollection(pairJsonldPairWriteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Pair resource.
         * @summary Replaces the Pair resource.
         * @param {string} id Resource identifier
         * @param {PairJsonldPairWrite} pairJsonldPairWrite The updated Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPairItem(id: string, pairJsonldPairWrite: PairJsonldPairWrite, options?: any): AxiosPromise<PairJsonldPairRead> {
            return localVarFp.putPairItem(id, pairJsonldPairWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Pair resource.
         * @summary Creates a Pair resource.
         * @param {PairJsonldPairWriteRegister} pairJsonldPairWriteRegister The new Pair resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPairPairCollection(pairJsonldPairWriteRegister: PairJsonldPairWriteRegister, options?: any): AxiosPromise<PairJsonldPairReadRegister> {
            return localVarFp.registerPairPairCollection(pairJsonldPairWriteRegister, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PairApi - object-oriented interface
 * @export
 * @class PairApi
 * @extends {BaseAPI}
 */
export class PairApi extends BaseAPI {
    /**
     * Retrieves a Group resource.
     * @summary Retrieves a Group resource.
     * @param {string} id Group identifier
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public apiGroupsMemberPairsGetSubresourceGroupSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return PairApiFp(this.configuration).apiGroupsMemberPairsGetSubresourceGroupSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id User identifier
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public apiUsersPairsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return PairApiFp(this.configuration).apiUsersPairsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Pair resource.
     * @summary Creates a Pair resource.
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public approvalPairPairCollection(inlineObject2?: InlineObject2, options?: any) {
        return PairApiFp(this.configuration).approvalPairPairCollection(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Pair resource.
     * @summary Removes the Pair resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public deletePairItem(id: string, options?: any) {
        return PairApiFp(this.configuration).deletePairItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Pair resources.
     * @summary Retrieves the collection of Pair resources.
     * @param {} UNKNOWN_PARAMETER_NAME
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public getAvailablePairsPairCollection(UNKNOWN_PARAMETER_NAME: any, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return PairApiFp(this.configuration).getAvailablePairsPairCollection(UNKNOWN_PARAMETER_NAME, page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Pair resources.
     * @summary Retrieves the collection of Pair resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public getPairCollection(page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return PairApiFp(this.configuration).getPairCollection(page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Pair resource.
     * @summary Retrieves a Pair resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public getPairItem(id: string, options?: any) {
        return PairApiFp(this.configuration).getPairItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Pair resource.
     * @summary Updates the Pair resource.
     * @param {string} id Resource identifier
     * @param {PairPairWrite} pairPairWrite The updated Pair resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public patchPairItem(id: string, pairPairWrite: PairPairWrite, options?: any) {
        return PairApiFp(this.configuration).patchPairItem(id, pairPairWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Pair resource.
     * @summary Creates a Pair resource.
     * @param {PairJsonldPairWriteCreate} pairJsonldPairWriteCreate The new Pair resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public postPairCollection(pairJsonldPairWriteCreate: PairJsonldPairWriteCreate, options?: any) {
        return PairApiFp(this.configuration).postPairCollection(pairJsonldPairWriteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Pair resource.
     * @summary Replaces the Pair resource.
     * @param {string} id Resource identifier
     * @param {PairJsonldPairWrite} pairJsonldPairWrite The updated Pair resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public putPairItem(id: string, pairJsonldPairWrite: PairJsonldPairWrite, options?: any) {
        return PairApiFp(this.configuration).putPairItem(id, pairJsonldPairWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Pair resource.
     * @summary Creates a Pair resource.
     * @param {PairJsonldPairWriteRegister} pairJsonldPairWriteRegister The new Pair resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PairApi
     */
    public registerPairPairCollection(pairJsonldPairWriteRegister: PairJsonldPairWriteRegister, options?: any) {
        return PairApiFp(this.configuration).registerPairPairCollection(pairJsonldPairWriteRegister, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceApi - axios parameter creator
 * @export
 */
export const ServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersServicesGetSubresourceUserSubresource: async (id: string, page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersServicesGetSubresourceUserSubresource', 'id', id)
            const localVarPath = `/api/v1/users/{id}/services`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Service resource.
         * @summary Removes the Service resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteServiceItem', 'id', id)
            const localVarPath = `/api/v1/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Service resources.
         * @summary Retrieves the collection of Service resources.
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentServicesServiceCollection: async (owner?: string, customer?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/services/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Service resources.
         * @summary Retrieves the collection of Service resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceCollection: async (page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Service resource.
         * @summary Retrieves a Service resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getServiceItem', 'id', id)
            const localVarPath = `/api/v1/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Service resource.
         * @summary Updates the Service resource.
         * @param {string} id Resource identifier
         * @param {ServiceServiceWriteUpdate} serviceServiceWriteUpdate The updated Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceItem: async (id: string, serviceServiceWriteUpdate: ServiceServiceWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchServiceItem', 'id', id)
            // verify required parameter 'serviceServiceWriteUpdate' is not null or undefined
            assertParamExists('patchServiceItem', 'serviceServiceWriteUpdate', serviceServiceWriteUpdate)
            const localVarPath = `/api/v1/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceServiceWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Service resource.
         * @summary Creates a Service resource.
         * @param {ServiceJsonldServiceWriteCreate} serviceJsonldServiceWriteCreate The new Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postServiceCollection: async (serviceJsonldServiceWriteCreate: ServiceJsonldServiceWriteCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceJsonldServiceWriteCreate' is not null or undefined
            assertParamExists('postServiceCollection', 'serviceJsonldServiceWriteCreate', serviceJsonldServiceWriteCreate)
            const localVarPath = `/api/v1/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceJsonldServiceWriteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Service resource.
         * @summary Replaces the Service resource.
         * @param {string} id Resource identifier
         * @param {ServiceJsonldServiceWriteUpdate} serviceJsonldServiceWriteUpdate The updated Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putServiceItem: async (id: string, serviceJsonldServiceWriteUpdate: ServiceJsonldServiceWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putServiceItem', 'id', id)
            // verify required parameter 'serviceJsonldServiceWriteUpdate' is not null or undefined
            assertParamExists('putServiceItem', 'serviceJsonldServiceWriteUpdate', serviceJsonldServiceWriteUpdate)
            const localVarPath = `/api/v1/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceJsonldServiceWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceApi - functional programming interface
 * @export
 */
export const ServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersServicesGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersServicesGetSubresourceUserSubresource(id, page, pageSize, owner, customer, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Service resource.
         * @summary Removes the Service resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServiceItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Service resources.
         * @summary Retrieves the collection of Service resources.
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentServicesServiceCollection(owner?: string, customer?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentServicesServiceCollection(owner, customer, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Service resources.
         * @summary Retrieves the collection of Service resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceCollection(page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceCollection(page, pageSize, owner, customer, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Service resource.
         * @summary Retrieves a Service resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceJsonldServiceRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Service resource.
         * @summary Updates the Service resource.
         * @param {string} id Resource identifier
         * @param {ServiceServiceWriteUpdate} serviceServiceWriteUpdate The updated Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchServiceItem(id: string, serviceServiceWriteUpdate: ServiceServiceWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceJsonldServiceReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchServiceItem(id, serviceServiceWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Service resource.
         * @summary Creates a Service resource.
         * @param {ServiceJsonldServiceWriteCreate} serviceJsonldServiceWriteCreate The new Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postServiceCollection(serviceJsonldServiceWriteCreate: ServiceJsonldServiceWriteCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceJsonldServiceReadCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postServiceCollection(serviceJsonldServiceWriteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Service resource.
         * @summary Replaces the Service resource.
         * @param {string} id Resource identifier
         * @param {ServiceJsonldServiceWriteUpdate} serviceJsonldServiceWriteUpdate The updated Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putServiceItem(id: string, serviceJsonldServiceWriteUpdate: ServiceJsonldServiceWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceJsonldServiceReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putServiceItem(id, serviceJsonldServiceWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceApi - factory interface
 * @export
 */
export const ServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceApiFp(configuration)
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersServicesGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.apiUsersServicesGetSubresourceUserSubresource(id, page, pageSize, owner, customer, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the Service resource.
         * @summary Removes the Service resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteServiceItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Service resources.
         * @summary Retrieves the collection of Service resources.
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentServicesServiceCollection(owner?: string, customer?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.getCurrentServicesServiceCollection(owner, customer, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Service resources.
         * @summary Retrieves the collection of Service resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceCollection(page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.getServiceCollection(page, pageSize, owner, customer, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Service resource.
         * @summary Retrieves a Service resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceItem(id: string, options?: any): AxiosPromise<ServiceJsonldServiceRead> {
            return localVarFp.getServiceItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Service resource.
         * @summary Updates the Service resource.
         * @param {string} id Resource identifier
         * @param {ServiceServiceWriteUpdate} serviceServiceWriteUpdate The updated Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceItem(id: string, serviceServiceWriteUpdate: ServiceServiceWriteUpdate, options?: any): AxiosPromise<ServiceJsonldServiceReadUpdate> {
            return localVarFp.patchServiceItem(id, serviceServiceWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Service resource.
         * @summary Creates a Service resource.
         * @param {ServiceJsonldServiceWriteCreate} serviceJsonldServiceWriteCreate The new Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postServiceCollection(serviceJsonldServiceWriteCreate: ServiceJsonldServiceWriteCreate, options?: any): AxiosPromise<ServiceJsonldServiceReadCreate> {
            return localVarFp.postServiceCollection(serviceJsonldServiceWriteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Service resource.
         * @summary Replaces the Service resource.
         * @param {string} id Resource identifier
         * @param {ServiceJsonldServiceWriteUpdate} serviceJsonldServiceWriteUpdate The updated Service resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putServiceItem(id: string, serviceJsonldServiceWriteUpdate: ServiceJsonldServiceWriteUpdate, options?: any): AxiosPromise<ServiceJsonldServiceReadUpdate> {
            return localVarFp.putServiceItem(id, serviceJsonldServiceWriteUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
export class ServiceApi extends BaseAPI {
    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id User identifier
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [customer]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiUsersServicesGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any) {
        return ServiceApiFp(this.configuration).apiUsersServicesGetSubresourceUserSubresource(id, page, pageSize, owner, customer, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Service resource.
     * @summary Removes the Service resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public deleteServiceItem(id: string, options?: any) {
        return ServiceApiFp(this.configuration).deleteServiceItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Service resources.
     * @summary Retrieves the collection of Service resources.
     * @param {string} [owner]
     * @param {string} [customer]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public getCurrentServicesServiceCollection(owner?: string, customer?: string, ownerCustomer?: string, options?: any) {
        return ServiceApiFp(this.configuration).getCurrentServicesServiceCollection(owner, customer, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Service resources.
     * @summary Retrieves the collection of Service resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [customer]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public getServiceCollection(page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any) {
        return ServiceApiFp(this.configuration).getServiceCollection(page, pageSize, owner, customer, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Service resource.
     * @summary Retrieves a Service resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public getServiceItem(id: string, options?: any) {
        return ServiceApiFp(this.configuration).getServiceItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Service resource.
     * @summary Updates the Service resource.
     * @param {string} id Resource identifier
     * @param {ServiceServiceWriteUpdate} serviceServiceWriteUpdate The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public patchServiceItem(id: string, serviceServiceWriteUpdate: ServiceServiceWriteUpdate, options?: any) {
        return ServiceApiFp(this.configuration).patchServiceItem(id, serviceServiceWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Service resource.
     * @summary Creates a Service resource.
     * @param {ServiceJsonldServiceWriteCreate} serviceJsonldServiceWriteCreate The new Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public postServiceCollection(serviceJsonldServiceWriteCreate: ServiceJsonldServiceWriteCreate, options?: any) {
        return ServiceApiFp(this.configuration).postServiceCollection(serviceJsonldServiceWriteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Service resource.
     * @summary Replaces the Service resource.
     * @param {string} id Resource identifier
     * @param {ServiceJsonldServiceWriteUpdate} serviceJsonldServiceWriteUpdate The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public putServiceItem(id: string, serviceJsonldServiceWriteUpdate: ServiceJsonldServiceWriteUpdate, options?: any) {
        return ServiceApiFp(this.configuration).putServiceItem(id, serviceJsonldServiceWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Setting resources.
         * @summary Retrieves the collection of Setting resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSettingSettingCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Setting resource.
         * @summary Retrieves a Setting resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSettingItem', 'id', id)
            const localVarPath = `/api/v1/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Setting resource.
         * @summary Updates the Setting resource.
         * @param {string} id Resource identifier
         * @param {SettingSettingWriteUpdate} settingSettingWriteUpdate The updated Setting resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSettingItem: async (id: string, settingSettingWriteUpdate: SettingSettingWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchSettingItem', 'id', id)
            // verify required parameter 'settingSettingWriteUpdate' is not null or undefined
            assertParamExists('patchSettingItem', 'settingSettingWriteUpdate', settingSettingWriteUpdate)
            const localVarPath = `/api/v1/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingSettingWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Setting resource.
         * @summary Replaces the Setting resource.
         * @param {string} id Resource identifier
         * @param {SettingJsonldSettingWriteUpdate} settingJsonldSettingWriteUpdate The updated Setting resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSettingItem: async (id: string, settingJsonldSettingWriteUpdate: SettingJsonldSettingWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putSettingItem', 'id', id)
            // verify required parameter 'settingJsonldSettingWriteUpdate' is not null or undefined
            assertParamExists('putSettingItem', 'settingJsonldSettingWriteUpdate', settingJsonldSettingWriteUpdate)
            const localVarPath = `/api/v1/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingJsonldSettingWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of Setting resources.
         * @summary Retrieves the collection of Setting resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentSettingSettingCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentSettingSettingCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Setting resource.
         * @summary Retrieves a Setting resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingJsonldSettingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Setting resource.
         * @summary Updates the Setting resource.
         * @param {string} id Resource identifier
         * @param {SettingSettingWriteUpdate} settingSettingWriteUpdate The updated Setting resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSettingItem(id: string, settingSettingWriteUpdate: SettingSettingWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingJsonldSettingReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSettingItem(id, settingSettingWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Setting resource.
         * @summary Replaces the Setting resource.
         * @param {string} id Resource identifier
         * @param {SettingJsonldSettingWriteUpdate} settingJsonldSettingWriteUpdate The updated Setting resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSettingItem(id: string, settingJsonldSettingWriteUpdate: SettingJsonldSettingWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingJsonldSettingReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSettingItem(id, settingJsonldSettingWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * Retrieves the collection of Setting resources.
         * @summary Retrieves the collection of Setting resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSettingSettingCollection(options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.getCurrentSettingSettingCollection(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Setting resource.
         * @summary Retrieves a Setting resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingItem(id: string, options?: any): AxiosPromise<SettingJsonldSettingRead> {
            return localVarFp.getSettingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Setting resource.
         * @summary Updates the Setting resource.
         * @param {string} id Resource identifier
         * @param {SettingSettingWriteUpdate} settingSettingWriteUpdate The updated Setting resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSettingItem(id: string, settingSettingWriteUpdate: SettingSettingWriteUpdate, options?: any): AxiosPromise<SettingJsonldSettingReadUpdate> {
            return localVarFp.patchSettingItem(id, settingSettingWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Setting resource.
         * @summary Replaces the Setting resource.
         * @param {string} id Resource identifier
         * @param {SettingJsonldSettingWriteUpdate} settingJsonldSettingWriteUpdate The updated Setting resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSettingItem(id: string, settingJsonldSettingWriteUpdate: SettingJsonldSettingWriteUpdate, options?: any): AxiosPromise<SettingJsonldSettingReadUpdate> {
            return localVarFp.putSettingItem(id, settingJsonldSettingWriteUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * Retrieves the collection of Setting resources.
     * @summary Retrieves the collection of Setting resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public getCurrentSettingSettingCollection(options?: any) {
        return SettingApiFp(this.configuration).getCurrentSettingSettingCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Setting resource.
     * @summary Retrieves a Setting resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public getSettingItem(id: string, options?: any) {
        return SettingApiFp(this.configuration).getSettingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Setting resource.
     * @summary Updates the Setting resource.
     * @param {string} id Resource identifier
     * @param {SettingSettingWriteUpdate} settingSettingWriteUpdate The updated Setting resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public patchSettingItem(id: string, settingSettingWriteUpdate: SettingSettingWriteUpdate, options?: any) {
        return SettingApiFp(this.configuration).patchSettingItem(id, settingSettingWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Setting resource.
     * @summary Replaces the Setting resource.
     * @param {string} id Resource identifier
     * @param {SettingJsonldSettingWriteUpdate} settingJsonldSettingWriteUpdate The updated Setting resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public putSettingItem(id: string, settingJsonldSettingWriteUpdate: SettingJsonldSettingWriteUpdate, options?: any) {
        return SettingApiFp(this.configuration).putSettingItem(id, settingJsonldSettingWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get JWT token to authenticate.
         * @param {Credentials} [credentials] Generate new JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCredentialsItem: async (credentials?: Credentials, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Refresh JWT token to authenticate.
         * @param {RefreshToken} [refreshToken] Generate new JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshTokenItem: async (refreshToken?: RefreshToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get JWT token to authenticate.
         * @param {Credentials} [credentials] Generate new JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCredentialsItem(credentials?: Credentials, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCredentialsItem(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Refresh JWT token to authenticate.
         * @param {RefreshToken} [refreshToken] Generate new JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRefreshTokenItem(refreshToken?: RefreshToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRefreshTokenItem(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         *
         * @summary Get JWT token to authenticate.
         * @param {Credentials} [credentials] Generate new JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCredentialsItem(credentials?: Credentials, options?: any): AxiosPromise<Token> {
            return localVarFp.postCredentialsItem(credentials, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Refresh JWT token to authenticate.
         * @param {RefreshToken} [refreshToken] Generate new JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshTokenItem(refreshToken?: RefreshToken, options?: any): AxiosPromise<Token> {
            return localVarFp.postRefreshTokenItem(refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     *
     * @summary Get JWT token to authenticate.
     * @param {Credentials} [credentials] Generate new JWT Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public postCredentialsItem(credentials?: Credentials, options?: any) {
        return TokenApiFp(this.configuration).postCredentialsItem(credentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Refresh JWT token to authenticate.
     * @param {RefreshToken} [refreshToken] Generate new JWT Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public postRefreshTokenItem(refreshToken?: RefreshToken, options?: any) {
        return TokenApiFp(this.configuration).postRefreshTokenItem(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGroupsGetSubresourceUserSubresource: async (id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersGroupsGetSubresourceUserSubresource', 'id', id)
            const localVarPath = `/api/v1/users/{id}/groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPairsGetSubresourceUserSubresource: async (id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersPairsGetSubresourceUserSubresource', 'id', id)
            const localVarPath = `/api/v1/users/{id}/pairs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersServicesGetSubresourceUserSubresource: async (id: string, page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersServicesGetSubresourceUserSubresource', 'id', id)
            const localVarPath = `/api/v1/users/{id}/services`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (ownerCustomer !== undefined) {
                localVarQueryParameter['owner.customer'] = ownerCustomer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserItem', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserUserCollection: async (customer?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {} UNKNOWN_PARAMETER_NAME email address of user
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmailUserCollection: async (UNKNOWN_PARAMETER_NAME: any, customer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'UNKNOWN_PARAMETER_NAME' is not null or undefined
            assertParamExists('getUserByEmailUserCollection', 'UNKNOWN_PARAMETER_NAME', UNKNOWN_PARAMETER_NAME)
            const localVarPath = `/api/v1/users/by_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (UNKNOWN_PARAMETER_NAME !== undefined) {
                localVarQueryParameter['email'] = UNKNOWN_PARAMETER_NAME;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection: async (page?: number, pageSize?: number, customer?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserItem', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {string} id Resource identifier
         * @param {UserUserWriteUpdate} userUserWriteUpdate The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserItem: async (id: string, userUserWriteUpdate: UserUserWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchUserItem', 'id', id)
            // verify required parameter 'userUserWriteUpdate' is not null or undefined
            assertParamExists('patchUserItem', 'userUserWriteUpdate', userUserWriteUpdate)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUserWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserJsonldUserWrite} userJsonldUserWrite The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCollection: async (userJsonldUserWrite: UserJsonldUserWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userJsonldUserWrite' is not null or undefined
            assertParamExists('postUserCollection', 'userJsonldUserWrite', userJsonldUserWrite)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userJsonldUserWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {string} id Resource identifier
         * @param {UserJsonldUserWriteUpdate} userJsonldUserWriteUpdate The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserItem: async (id: string, userJsonldUserWriteUpdate: UserJsonldUserWriteUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putUserItem', 'id', id)
            // verify required parameter 'userJsonldUserWriteUpdate' is not null or undefined
            assertParamExists('putUserItem', 'userJsonldUserWriteUpdate', userJsonldUserWriteUpdate)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userJsonldUserWriteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetManagerUserCollection: async (inlineObject4?: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/resetm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserUserCollection: async (inlineObject3?: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUserUserCollection: async (inlineObject5?: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGroupsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGroupsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPairsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPairsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersServicesGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersServicesGetSubresourceUserSubresource(id, page, pageSize, owner, customer, ownerCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserUserCollection(customer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserUserCollection(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {} UNKNOWN_PARAMETER_NAME email address of user
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByEmailUserCollection(UNKNOWN_PARAMETER_NAME: any, customer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByEmailUserCollection(UNKNOWN_PARAMETER_NAME, customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCollection(page?: number, pageSize?: number, customer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCollection(page, pageSize, customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {string} id Resource identifier
         * @param {UserUserWriteUpdate} userUserWriteUpdate The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserItem(id: string, userUserWriteUpdate: UserUserWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserItem(id, userUserWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserJsonldUserWrite} userJsonldUserWrite The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserCollection(userJsonldUserWrite: UserJsonldUserWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserCollection(userJsonldUserWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {string} id Resource identifier
         * @param {UserJsonldUserWriteUpdate} userJsonldUserWriteUpdate The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserItem(id: string, userJsonldUserWriteUpdate: UserJsonldUserWriteUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserReadUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserItem(id, userJsonldUserWriteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetManagerUserCollection(inlineObject4?: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserReadReset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetManagerUserCollection(inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserUserCollection(inlineObject3?: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserReadReset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserUserCollection(inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyUserUserCollection(inlineObject5?: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserReadVerify>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyUserUserCollection(inlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGroupsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.apiUsersGroupsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPairsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.apiUsersPairsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [owner]
         * @param {string} [customer]
         * @param {string} [ownerCustomer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersServicesGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.apiUsersServicesGetSubresourceUserSubresource(id, page, pageSize, owner, customer, ownerCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserUserCollection(customer?: string, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.getCurrentUserUserCollection(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {} UNKNOWN_PARAMETER_NAME email address of user
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmailUserCollection(UNKNOWN_PARAMETER_NAME: any, customer?: string, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.getUserByEmailUserCollection(UNKNOWN_PARAMETER_NAME, customer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [pageSize] The number of items per page
         * @param {string} [customer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection(page?: number, pageSize?: number, customer?: string, options?: any): AxiosPromise<InlineResponse20015> {
            return localVarFp.getUserCollection(page, pageSize, customer, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem(id: string, options?: any): AxiosPromise<UserJsonldUserRead> {
            return localVarFp.getUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {string} id Resource identifier
         * @param {UserUserWriteUpdate} userUserWriteUpdate The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserItem(id: string, userUserWriteUpdate: UserUserWriteUpdate, options?: any): AxiosPromise<UserJsonldUserReadUpdate> {
            return localVarFp.patchUserItem(id, userUserWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserJsonldUserWrite} userJsonldUserWrite The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCollection(userJsonldUserWrite: UserJsonldUserWrite, options?: any): AxiosPromise<UserJsonldUserRead> {
            return localVarFp.postUserCollection(userJsonldUserWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {string} id Resource identifier
         * @param {UserJsonldUserWriteUpdate} userJsonldUserWriteUpdate The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserItem(id: string, userJsonldUserWriteUpdate: UserJsonldUserWriteUpdate, options?: any): AxiosPromise<UserJsonldUserReadUpdate> {
            return localVarFp.putUserItem(id, userJsonldUserWriteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetManagerUserCollection(inlineObject4?: InlineObject4, options?: any): AxiosPromise<UserJsonldUserReadReset> {
            return localVarFp.resetManagerUserCollection(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserUserCollection(inlineObject3?: InlineObject3, options?: any): AxiosPromise<UserJsonldUserReadReset> {
            return localVarFp.resetUserUserCollection(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUserUserCollection(inlineObject5?: InlineObject5, options?: any): AxiosPromise<UserJsonldUserReadVerify> {
            return localVarFp.verifyUserUserCollection(inlineObject5, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id User identifier
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersGroupsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return UserApiFp(this.configuration).apiUsersGroupsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id User identifier
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersPairsGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, ownerCustomer?: string, options?: any) {
        return UserApiFp(this.configuration).apiUsersPairsGetSubresourceUserSubresource(id, page, pageSize, owner, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id User identifier
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [owner]
     * @param {string} [customer]
     * @param {string} [ownerCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersServicesGetSubresourceUserSubresource(id: string, page?: number, pageSize?: number, owner?: string, customer?: string, ownerCustomer?: string, options?: any) {
        return UserApiFp(this.configuration).apiUsersServicesGetSubresourceUserSubresource(id, page, pageSize, owner, customer, ownerCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the User resource.
     * @summary Removes the User resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserItem(id: string, options?: any) {
        return UserApiFp(this.configuration).deleteUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {string} [customer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCurrentUserUserCollection(customer?: string, options?: any) {
        return UserApiFp(this.configuration).getCurrentUserUserCollection(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {} UNKNOWN_PARAMETER_NAME email address of user
     * @param {string} [customer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserByEmailUserCollection(UNKNOWN_PARAMETER_NAME: any, customer?: string, options?: any) {
        return UserApiFp(this.configuration).getUserByEmailUserCollection(UNKNOWN_PARAMETER_NAME, customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {number} [page] The collection page number
     * @param {number} [pageSize] The number of items per page
     * @param {string} [customer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserCollection(page?: number, pageSize?: number, customer?: string, options?: any) {
        return UserApiFp(this.configuration).getUserCollection(page, pageSize, customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserItem(id: string, options?: any) {
        return UserApiFp(this.configuration).getUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the User resource.
     * @summary Updates the User resource.
     * @param {string} id Resource identifier
     * @param {UserUserWriteUpdate} userUserWriteUpdate The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public patchUserItem(id: string, userUserWriteUpdate: UserUserWriteUpdate, options?: any) {
        return UserApiFp(this.configuration).patchUserItem(id, userUserWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {UserJsonldUserWrite} userJsonldUserWrite The new User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserCollection(userJsonldUserWrite: UserJsonldUserWrite, options?: any) {
        return UserApiFp(this.configuration).postUserCollection(userJsonldUserWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the User resource.
     * @summary Replaces the User resource.
     * @param {string} id Resource identifier
     * @param {UserJsonldUserWriteUpdate} userJsonldUserWriteUpdate The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUserItem(id: string, userJsonldUserWriteUpdate: UserJsonldUserWriteUpdate, options?: any) {
        return UserApiFp(this.configuration).putUserItem(id, userJsonldUserWriteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetManagerUserCollection(inlineObject4?: InlineObject4, options?: any) {
        return UserApiFp(this.configuration).resetManagerUserCollection(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetUserUserCollection(inlineObject3?: InlineObject3, options?: any) {
        return UserApiFp(this.configuration).resetUserUserCollection(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public verifyUserUserCollection(inlineObject5?: InlineObject5, options?: any) {
        return UserApiFp(this.configuration).verifyUserUserCollection(inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get API Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAPIVersionItem: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter: any = {};
            const localVarQueryParameter: any = {};

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get API Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAPIVersionItem(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Version>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAPIVersionItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiFp(configuration)
    return {
        /**
         *
         * @summary Get API Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAPIVersionItem(options?: any): AxiosPromise<Version> {
            return localVarFp.getAPIVersionItem(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     *
     * @summary Get API Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public getAPIVersionItem(options?: any) {
        return VersionApiFp(this.configuration).getAPIVersionItem(options).then((request) => request(this.axios, this.basePath));
    }
}


