<!-- ヘッダー＆グルーバルメニューコンポーネント -->
<script setup>
import { computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import useBreakPoint from '@/composables/useBreakPoint'

import NestedMenu from '@/components/NestedMenu'

const router = useRouter()
const route = useRoute()
const store = useStore()
const { isMobile, isHeaderVisible, isDarkTheme, toggleTheme } = useBreakPoint()
const { t, locale } = useI18n()

// XXX ストアのstateはモジュール名を明示しないといけない(ネームスペース無の場合でも)
const isAuthenticated = computed(() => store.state.token.authenticated)

const logoSize = computed(() => isMobile.value ? 100 : 132)
/**
 * メニュー項目
 */
const menuItems = computed(() => {
  // ユーザーまたは管理者のログイン状態が変化したときの処理
  // 言語切り替えメニュー項目
  // XXX i18nが配列/オブジェクトとして読み込めなくなってたので個別に読み込み
  const langs = []
  for (let i = 0; i < 2; i++) {
    langs.push({ key: t(`menus.langs[${i}].key`), title: t(`menus.langs[${i}].title`) })
  }
  // ログイン/ログアウト・白黒切り替え・言語ボタン/メニュー
  const items = []
  if (isAuthenticated.value) {
    items.push({ key: 'SIGNOUT', title: t('signout.title') })
  } else {
    items.push({ key: 'SIGNIN', title: t('signin.title') })
    // items.push({ key: 'SIGNUP', title: t('signup.title') })
  }
  items.push({ isDivider: true })
  items.push({ key: 'THEME', title: t('common.theme') })
  items.push({ isDivider: true })
  items.push({
    key: 'LANG',
    title: t('common.lang'),
    menu: langs,
  })
  return items
})

onMounted(() => {
  refresh()
})

/**
 * ログアウト処理を実行
 */
const logout = () => {
  const current = route.path
  const isManager = current && current.startsWith('/manager')
  store.dispatch('logout')
  if (isManager) {
    if (route.path !== '/manager/signin') {
      router.push('/manager/signin')
    }
  } else {
    if (route.path !== '/signin') {
      router.push('/signin')
    }
  }
}

/**
 * メニューボタン/オーバーフローメニュー項目をクリックしたときの処理
 * @param {} item
 */
const onMenuItemClick = (item) => {
  // console.log('onMenuItemClick:', item)
  const current = route.path
  const isManager = current && current.startsWith('/manager')
  switch (item.key) {
    // case 'SIGNUP':
    //   if (current !== '/signup') {
    //     router.push('/signup')
    //   }
    //   break
    case 'SIGNIN':
      if (isManager) {
        if (current !== '/manager/signin') {
          router.push('/manager/signin')
        }
      } else {
        if (current !== '/signin') {
          router.push('/signin')
        }
      }
      break
    case 'SIGNOUT':
      logout()
      break
    case 'THEME':
      toggleTheme()
      break
    case 'ja':
    case 'en':
      // NestedMenuからは言語切り替えメニューでも
      // onMenuItemClickが呼ばれるのでここでも処理が必要
      onLangClick(item)
      break
  }
}

/**
 * 言語を選択したときの処理
 * @param {*} item
 */
const onLangClick = (item) => {
  // console.log('onLangClick:', item)
  // こっちはvue-i18nの言語を切り替え
  locale.value = item.key
  // こっちはhtmlのlang属性を切り替え
  store.dispatch('common/changeLang', item.key)
}

/**
 * ロゴをクリックしたときの処理
 */
const onLogoClick = () => {
  const current = route.path
  // console.log('onLogoClick:', current)
  if (isAuthenticated.value) {
    if (current) {
      if (current.startsWith('/user') && (current !== '/user')) {
        router.push('/user')
      } else if (current.startsWith('/manager') && (current !== '/manager')) {
        router.push('/manager')
      }
    }
  }
}

const refresh = () => {
  return store.dispatch('common/refresh')
}

</script>

<template>
  <header>
    <v-app-bar
      v-if="isHeaderVisible"
      color="navigation"
    >
      <v-container class="ma-0 pa-1 fill-height w-100">
        <!-- アイコン表示 -->
        <div
          class="mx-4 fill-height"
          :width="logoSize"
          @click="onLogoClick"
        >
          <img
            v-if="isDarkTheme"
            class="fill-height"
            :width="logoSize"
            src="@/assets/logo_main.svg"
            onerror="this.onerror=null; this.src=&quot;@/assets/logo_main.png&quot;"
          >
          <img
            v-else
            class="fill-height"
            :width="logoSize"
            src="@/assets/logo_white.svg"
            onerror="this.onerror=null; this.src=&quot;@/assets/logo_white.png&quot;"
          >
        </div>
        <v-spacer />
        <!-- ログイン/ログアウト・白黒切り替え・言語切り替えボタン表示 -->
        <div class="mx-1 mx-sm-2 d-none d-sm-flex">
          <div
            v-for="(item) in menuItems"
            :key="item.key"
          >
            <v-divider v-if="item.isDivider" />
            <!-- 通常のメニューボタン -->
            <v-btn
              v-else-if="item.key!=='LANG'"
              class="mr-1 d-none d-sm-flex"
              min-width="120"
              variant="text"
              @click="onMenuItemClick(item)"
            >
              {{ item.title }}
            </v-btn>
            <!-- 言語切替ボタン -->
            <v-menu
              v-else
              location="left bottom"
            >
              <template #activator="{ props }">
                <v-btn
                  class="mr-1 d-none d-sm-flex"
                  v-bind="props"
                  variant="text"
                  min-width="120"
                >
                  {{ $t('common.lang') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item2, index2) in item.menu"
                  :key="index2"
                  @click="onLangClick(item2)"
                >
                  <v-list-item-title>{{ item2.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <!-- メニューボタンが表示できないときのオーバーフローメニュー -->
        <v-menu
          location="left bottom"
          class="mx-1 mx-sm-2"
        >
          <template #activator="{ props }">
            <v-btn
              class="d-flex d-sm-none"
              icon
              v-bind="props"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <div
              v-for="(item) in menuItems"
              :key="item.key"
            >
              <v-divider v-if="item.isDivider" />
              <nested-menu
                v-else-if="item.menu"
                :title="item.title"
                :items="item.menu"
                :menu-item-click="onMenuItemClick"
                :is-open-on-hover="false"
                :is-offset-x="true"
                :is-offset-y="false"
                :is-sub-menu="true"
              />
              <v-list-item
                v-else
                @click="onMenuItemClick(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>
  </header>
</template>

<style scoped>
@import '@/assets/css/style.css';
</style>
