import store from '@/store'
import {
  UserApi, PairApi, GroupApi,
  Configuration,
} from '@/api/client'
// eslint-disable-next-line no-unused-vars
import { Credentials } from './token'
import { extractCollection } from '@/utils/hydra'

const API_SERVER_URL = process.env.__API_SERVER_URL_

/**
 * ログイン中のユーザー情報をクリアする
 * @param {{any}} { commit }
 * @returns {Promise<any>}
 */
const clear = async (commit: any): Promise<any> => {
  await store.dispatch('users/resetAll')
  await store.dispatch('settings/resetAll')
  return store.dispatch('clearToken')
}

/**
 * ユーザー関係のアクション
 * dispatchで呼び出す
 * 引数の１つ目はstate, commit, dispatch等のVuexから引き渡されるもの
 * 引数の２つ目はdispatchを呼び出す際に引き渡されるもの
 * (dispacthに渡せす引数は１つだけなので複数の引数が必要な場合は
 * オブジェクトとして渡さないといけない)
 */
const actions = {
  /**
   * 新規ユーザー登録
   * @param {{any}} { commit }
   * @param {Credentials} credentail
   * @returns {Promise<any>}
   */
  create: async ({ commit }: { commit: any }, credentail: Credentials): Promise<any> => {
    try {
      const response = await (new UserApi(new Configuration({ basePath: API_SERVER_URL })))
        .postUserCollection({ email: credentail.username, plainPassword: credentail.password })
      if (response.data) {
        await store.dispatch('authenticate', credentail)
        await store.dispatch('users/fetchCurrrent')
        await store.dispatch('settings/fetchCurrrent')
        await store.dispatch('services/fetchCurrrent')
        return response.data
      } else {
        const err = { response, message: 'Failed to create user' }
        throw err
      }
    } catch (error) {
      clear(commit)
      throw error
    }
  },

  /**
   * メールアドレス確認リンクを踏んだときの処理
   * @param {{any}} { commit }
   * @param {{string | undefined,string | undefined}} { t0, t1 }
   * @returns {Promise<any>}
   */
  verify: async (
    { commit }: { commit: any },
    { t0, t1 }: { t0: string | undefined, t1: string | undefined }): Promise<any> => {

    const response = await (new UserApi(new Configuration({ basePath: API_SERVER_URL })))
      .verifyUserUserCollection({ token: t0, target: t1 })
    // console.log('resetUserUserCollection:%o', response.data)
    if ((response.status >= 200) || (response.status <= 202)) {
      return response
    } else {
      const err = { response, message: 'Failed to verify user' }
      throw err
    }
  },

  /**
   * ペア招待承認リンクを踏んだときの処理
   * @param {{any}} { commit }
   * @param {{string | undefined,string | undefined}} { t0, t1 }
   * @returns {Promise<any>}
   */
  approve: async (
    { commit }: { commit: any },
    { t0, t1 }: { t0: string | undefined, t1: string | undefined }): Promise<any> => {

    const response = await (new PairApi(new Configuration({ basePath: API_SERVER_URL })))
      .approvalPairPairCollection({ token: t0, target: t1 })
    // console.log('approvalPairPairCollection:%o', response.data)
    if ((response.status >= 200) || (response.status <= 202)) {
      return response
    } else {
      const err = { response, message: 'Failed to approve pair' }
      throw err
    }
  },

  /**
   * グループへの招待メール送信要求の処理
   * @param {{any}} { commit }
   * @param {any} { group }
   * @returns {Promise<any>}
   */
  inviteGroup: async (
    { commit }: { commit: any },
    { group }: { group: any }): Promise<any> => {

    // console.log('inviteGroup:%o', group)
    const token: any = await store.dispatch('updateToken')
    if (token) {
      const response = await (new GroupApi(new Configuration({ basePath: API_SERVER_URL, accessToken: token })))
        .inviteGroupItem(group.id, {})
      // console.log('inviteGroupItem:%o', response.data)
      if ((response.status >= 200) || (response.status <= 202)) {
        return response
      } else {
        const err = { response }
        throw err
      }
    } else {
      const err = { response: token }
      throw err
    }
  },

  /**
   * 参加可能グループ一覧取得処理
   * @param {{any}} { commit }
   * @param {any} { group }
   * @returns {Promise<any>}
   */
  availableGroups: async (
    { commit }: { commit: any },
    { userId, page, pageSize }: { userId: string, page?: number, pageSize?: number }): Promise<any> => {

    // console.log('availableGroups:%o', group)
    const token: any = await store.dispatch('updateToken')
    if (token) {
      const response = await (new GroupApi(new Configuration({ basePath: API_SERVER_URL, accessToken: token })))
        .getAvailableGroupsGroupCollection(userId, page, pageSize)
      // console.log('inviteGroupItem:%o', response.data)
      if ((response.status >= 200) || (response.status <= 202)) {
        return extractCollection(response.data)
      } else {
        const err = { response }
        throw err
      }
    } else {
      const err = { response: token }
      throw err
    }
  },

  /**
   * 既存ユーザーのログイン
   * @param {{any}} { commit }
   * @param {Credentials} credentail
   * @returns {Promise<any>}
   */
  login: async ({ commit }: { commit: any }, credentail: Credentials): Promise<any> => {
    clear(commit)
    try {
      const auth = await store.dispatch('authenticate', credentail)
      if (auth.token) {
        const options = { headers: { authorization: 'Bearer ' + auth.token } }
        const response = await store.dispatch('users/fetchCurrrent', options)
        // console.log('users/fetchCurrrent:', response)
        await store.dispatch('settings/fetchCurrrent', options)
        await store.dispatch('services/fetchCurrrent', options)
        await store.dispatch('customers/fetchCurrrent', options)
        if (response) {
          return response
        } else {
          const err = { response, message: 'Failed to login' }
          throw err
        }
      } else {
        const err = { response: auth, message: 'Failed to login' }
        throw err
      }
    } catch (error) {
      clear(commit)
      throw error
    }
  },

  /**
   * ログイン中ユーザーのログアウト
   * @param {{any}} { commit }
   * @returns {Promise<any>}
   */
  logout: async ({ commit }: { commit: any }): Promise<any> => {
    return clear(commit)
  },

  /**
   * パスワードをリセット要求
   * emailだけならリセットメール送信
   * @param {{any}} { commit }
   * @param {string, string|undefined, string|undefined} { email, plainPassword, token }
   * @returns {Promise<any>}
   */
  resetPassword: async (
    { commit }: { commit: any },
    { email, plainPassword, token }: { email: string, plainPassword: string | undefined, token: string | undefined}): Promise<any> => {

    const response = await (new UserApi(new Configuration({ basePath: API_SERVER_URL })))
      .resetUserUserCollection({ email, plainPassword, token })
    // console.log('resetUserUserCollection:%o', response.data)
    if ((response.status >= 200) || (response.status <= 202)) {
      return response
    } else {
      const err = { response, message: 'Failed to request reset password' }
      throw err
    }
  },

  /**
   * マネジャーパスワードをリセット要求
   * emailだけならリセットメール送信
   * @param {{any}} { commit }
   * @param {string, string|undefined, string|undefined} { email, plainPassword, token }
   * @returns {Promise<any>}
   */
  resetManagerPassword: async (
    { commit }: { commit: any },
    { email, plainPassword, token }: { email: string, plainPassword: string | undefined, token: string | undefined}): Promise<any> => {

    const response = await (new UserApi(new Configuration({ basePath: API_SERVER_URL })))
      .resetManagerUserCollection({ email, plainPassword, token })
    // console.log('resetUserUserCollection:%o', response.data)
    if ((response.status >= 200) || (response.status <= 202)) {
      return response
    } else {
      const err = { response, message: 'Failed to request reset password' }
      throw err
    }
  },
}

export default {
  namespaced: false,
  actions,
}
