import { computed } from 'vue'
import { useScreenOrientation } from '@vueuse/core'
import { useStore } from 'vuex'
import { useDisplay, useTheme } from 'vuetify'
import { detect } from 'detect-browser'

export default function useBreakPoint () {
  const display = useDisplay()
  const store = useStore()
  const { mobile: isMobile, platform, name: bp } = display
  const {
    isSupported,
    orientation,
    angle,
    lockOrientation,
    unlockOrientation,
  } = useScreenOrientation()
  const vutifyTheme = useTheme()

  const logLevel = computed(() => process.env.__APP_LOG_LEVEL_)
  const userAgent = computed(() => window.navigator.userAgent)
  /**
   * userAgentの解析結果を取得
   * @returns {BrowserInfo|SearchBotDeviceInfo|BotInfo|NodeInfo|ReactNativeInfo|null}
   */
  const info = computed(() => detect(userAgent.value))

  /**
   * AndroidまたはiOSで実行中かどうか
   */
  const isSmartPhone = computed(() => {
    const p = platform.value
    return p.android || p.ios
  })

  const isLandscape = computed(() => {
    return (angle.value % 180) === 0
    // return (orientation.value == "landscape-primary") || (orientation.value == "landscape-secondary")
  })

  const isDarkTheme = computed(() => {
    return vutifyTheme.global.name.value === 'dark'
  })

  /**
   * ダイアログの幅を計算
   */
  const dialogWidth = computed(() => {
    if (display.width.value < 400) {
      return display.width.value
    } else {
      return 400
    }
  })

  /**
   * ダイアログのボタンの高さ
   */
  const dialogButtonHeight = computed(() => {
    return isMobile.value ? 75 : 100
  })

  /**
   * アイコンの大きさ
   * デスクトップでのブラウザスクロールバー幅は16px
   */
  const iconSize = computed(() => {
    switch (bp.value) {
      case 'xs': return 14    // < 600px, 小型・大型スマホ
      case 'sm': return 24    // 600px > < 960px, 小型・中型タブレット
      case 'md': return 32    // 960px > < 1280px, 大型タブレット・ノートＰＣ
      case 'lg': return 44    // 1280px > < 1920px, デスクトップ
      case 'xl': return 48    // 1920px > < 2560px*, 1080p/1440pデスクトップ
      case 'xxl': return 52   // > 2560px*, 4K・ウルトラワイド
      default: return 'default'
    }
  })

  /**
   * アプリのヘッダーを表示するかどうか
   */
  const isHeaderVisible = computed(() => store.state.common.headerVisible)

  /**
   * アプリのフッターを表示するかどうか
   */
  const isFooterVisible = computed(() => store.state.common.footerVisible)

  /**
   * 白黒切り替え(light/darkテーマ切り替え)
   */
  const toggleTheme = () => {
    vutifyTheme.global.name.value = (isDarkTheme.value) ? 'light' : 'dark'
  }

  const update = () => {
    display.update()
  }

  return {
    logLevel,
    userAgent,
    info,
    isSupported,
    bp,
    platform,
    display,
    isMobile,
    isSmartPhone,
    isHeaderVisible,
    isFooterVisible,
    isLandscape,
    isDarkTheme,
    angle,
    dialogWidth,
    dialogButtonHeight,
    iconSize,
    orientation,
    lockOrientation,
    unlockOrientation,
    toggleTheme,
    update,
  }
}
