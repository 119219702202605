import { createI18n } from 'vue-i18n'

// デフォルトの言語
export const DEFAULT_LANG = 'ja'
// サポート外の言語を指定されたときのフォールバック言語
export const FALLBACK_LANG = 'en'

// 対応している言語
export const SUPPORTED_LANGS = ['ja', 'en']

// ブラウザの言語指定を取得
const language = (
  (
    window.navigator.languages
    && window.navigator.languages[0]
  )
  || window.navigator.language
  // || window.navigator.userLanguage
  // || window.navigator.browserLanguage
  || FALLBACK_LANG
)

// ブラウザの表示言語から現在のロケール設定を取得
const locale = language.substr(0, 2) === DEFAULT_LANG ? DEFAULT_LANG : FALLBACK_LANG

const i18n = createI18n({
  locale,
  fallbackLocale: FALLBACK_LANG,
  legacy: false,
  messages: {
    ja: require('@/assets/locales/ja.json'),
    en: require('@/assets/locales/en.json'),
  },
})

export default i18n
